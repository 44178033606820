import Select, { SelectOption } from "common/Components/Select/Select";
import {
  TimeRangePicker,
  TimeRangePickerProps,
} from "common/Components/TimePicker";
import React, { SetStateAction, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

export enum RoutinePeriod {
  Morning = 1,
  Noon = 2,
  Afternoon = 3,
  Evening = 4,
}

interface RoutineTimePickerProps extends TimeRangePickerProps {
  period: RoutinePeriod | null;
  setPeriod: React.Dispatch<SetStateAction<RoutinePeriod | null>>;
  validatePeriod?: (start: number, end: number) => string | undefined;
}

const RoutineTimePicker: React.FC<RoutineTimePickerProps> = ({
  period,
  setPeriod,
  validatePeriod,
  ...props
}) => {
  const ROOT_CLASS = "routine-time-picker";

  const { t } = useTranslation();

  interface PeriodOption extends SelectOption {
    value: RoutinePeriod;
  }

  const getPeriodOptions = useCallback((): PeriodOption[] => {
    return [
      {
        value: RoutinePeriod.Morning,
        label: t("templates.templates.1"),
      },
      {
        value: RoutinePeriod.Noon,
        label: t("templates.templates.2"),
      },
      {
        value: RoutinePeriod.Afternoon,
        label: t("templates.templates.3"),
      },
      {
        value: RoutinePeriod.Evening,
        label: t("templates.templates.4"),
      },
    ];
  }, [t]);

  useEffect(() => {
    setPeriodWithTimes(period);
  }, []);

  const setPeriodWithTimes = (newPeriod: RoutinePeriod | null) => {
    if (newPeriod !== period) {
      setPeriod(newPeriod);

      if (newPeriod) {
        switch (newPeriod) {
          case RoutinePeriod.Morning:
            props.setStart(5 * 3600);
            props.setEnd(11 * 3600);
            break;
          case RoutinePeriod.Noon:
            props.setStart(11 * 3600);
            props.setEnd(13 * 3600);
            break;
          case RoutinePeriod.Afternoon:
            props.setStart(13 * 3600);
            props.setEnd(17 * 3600);
            break;
          case RoutinePeriod.Evening:
            props.setStart(17 * 3600);
            props.setEnd(23 * 3600);
            break;
          default:
            break;
        }
      }
    }
  };

  return (
    <div className={ROOT_CLASS}>
      {period !== null ? (
        <TimeRangePicker {...props} validatePeriod={validatePeriod} />
      ) : (
        <Select
          label={t("routine.edit.inputs.time.menuLabel")}
          value={period}
          options={getPeriodOptions()}
          onChange={(o) => setPeriodWithTimes(o.value)}
          placeholder={t("routine.edit.inputs.time.placeholder")}
          hideIndicator
          classes={{
            button: ROOT_CLASS + "__select",
          }}
        />
      )}
    </div>
  );
};

export default RoutineTimePicker;
