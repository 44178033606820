import { useTheme } from "@mui/material";
import {
  DashboardMedicalSideEffectSelected,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import { Icons } from "@neurosolutionsgroup/components";
import Select, { SelectOption } from "common/Components/Select/Select";
import useFollowUp from "common/hooks/FollowUp/useFollowUp";
import useLanguage from "common/hooks/Parameters/useLanguage";
import useSideEffect from "common/hooks/sideEffect/useSideEffect";
import React, { SetStateAction, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface SymptomOption extends SelectOption {
  value: string;
}

interface SymptomPickerProps {
  selectedChild: string | null;
  sideEffect: string | null;
  setSideEffect: React.Dispatch<SetStateAction<string | null>>;
}

const SymptomPicker = ({
  selectedChild,
  sideEffect,
  setSideEffect,
}: SymptomPickerProps): JSX.Element => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { handleEvent } = useAnalytics();
  const { palette } = useTheme();

  const {
    selectors: { sideEffectById },
  } = useSideEffect();

  const {
    selectors: { followedSideEffectsByGamerChildId },
  } = useFollowUp();

  const getSideEffectOptions = useCallback((): SymptomOption[] => {
    if (selectedChild && sideEffectById) {
      const followedSideEffects =
        followedSideEffectsByGamerChildId[selectedChild];

      if (!followedSideEffects) {
        return [];
      }

      return followedSideEffects.map((fse) => ({
        value: fse.sideEffectId,
        label: sideEffectById[fse.sideEffectId].name[language],
      }));
    }

    return [];
  }, [
    selectedChild,
    followedSideEffectsByGamerChildId,
    sideEffectById,
    language,
  ]);

  const onSideEffectChange = (value: string | null) => {
    if (sideEffectById && value) {
      const event: DashboardMedicalSideEffectSelected = {
        name: "Dashboard Medical Side Effect Selected",
        eventProperties: {
          "Side Effect Selected": sideEffectById[value].name.en,
        },
      };

      handleEvent(event);
    }

    setSideEffect(value);
  };

  // Select first side effect on child change, if available.
  useEffect(() => {
    if (selectedChild) {
      const followedSideEffects =
        followedSideEffectsByGamerChildId[selectedChild];

      if (followedSideEffects && followedSideEffects.length > 0) {
        setSideEffect(followedSideEffects[0].sideEffectId);
      }
    }
  }, [selectedChild, followedSideEffectsByGamerChildId]);

  return (
    <Select
      options={getSideEffectOptions()}
      value={sideEffect}
      onChange={(o) => onSideEffectChange(o.value)}
      label={t("dashboard.med.sideEffect")}
      placeholder={t("dashboard.med.sideEffect")}
      variant="outlined"
      indicator={
        <Icons.ArrowIcon arrowDirection="down" color={palette.secondary.main} />
      }
      fullWidth={false}
    />
  );
};

export default SymptomPicker;
