import CoachCategory1 from "./coach_category_1.png";
import CoachCategory2 from "./coach_category_2.png";
import CoachCategory3 from "./coach_category_3.png";
import CoachCategory4 from "./coach_category_4.png";
import ChatAvatar from "./coach_chat_avatar.png";
import CoachDynamic from "./coach_dynamic.png";
import CoachFull from "./coach_wholebody.png";
import CoachGraph from "./coach_graph.png";
import CoachHead from "./coach.svg";
import CoachInfoHead from "./coach_info_head.png";
import CoachProfile from "./coach_profile.png";
import CoachWarning from "./coach_warning.png";
import InfoSymptomsGraph from "./info_symptoms_graph.png";
import RobotChest from "./robot_chest.svg";
import ValidationReminder from "./coach_validation_reminder.png";

const CoachAssets = {
  ChatAvatar,
  CoachCategory1,
  CoachCategory2,
  CoachCategory3,
  CoachCategory4,
  CoachDynamic,
  CoachFull,
  CoachGraph,
  CoachHead,
  CoachInfoHead,
  CoachProfile,
  CoachWarning,
  InfoSymptomsGraph,
  RobotChest,
  ValidationReminder,
};

export default CoachAssets;
