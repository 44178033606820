import {
  FirestoreCollection,
  FirestoreNoteDocument,
  Note,
} from "@neurosolutionsgroup/models";
import {
  deleteDoc,
  doc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { SetStateAction } from "react";
import { useAppDataContext } from "../AppDataContext";
import useAuth from "../auth/useAuth";
import { NoteDateCount, NoteDocsByDate, useNotesContext } from "./NoteContext";
import { JournalNoteSaved, useAnalytics } from "@neurosolutionsgroup/analytics";
import { Tools } from "@neurosolutionsgroup/tools";

interface UseNotesResult {
  noteDates: Date[];
  noteDateCounts: NoteDateCount[];
  currentDate: Date;
  setCurrentDate: React.Dispatch<SetStateAction<Date>>;
  notesForWeek: NoteDocsByDate;
  loading: boolean;
  addNote: (note: Note) => Promise<void>;
  editNote: (note: Note) => Promise<void>;
  deleteNote: (noteId: string) => Promise<void>;
}

const useNotes = (): UseNotesResult => {
  const {
    noteDates,
    noteDateCounts,
    currentDate,
    setCurrentDate,
    setNoteDates,
    setNotesByDate,
    notesForWeek,
    loading,
  } = useNotesContext();

  const { user, tenantId } = useAuth();

  const { setLoading } = useAppDataContext();

  const { handleEvent } = useAnalytics();

  const addNote = async (note: Note): Promise<void> => {
    if (user && tenantId) {
      setLoading(true);

      const { noteId, ...noteData } = note;

      const event: JournalNoteSaved = {
        name: "Journal Note Saved",
      };

      handleEvent(event);

      const noteDoc: FirestoreNoteDocument = {
        ...noteData,
        sideEffectId: noteData.sideEffectId ?? null,
        routineCategoryId: noteData.routineCategoryId ?? null,
        eventTime: noteData.eventTime ?? null,
        userId: user.uid,
        tenantId: tenantId,
      };

      const db = getFirestore();

      try {
        await setDoc(doc(db, FirestoreCollection.Notes, noteId), noteDoc);

        // Update state temporarilly to avoid delay in update to summary.
        if (
          !noteDates.includes(
            Tools.Time.Dates.parseDateStringToJsDate(note.eventDate)
          )
        ) {
          setNoteDates((current) => [
            ...current,
            Tools.Time.Dates.parseDateStringToJsDate(note.eventDate),
          ]);

          setNotesByDate((current) => ({
            ...current,
            [note.eventDate]: [noteId],
          }));
        } else {
          setNotesByDate((current) => ({
            ...current,
            [note.eventDate]: [...current[note.eventDate], noteId],
          }));
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const editNote = async (note: Note): Promise<void> => {
    setLoading(true);

    const db = getFirestore();

    const noteDocRef = doc(db, FirestoreCollection.Notes, note.noteId);

    try {
      await updateDoc(noteDocRef, {
        title: note.title,
        text: note.text,
        eventDate: note.eventDate,
        eventTime: note.eventTime ?? null,
        sideEffectId: note.sideEffectId ?? null,
        sideEffectData: note.sideEffectData,
        routineCategoryId: note.routineCategoryId ?? null,
      });

      setCurrentDate(Tools.Time.Dates.parseDateStringToJsDate(note.eventDate));
    } finally {
      setLoading(false);
    }
  };

  const deleteNote = async (noteId: string): Promise<void> => {
    setLoading(true);

    const db = getFirestore();

    const noteDocRef = doc(db, FirestoreCollection.Notes, noteId);

    try {
      await deleteDoc(noteDocRef);
    } finally {
      setLoading(false);
    }
  };

  return {
    noteDates,
    noteDateCounts,
    currentDate,
    setCurrentDate,
    notesForWeek,
    loading,
    addNote,
    editNote,
    deleteNote,
  };
};

export default useNotes;
