import { Routine } from "@neurosolutionsgroup/models";
import Tools from "@neurosolutionsgroup/tools";

// Check no child has more than the max number of active routines.
const validateActiveRoutinesCount = (
  children: string[],
  days: number,
  getRoutinesForChild: (childId: string) => Routine[],
  routineId: string,
  maximumRoutinesCount: number
): boolean => {
  return children.every(
    (u) =>
      getMaximumDayCount(
        getRoutinesForChild(u)
          .filter(
            (r) => !r.deleted && !r.disabled && !r.locked && r.id !== routineId
          )
          .map((r) => r.days),
        days
      ) < maximumRoutinesCount
  );
};

const getMaximumDayCount = (daysArray: number[], days: number): number => {
  let max = 0;

  for (let i = 0; i <= 6; i++) {
    if (Tools.Time.Days.dayIndexes[i] & days) {
      const count = daysArray.filter(
        (d) => d & Tools.Time.Days.dayIndexes[i]
      ).length;

      if (count > max) {
        max = count;
      }
    }
  }

  return max;
};

// Check no children have routines already assigned for the same day and time.
const validateRoutinesDontOverlap = (
  children: string[],
  getRoutinesForChild: (childId: string) => Routine[],
  routineId: string,
  days: number,
  start: number,
  end: number
): boolean => {
  return children.every((u) =>
    getRoutinesForChild(u)
      .filter((r) => r.id !== routineId)
      .every((r) => {
        return !(r.days & days) || r.end <= start || r.start >= end;
      })
  );
};

const toggleRoutineActive = (
  routine: Routine,
  isDisabled: boolean
): Routine => {
  if (isDisabled === routine.disabled) {
    return routine;
  }

  routine.disabled = isDisabled;

  const dateNow = Tools.Time.Dates.getTimeStamp();

  if (!isDisabled && routine.lastDisabledDate !== null) {
    const period = { start: routine.lastDisabledDate, end: dateNow };

    const todayMidnight = Tools.Time.Dates.getTimeStamp(
      Tools.Time.Dates.getMidnightDate()
    );

    if (routine.lastDisabledDate > todayMidnight) {
      if (
        routine.lastDisabledDate < todayMidnight + routine.start &&
        dateNow > todayMidnight + routine.end
      ) {
        routine.inactivePeriods = routine.inactivePeriods
          ? routine.inactivePeriods.concat(period)
          : [period];
      }
    } else {
      routine.inactivePeriods = routine.inactivePeriods
        ? routine.inactivePeriods.concat(period)
        : [period];
    }
  }

  routine.lastDisabledDate = isDisabled ? dateNow : null;

  return routine;
};

const RoutineEditionLogic = {
  toggleRoutineActive,
  validateActiveRoutinesCount,
  validateRoutinesDontOverlap,
};

export default RoutineEditionLogic;
