import {
  useAnalytics,
  ValidationAvailable,
} from "@neurosolutionsgroup/analytics";
import {
  GamerChild,
  MedicalChild,
  TaskHistory,
} from "@neurosolutionsgroup/models";
import Tools from "@neurosolutionsgroup/tools";
import FirebaseAPI from "@neurosolutionsgroup/api-client";
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import useParameters from "../Parameters/useParameters";
import { CustomWindow } from "custom.window";
import { Loader } from "@neurosolutionsgroup/components";
import {
  useRemoteConfig,
  WebviewsFeatureFlag,
} from "@neurosolutionsgroup/remote-config";

declare let window: CustomWindow;

export type ChildrenById = {
  [key: string]: GamerChild;
};

export type MedicalChildrenByGamerId = {
  [key: string]: MedicalChild;
};

export interface TasksToValidate {
  [childId: string]: TaskHistory[];
}

export interface ChildrenContextData {
  childIds: string[];
  setChildIds: Dispatch<SetStateAction<string[]>>;
  childrenById: ChildrenById;
  setChildrenById: Dispatch<SetStateAction<ChildrenById>>;
  medicalChildrenByGamerId: MedicalChildrenByGamerId;
  setMedicalChildrenByGamerId: Dispatch<
    SetStateAction<MedicalChildrenByGamerId>
  >;
  selectedChild: string | null;
  setSelectedChild: Dispatch<SetStateAction<string | null>>;
  tasksToValidate: TasksToValidate;
  setTasksToValidate: React.Dispatch<SetStateAction<TasksToValidate>>;
  childInitilizationComplete: boolean;
  resetChildren: () => void;
  lastValidationSent: number | undefined;
  setLastValidationSent: React.Dispatch<SetStateAction<number | undefined>>;
  anyChildHasTaskTovalidate: boolean;
  updateSelectedChild: VoidFunction;
  refreshChildren: () => Promise<void>;
}

const [useChildrenContext, ChildrenContextProvider] =
  Tools.Context.createGenericContext<ChildrenContextData>(__filename);

const ChildrenProvider: React.FC<PropsWithChildren<unknown>> = (props) => {
  const [childIds, setChildIds] = useState<string[]>([]);
  const [childrenById, setChildrenById] = useState<ChildrenById>({});
  const [medicalChildrenByGamerId, setMedicalChildrenByGamerId] =
    useState<MedicalChildrenByGamerId>({});
  const [selectedChild, setSelectedChild] = useState<string | null>(null);
  const [tasksToValidate, setTasksToValidate] = useState<TasksToValidate>({});
  const [childInitilizationComplete, setChildInitializationComplete] =
    useState(false);
  const [lastValidationSent, setLastValidationSent] = useState<number>();
  const [loading, setLoading] = useState(false);

  const { addLoadingProgress, version } = useParameters();
  const {
    handleEvent,
    functions: { setProfileProperties },
  } = useAnalytics();
  const { checkFeatureFlagVersion } = useRemoteConfig();

  const refreshChildren = async (): Promise<void> => {
    const completeChildren = await FirebaseAPI.Child.getCompletChildren(
      -new Date().getTimezoneOffset()
    );

    completeChildren.sort(
      (a, b) =>
        (a.gamerChild.isDisabled ? 1 : 0) - (b.gamerChild.isDisabled ? 1 : 0)
    );

    const apiChildrenById: ChildrenById = {};
    const apiMedicalChildrenById: MedicalChildrenByGamerId = {};
    const apiTasksToValidate: TasksToValidate = {};

    completeChildren.forEach((c) => {
      apiChildrenById[c.gamerChild.id] = c.gamerChild;
      apiTasksToValidate[c.gamerChild.id] = c.gamerChild.isDisabled
        ? []
        : c.gamerChild.history.filter((h) => h.confirmTime === null);

      const medicalChild = c.medicalChild;

      if (medicalChild && medicalChild.gamerChildId) {
        apiMedicalChildrenById[medicalChild.gamerChildId] = medicalChild;
      }
    });

    setChildrenById(apiChildrenById);
    setChildIds(Object.keys(apiChildrenById));
    setTasksToValidate(apiTasksToValidate);

    if (checkFeatureFlagVersion(WebviewsFeatureFlag.ChildAnalytics, version)) {
      setProfileProperties(
        {
          children: completeChildren.map((child) => ({
            name: child.gamerChild.name,
            birthDate: child.gamerChild.birthDate,
            gender: child.gamerChild.gender,
          })),
        },
        ["Console", "CustomerIO"]
      );
    }

    const validationsCount = Object.values(apiTasksToValidate).reduce(
      (prev, curr) => prev + curr.length,
      0
    );

    if (validationsCount > 0) {
      const event: ValidationAvailable = {
        name: "Validation Available",
        eventProperties: {
          "Number of validations": validationsCount,
          "Number of children with validations": Object.values(
            apiTasksToValidate
          ).filter((value) => value.length > 0).length,
        },
      };

      handleEvent(event);
    }

    setMedicalChildrenByGamerId(apiMedicalChildrenById);
    setSelectedChild((current) =>
      Object.keys(apiChildrenById).length === 0
        ? null
        : current && Object.keys(apiChildrenById).includes(current)
        ? current
        : Object.keys(apiChildrenById)[0]
    );
  };

  const updateChildrenInfo = async (): Promise<void> => {
    setLoading(true);

    const gamerChildren = await FirebaseAPI.Child.getGamerChildren();

    gamerChildren.sort(
      (a, b) => (a.isDisabled ? 1 : 0) - (b.isDisabled ? 1 : 0)
    );

    const apiChildrenById: ChildrenById = {};
    const apiTasksToValidate: TasksToValidate = {};

    gamerChildren.forEach((c) => {
      apiChildrenById[c.id] = c;
      apiTasksToValidate[c.id] = c.isDisabled
        ? []
        : c.history.filter((h) => h.confirmTime === null);
    });

    setChildrenById(apiChildrenById);
    setChildIds(gamerChildren.map((c) => c.id));
    setTasksToValidate(apiTasksToValidate);
    setSelectedChild((current) =>
      current && gamerChildren.map((c) => c.id).includes(current)
        ? current
        : gamerChildren.map((c) => c.id).length > 0
        ? gamerChildren.map((c) => c.id)[0]
        : null
    );
    setLoading(false);
  };

  useEffect(() => {
    const getGamerChildren = async (): Promise<void> => {
      await refreshChildren();

      setChildInitializationComplete(true);
      addLoadingProgress(20);

      window.updateChild = () => {
        updateChildrenInfo();

        return;
      };
    };

    getGamerChildren();
  }, []);

  const updateSelectedChild = () => {
    setSelectedChild(initialSelectedChild(tasksToValidate) ?? childIds[0]);
  };

  const initialSelectedChild = (
    tasksToValidate: TasksToValidate
  ): null | string => {
    const defaultSelection = Object.keys(tasksToValidate)[0] ?? null;

    const childWithTaskToValidate = Object.entries(tasksToValidate).flatMap(
      ([key, value]) => {
        if (value.length > 0) {
          return key;
        } else {
          return [];
        }
      }
    );

    return childWithTaskToValidate.length === 0
      ? defaultSelection
      : childWithTaskToValidate[0];
  };

  const resetChildren = () => {
    setChildIds([]);
    setChildrenById({});
    setMedicalChildrenByGamerId({});
    setSelectedChild(null);
    setTasksToValidate({});
    setChildInitializationComplete(false);
  };

  const anyChildHasTaskTovalidate = useMemo((): boolean => {
    if (!tasksToValidate) {
      return false;
    }
    const allTaks = Object.values(tasksToValidate).flat(1);
    return allTaks.length > 0;
  }, [tasksToValidate]);

  return (
    <ChildrenContextProvider
      value={{
        childIds,
        setChildIds,
        childrenById,
        setChildrenById,
        medicalChildrenByGamerId,
        setMedicalChildrenByGamerId,
        selectedChild,
        setSelectedChild,
        tasksToValidate,
        setTasksToValidate,
        childInitilizationComplete,
        resetChildren,
        lastValidationSent,
        setLastValidationSent,
        anyChildHasTaskTovalidate,
        updateSelectedChild,
        refreshChildren,
      }}
    >
      {loading ? <Loader /> : props.children}
    </ChildrenContextProvider>
  );
};

export { useChildrenContext, ChildrenProvider };
