import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import FtueDialog from "components/FtueDialog";
import { useTranslation } from "react-i18next";
import { Icons } from "@neurosolutionsgroup/components";

export interface StartOfFTUEDialogProps {
  imgSrc: string;
  open: boolean;
  onClickContinue: VoidFunction;
  onClickDiscover: VoidFunction;
}

const StartOfFTUEDialog = ({
  imgSrc,
  open,
  onClickContinue,
  onClickDiscover,
}: StartOfFTUEDialogProps): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <FtueDialog
      withoutPadding={true}
      title={t("ftue.firstDialog.title")}
      content={
        <>
          <Box
            sx={{
              position: "absolute",
              top: "-65px",
              left: 0,
              right: 0,
              display: "flex",
              justifyContent: "center",
              img: {
                width: "135px",
                objectFit: "contain",
              },
            }}
          >
            <img src={imgSrc} alt="" />
          </Box>
          <Grid container rowSpacing={2} data-cy="ftue_start_dialog">
            <Grid item xs={12}>
              <DialogPoint
                icon={<Icons.MedalIcon color={theme.palette.secondary.light} />}
                title={t("ftue.firstDialog.points.objective.title")}
                text={t("ftue.firstDialog.points.objective.text")}
              />
            </Grid>
            <Grid item xs={12}>
              <DialogPoint
                icon={
                  <Icons.MedicationIcon color={theme.palette.secondary.light} />
                }
                title={t("ftue.firstDialog.points.prescriptions.title")}
                text={t("ftue.firstDialog.points.prescriptions.text")}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                svg: {
                  marginLeft: "8px",
                },
              }}
            >
              <DialogPoint
                icon={
                  <Icons.JournalIcon color={theme.palette.secondary.light} />
                }
                title={t("ftue.firstDialog.points.journal.title")}
                text={t("ftue.firstDialog.points.journal.text")}
              />
            </Grid>
            <Grid item xs={12} marginTop={1}>
              <Button
                data-cy="ftue_start_dialog_continue_button"
                variant="contained"
                color={"secondary"}
                fullWidth
                sx={{ borderRadius: ".5rem", padding: 2 }}
                onClick={onClickContinue}
              >
                <Typography color={"#fff"} fontWeight={"bold"}>
                  {t("ftue.firstDialog.buttons.continue")}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                color={"secondary"}
                sx={{ borderRadius: ".5rem", padding: 2 }}
                onClick={onClickDiscover}
              >
                <Typography fontWeight={"bold"}>
                  {t("ftue.firstDialog.buttons.discover")}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </>
      }
      open={open}
      sx={{
        marginTop: "40px",
        paddingTop: "45px",
        overflow: "visible",
        minWidth: 0,
        width: "90vw",
        maxWidth: "400px",
      }}
    />
  );
};
export default StartOfFTUEDialog;

interface DialogPointProps {
  icon: JSX.Element;
  title: string;
  text: string;
}

const DialogPoint = ({ icon, title, text }: DialogPointProps): JSX.Element => {
  return (
    <Grid container direction="row" alignItems="center" spacing={1}>
      <Grid
        item
        xs={2}
        display="flex"
        justifyContent="center"
        sx={{
          svg: {
            width: "1.5rem",
            height: "1.5rem",
          },
        }}
      >
        {icon}
      </Grid>
      <Grid item xs={10}>
        <Grid container direction="column">
          <Grid item>
            <Typography textAlign="left" fontWeight={600} fontSize="0.9rem">
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography textAlign="left" fontSize="0.9rem">
              {text}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
