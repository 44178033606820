import {
  AccessTime as AccessTimeIcon,
  EmojiEventsOutlined as EmojiEventsOutlinedIcon,
} from "@mui/icons-material";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { Icons } from "@neurosolutionsgroup/components";
import {
  ChallengeV2,
  ChildIconsDefintion,
  GamerChild,
} from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";
import { format, Locale } from "date-fns";
import { useTranslation } from "react-i18next";

interface ChallengeDisplayCardSummaryProps {
  open: boolean;
  challenge: ChallengeV2;
  childIcons: ChildIconsDefintion;
  childList: GamerChild[];
  currentChallengeWeek: number;
  dateLocale: Locale;
  taskIconAssets: Record<number, string>;
  toApprove: boolean;
}

const ChallengeDisplayCardSummary = ({
  open,
  challenge,
  childIcons,
  childList,
  currentChallengeWeek,
  dateLocale,
  taskIconAssets,
  toApprove,
}: ChallengeDisplayCardSummaryProps): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();

  const child = childList.find((c) => c.id === challenge.childId);

  return (
    <Box width="100%">
      <Grid container alignItems="center" spacing={1}>
        {toApprove && challenge.createdByName ? (
          <Grid item xs={12}>
            <Typography
              color="error"
              fontWeight={600}
              fontSize="0.85rem"
              textAlign="center"
              my={-0.5}
            >
              {t("routine.challenge.approval.text", {
                profName: challenge.createdByName,
              })}
            </Typography>
          </Grid>
        ) : null}
        <Grid
          item
          xs={11}
          sm={11.5}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            alignItems="center"
            sx={{
              img: {
                width: "1.5rem",
              },
            }}
          >
            {child ? (
              <>
                <img src={childIcons[child?.icon]} alt="" />
                <Typography ml={0.5} fontWeight={600} fontSize="0.85rem">
                  {child.name}
                </Typography>
              </>
            ) : null}
          </Box>

          <Box
            sx={{
              backgroundColor: "#31737C1A",
              borderRadius: "10px",
              paddingY: 0.5,
              paddingX: 1,
              marginLeft: 0.5,
            }}
          >
            <Typography fontSize="0.85rem" fontWeight={600} textAlign="center">
              {t(`settings.child.needs.needs.${challenge.skillId}`)}
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={1}
          sm={0.5}
          display="flex"
          alignItems="center"
          justifyContent="end"
          sx={{
            svg: {
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            },
          }}
        >
          <Icons.ArrowIcon
            arrowType="empty"
            arrowDirection="down"
            color={theme.palette.secondary.main}
          />
        </Grid>

        {challenge && currentChallengeWeek < 0 ? (
          <Grid item xs={12} display="flex" alignItems="center">
            <AccessTimeIcon color="secondary" fontSize="small" />
            <Typography fontSize="0.85rem" ml={0.5}>
              <i>
                {t("dashboard.skills.currentChallenge.startDate")}{" "}
                {format(
                  Tools.Time.Dates.parseDateStringToJsDate(challenge.startDate),
                  "d MMM yyyy",
                  { locale: dateLocale }
                )}
              </i>
            </Typography>
          </Grid>
        ) : null}

        <Grid
          item
          xs={12}
          sx={{
            img: {
              width: "15vw",
              maxWidth: "55px",
              marginRight: 1,
            },
            marginTop: 1,
          }}
        >
          <Box display="flex" alignItems="center">
            <img src={taskIconAssets[challenge.icon]} alt="" />
            <Typography fontSize="1rem" fontWeight={700}>
              {challenge.title}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              backgroundColor: "#31737C1A",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              padding: 0.5,
            }}
          >
            <Box pl={1.5} pr={1} pt={0.5}>
              <EmojiEventsOutlinedIcon color="secondary" />
            </Box>
            <Typography fontSize="1rem" fontWeight={600}>
              {challenge.reward.text}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChallengeDisplayCardSummary;
