import { Challenge, ChallengeHistory } from "@neurosolutionsgroup/models";
import { HookResult } from "../HookResult";
import { useChallengeContext } from "./ChallengesContext";
import FirebaseAPI from "@neurosolutionsgroup/api-client";
import Tools from "@neurosolutionsgroup/tools";
import {
  ChallengeCreated,
  ChallengeDeleted,
  ChallengeOccurrenceValidated,
  ChallengeValidated,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import { differenceInSeconds } from "date-fns";

export interface useChallengesSelectors {
  challengeFeatureFlagActive: boolean;
  challenges: Challenge[];
  challengesForApproval: Challenge[];
  challengeReadyToValidateForSelectedChild: Challenge[];
  deactivatedChallenges: string[];
  loading: boolean;
  totalChallengesToValidate: number;
}

export interface useChallengesActions {
  approveChallenge: (
    challengeId: string,
    approvalStatus: boolean,
    message?: string
  ) => Promise<void>;
  createChallenge: (challenge: Challenge) => Promise<void>;
  validateChallenges: (
    challengeId: string,
    history: ChallengeHistory[]
  ) => Promise<Challenge>;
  deleteChallenge: (challenge: Challenge) => Promise<void>;
  childValidation: (challenge: Challenge, status: boolean) => Promise<void>;
  childWasNotified: (challenge: Challenge) => Promise<void>;
}

const useChallenges = (): HookResult<
  useChallengesSelectors,
  useChallengesActions
> => {
  const {
    challengeFeatureFlagActive,
    challenges,
    setChallenges,
    challengesForApproval,
    setChallengesForApproval,
    challengeReadyToValidateForSelectedChild,
    deactivatedChallenges,
    setDeactivatedChallenges,
    totalChallengesToValidate,
    loading,
    setLoading,
  } = useChallengeContext();
  const { handleEvent } = useAnalytics();

  const createChallenge = async (
    challenge: Challenge,
    customReward?: boolean
  ): Promise<void> => {
    setLoading(true);

    try {
      await FirebaseAPI.Challenge.postChallenge(challenge);

      const event: ChallengeCreated = {
        name: "Challenge Created",
        eventProperties: {
          "Custom Reward": customReward ?? true,
          "Challenge Length Seconds": challenge.endDate
            ? differenceInSeconds(
                new Date(challenge.endDate * 1000),
                new Date()
              )
            : null,
          "Days": challenge.days,
          "Icon": challenge.icon,
          "Time Period Length Seconds":
            challenge.startTime && challenge.endTime
              ? challenge.endTime - challenge.startTime
              : null,
          "Occurrences": challenge.occurrences ?? 1,
        },
      };

      handleEvent(event);
    } finally {
      setLoading(false);
    }
  };

  const deleteChallenge = async (challenge: Challenge): Promise<void> => {
    setLoading(true);

    try {
      const updatedChallenge = await FirebaseAPI.Challenge.deleteChallenge(
        challenge.id
      );

      setChallenges((current) =>
        [...current].filter((c) => c.id !== challenge.id)
      );

      if (updatedChallenge.deleted === false) {
        setDeactivatedChallenges((current) => [...current, challenge.id]);
      }

      const event: ChallengeDeleted = {
        name: "Challenge Deleted",
      };

      handleEvent(event);
    } finally {
      setLoading(false);
    }
  };

  const validateChallenges = async (
    challengeId: string,
    history: ChallengeHistory[]
  ): Promise<Challenge> => {
    const newChallenge = await FirebaseAPI.Challenge.parentValidation(
      challengeId,
      {
        history,
      }
    );

    setChallenges((current) => [
      ...current.filter((c) => c.id !== newChallenge.id),
      newChallenge,
    ]);

    if (newChallenge.successful !== null) {
      const event: ChallengeValidated = {
        name: "Challenge Validated",
        eventProperties: {
          Status: newChallenge.successful,
        },
      };

      handleEvent(event);
    } else {
      const event: ChallengeOccurrenceValidated = {
        name: "Challenge Occurrence Validated",
        eventProperties: {
          Statuses: history.flatMap((h) =>
            h.parentStatus !== null ? h.parentStatus : []
          ),
        },
      };

      handleEvent(event);
    }

    return newChallenge;
  };

  const childValidation = async (
    challenge: Challenge,
    status: boolean
  ): Promise<void> => {
    setLoading(true);

    try {
      const updatedChallenge = await FirebaseAPI.Challenge.childValidation(
        challenge.id,
        {
          childStatus: status,
          childExecutionDate: Tools.Time.Dates.getTimeStamp(),
        }
      );

      setChallenges((current) => [
        ...current.filter((c) => c.id !== updatedChallenge.id),
        updatedChallenge,
      ]);
    } finally {
      setLoading(false);
    }
  };

  const approveChallenge = async (
    challengeId: string,
    challengeStatus: boolean,
    message?: string
  ): Promise<void> => {
    setLoading(true);

    try {
      const updatedChallenge = await FirebaseAPI.Challenge.approveChallenge(
        challengeId,
        challengeStatus,
        message
      );

      setChallenges((current) => [
        ...current.filter((c) => c.id !== updatedChallenge.id),
        updatedChallenge,
      ]);
    } finally {
      setLoading(false);
    }
  };

  const childWasNotified = async (challenge: Challenge): Promise<void> => {
    setLoading(true);

    try {
      const updatedChallenge = await FirebaseAPI.Challenge.childWasNotified(
        challenge.id
      );

      setChallengesForApproval((current) => [
        ...current.filter((c) => c.id !== updatedChallenge.id),
      ]);
    } finally {
      setLoading(false);
    }
  };

  return {
    selectors: {
      challengeFeatureFlagActive,
      challenges,
      challengesForApproval,
      challengeReadyToValidateForSelectedChild,
      deactivatedChallenges,
      loading,
      totalChallengesToValidate,
    },
    actions: {
      approveChallenge,
      childValidation,
      childWasNotified,
      createChallenge,
      validateChallenges,
      deleteChallenge,
    },
  };
};

export default useChallenges;
