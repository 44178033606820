import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CloseablePage,
  IconPinDrawerInput,
  Select,
  StickyButtons,
} from "common/Components";
import { SelectOption } from "common/Components/Select/Select";
import { SettingsInputGroup } from "common/Settings";
import { Box, ButtonBase, Input } from "@mui/material";
import { Icons } from "@neurosolutionsgroup/components";

export enum PasswordType {
  NONE,
  ICONS,
  DIGITS,
}

interface EnumSelectOption<TEnum extends number | string> extends SelectOption {
  value: TEnum;
}

export interface ChildPasswordProps {
  name: string;
  isOpen: boolean;
  onClose: VoidFunction;
  passwordType: PasswordType;
  password: string | null;
  onChange: (type: PasswordType, password: string | null) => void;
}

export const ChildPassword: React.FC<ChildPasswordProps> = (props) => {
  const { t } = useTranslation(undefined, { keyPrefix: "settings.child" });

  type PasswordTypeOption = EnumSelectOption<PasswordType>;
  const passwordTypeOptions: PasswordTypeOption[] = [
    { value: PasswordType.NONE, label: t("noPassword") },
    { value: PasswordType.ICONS, label: t("iconsPassword") },
    { value: PasswordType.DIGITS, label: t("digitsPassword") },
  ];

  const isAllDigits = (s: string) => !/\D/.test(s);

  const [passwordType, setPasswordType] = useState(props.passwordType);
  const [password, setPassword] = useState(props.password);

  useEffect(() => {
    setPassword(props.password);
    setPasswordType(props.passwordType);
  }, [props.password, props.passwordType]);

  const isPasswordValid = useMemo(() => {
    switch (passwordType) {
      case PasswordType.NONE:
        return true;
      case PasswordType.DIGITS:
        return password && isAllDigits(password);
      case PasswordType.ICONS:
        return password && isAllDigits(password);
    }
  }, [passwordType, password]);

  const onPasswordTypeChange = (option: PasswordTypeOption) => {
    setPasswordType(option.value);
    if (option.value === PasswordType.NONE) {
      setPassword(null);
    } else {
      setPassword("");
    }
  };

  const onClear = () => {
    setPassword("");
  };

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const pattern = new RegExp("^[0-9]*$");
    if (pattern.test(newValue)) {
      setPassword(newValue);
    }
  };

  const onConfirm = () => {
    if (!isPasswordValid) {
      return;
    }

    props.onChange(passwordType, password);
    props.onClose();
  };

  const onIconPinChange = (value: string) => {
    setPassword(value);
  };

  const onClose = () => {
    setPasswordType(props.passwordType);
    setPassword(props.password);
    props.onClose();
  };

  const renderPasswordInputs = () => {
    switch (passwordType) {
      case PasswordType.NONE:
        return null;
      case PasswordType.ICONS:
        return (
          <IconPinDrawerInput
            label={t("iconsPassword")}
            onChange={onIconPinChange}
            password={password}
          />
        );
      case PasswordType.DIGITS:
        return password !== null ? (
          <>
            <label htmlFor="foo">{t("password")}</label>
            <Box my={1}>
              <Input
                sx={{
                  border: "1px solid",
                  backgroundColor: "#fff",
                  borderColor: "#31737c",
                  borderRadius: "10px",
                  paddingX: "1em",
                  width: "100%",
                  height: "2.5em",
                  borderWidth: "1px",
                  svg: {
                    height: "2.5em",
                    width: "2.5em",
                    path: {
                      opacity: "0.5",
                      fill: "#31737c",
                    },
                  },
                }}
                inputProps={{ pattern: "\\d*", inputMode: "numeric" }}
                autoFocus
                disableUnderline={true}
                value={password}
                onChange={onPasswordChange}
                endAdornment={
                  password.length > 0 ? (
                    <ButtonBase onClick={onClear}>
                      <Icons.CloseIcon withCircle />
                    </ButtonBase>
                  ) : null
                }
                fullWidth
              />
            </Box>
          </>
        ) : null;
    }
  };

  const header = (
    <>
      <ButtonBase
        onClick={onClose}
        sx={{
          "marginBottom": "0.5em",
          "& svg": {
            width: "1.5em",
            height: "1.5em",
          },
        }}
      >
        <Icons.CloseIcon color={"#fff"} />
      </ButtonBase>
      <h2>{t("passwordTitle", { childName: props.name })}</h2>
    </>
  );

  return (
    <CloseablePage
      isOpen={props.isOpen}
      onClose={props.onClose}
      color="secondary"
      header={header}
      overrideNavHeader
    >
      <SettingsInputGroup labelText={t("passwordType")}>
        <Select
          label={t("passwordType")}
          value={passwordType}
          onChange={onPasswordTypeChange}
          options={passwordTypeOptions}
        />
      </SettingsInputGroup>
      {renderPasswordInputs()}
      <StickyButtons
        onConfirm={onConfirm}
        confirmText={t("confirm")}
        disabled={!isPasswordValid}
      />
    </CloseablePage>
  );
};

export default ChildPassword;
