import Context from "./Context";
import Data from "./Data";
import Environment from "./Environments";
import Language from "./Language";
import Layout from "./Layout";
import Maths from "./Maths";
import Objects from "./Objects";
import Tests from "./Tests";
import Time from "./Time";

export { ProviderProps } from "./Context";

const Tools = {
  Context,
  Data,
  Environment,
  Language,
  Layout,
  Maths,
  Objects,
  Tests,
  Time,
};

export { Tools };
