import { Box, SxProps, Theme } from "@mui/material";

interface EmptyStateGaugeProps {
  sx?: SxProps<Theme>;
}

const EmptyStateGauge = ({ sx }: EmptyStateGaugeProps): JSX.Element => {
  return (
    <Box
      sx={[
        {
          paddingX: "2rem",
          opacity: 0.57,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <svg viewBox="0 0 256 128" fill="none" width="100%">
        <path
          d="M255.999 127.998L232.959 62.0781L183.039 99.1981L191.999 127.998L255.999 127.998Z"
          fill="#2CD25A"
        />
        <path
          d="M232.956 62.0728L187.124 16.6328L163.836 75.5128L183.036 99.1928L232.956 62.0728Z"
          fill="#99E332"
        />
        <path
          d="M187.128 16.64L128 0L128 64L163.84 75.52L187.128 16.64Z"
          fill="#DEEE17"
        />
        <path
          d="M128.011 0L68.8828 14.72L93.4511 72.96L128.011 64L128.011 0Z"
          fill="#FCD313"
        />
        <path
          d="M68.8708 14.7266L23.0391 58.2466L72.3191 100.487L93.4391 72.9666L68.8708 14.7266Z"
          fill="#FE8026"
        />
        <path
          d="M23.04 58.2344L0 127.994H64L72.32 100.474L23.04 58.2344Z"
          fill="#FF233A"
        />
      </svg>
    </Box>
  );
};

export default EmptyStateGauge;
