import { ChallengeV2, SkillShortData } from "@neurosolutionsgroup/models";
import Time from "../../../Time";
import {
  add,
  differenceInWeeks,
  isAfter,
  isBefore,
  isSameWeek,
} from "date-fns";

const calculateChallengeWeekPercentages = (
  challenge: ChallengeV2
): { percentages: number[]; lastCompleteWeek: number } => {
  if (challenge.successful === true) {
    const percentages = Array(challenge.frequency).fill(100);

    return {
      percentages,
      lastCompleteWeek: challenge.duration,
    };
  }

  const percentages: number[] = [];

  let lastCompleteWeek = 0;

  // Count ongoing challenges up to most recent complete week.
  const challengeWeeks = challenge.cancelledWeek ?? challenge.duration;

  for (let weekIndex = 1; weekIndex <= challengeWeeks; weekIndex++) {
    const historyForWeek = challenge.history.filter(
      (history) =>
        history.week === weekIndex &&
        history.validationDate &&
        history.canBeRetried !== true
    );

    const isWeekComplete = historyForWeek.length >= challenge.frequency;

    if (isWeekComplete) {
      lastCompleteWeek = weekIndex;

      const successes = historyForWeek.filter(
        (history) => history.parentStatus === true
      ).length;

      const percentage = (successes / challenge.frequency) * 100;

      percentages.push(percentage);
    }
  }

  // If challenge end is passed, then consider challenge complete.
  const startDate = Time.Dates.parseDateStringToJsDate(challenge.startDate);

  const endDate = add(startDate, { weeks: challenge.duration, days: -1 });

  if (isAfter(new Date(), endDate)) {
    lastCompleteWeek = challenge.duration;
  }

  return { percentages, lastCompleteWeek };
};

const formatShortChallengeData = (
  skillId: string,
  challenges: ChallengeV2[]
): SkillShortData => {
  const data: SkillShortData = {
    skillId,
    successPercentage: null,
    lastChallengeWeeks: null,
    ongoingChallenge: false,
  };

  const challengesForSkill = challenges.filter(
    (challenge) => challenge.skillId === skillId
  );

  const percentages: number[] = [];

  let latestCompletedChallenge = 0;

  if (challengesForSkill.length > 0) {
    challengesForSkill.forEach((challenge) => {
      // Challenge is ongoing if no result or challenge is ended.
      if (challenge.successful === null) {
        const challengeEndDate = add(
          Time.Dates.parseDateStringToJsDate(challenge.startDate),
          {
            weeks: challenge.duration,
            days: -1,
          }
        );

        data.ongoingChallenge = isBefore(new Date(), challengeEndDate);
      }

      const { percentages: challengeWeekPercentages, lastCompleteWeek } =
        calculateChallengeWeekPercentages(challenge);

      percentages.push(...challengeWeekPercentages);

      if (lastCompleteWeek > 0) {
        const challengeCompletionDate = Time.Dates.getTimeStamp(
          add(Time.Dates.parseDateStringToJsDate(challenge.startDate), {
            weeks: lastCompleteWeek,
            days: -1,
          })
        );

        if (challengeCompletionDate > latestCompletedChallenge) {
          latestCompletedChallenge = challengeCompletionDate;
        }
      }
    });

    if (percentages.length > 0) {
      const sum = percentages.reduce((a, b) => a + b, 0);

      const average = sum / percentages.length;

      data.successPercentage = average;
    }

    if (latestCompletedChallenge > 0) {
      const latestChallengeDate = latestCompletedChallenge * 1000;

      const lastChallengeThisWeek = isSameWeek(
        new Date(),
        latestChallengeDate,
        { weekStartsOn: 1 }
      );

      const weeksSinceLastChallenge =
        differenceInWeeks(new Date(), latestChallengeDate) + 1;

      data.lastChallengeWeeks = lastChallengeThisWeek
        ? 0
        : weeksSinceLastChallenge;
    }
  }

  return data;
};

export default formatShortChallengeData;
