import { Typography, Button, Box, useTheme } from "@mui/material";
import { Icons } from "@neurosolutionsgroup/components";
import { orderedNeedKeys } from "@neurosolutionsgroup/models";
import { ChildNeedsPage } from "@neurosolutionsgroup/webviews-pages";
import { PageSection } from "common/Components/PageSection";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SAFE_AREAS } from "stylesheets";

export interface EditNeedsProps {
  needs: string[];
  onNeedsChange: (needs: string[]) => void;
}

const EditNeeds: React.FC<EditNeedsProps> = ({ needs, onNeedsChange }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "settings.child.needs",
  });

  const { palette } = useTheme();

  const [needsEditionOpen, setNeedsEditionOpen] = useState<boolean>(false);

  return (
    <PageSection title={t("editionPage.title")}>
      <Typography>{t("editionPage.question")}</Typography>
      <Button
        onClick={() => setNeedsEditionOpen(true)}
        fullWidth
        sx={{ marginTop: 1 }}
      >
        <Typography>{t("editionPage.edition")}</Typography>
        <Box ml="auto">
          {
            <Icons.ArrowIcon
              arrowDirection="right"
              color={palette.secondary.main}
            />
          }
        </Box>
      </Button>
      {needs.length > 0 ? (
        <ul>
          {needs.map((n) => {
            return (
              <li key={n}>
                <Typography>{t("needs." + n)}</Typography>
              </li>
            );
          })}
        </ul>
      ) : (
        <Typography>{t("editionPage.noNeeds")}</Typography>
      )}
      {needsEditionOpen && (
        <ChildNeedsPage
          safeAreas={SAFE_AREAS}
          sx={{
            zIndex: 10000,
          }}
          onSubmit={(needs) => {
            onNeedsChange(needs);
            setNeedsEditionOpen(false);
          }}
          orderedNeedKeys={orderedNeedKeys}
          preselectedNeeds={needs}
        />
      )}
    </PageSection>
  );
};
export default EditNeeds;
