import React, { SetStateAction, useState } from "react";
import TaskCard from "./TaskCard";
import { v4 } from "uuid";
import TaskEdition from "../TaskEdition/TaskEdition";
import { useCoach } from "common/hooks/messaging/Coach";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { Routine, Task } from "@neurosolutionsgroup/models";
import Tools from "@neurosolutionsgroup/tools";
import {
  PremiumFeature,
  TaskDeleted,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import { Box, ButtonBase, useTheme } from "@mui/material";
import CoachErrorDialog, {
  CoachError,
} from "common/hooks/messaging/Coach/CoachErrorDialog";
import {
  FTUEFlow,
  FTUEFlowDefinitions,
  FirstRoutineProgress,
  useFTUE,
} from "@neurosolutionsgroup/webviews-ftue";
import { Icons, PremiumLock } from "@neurosolutionsgroup/components";
import useSubscription from "common/hooks/subscription/useSubscription";

export enum TaskOperation {
  Add,
  Copy,
  Edit,
}

interface TaskDisplayProps {
  tasks: Task[];
  setTasks: React.Dispatch<SetStateAction<Task[]>>;
  routine: Routine;
  routineStart: number;
  routineEnd: number;
}

const TaskDisplayComponent: React.FC<TaskDisplayProps> = ({
  tasks,
  setTasks,
  routine,
  routineStart,
  routineEnd,
}) => {
  const {
    selectors: { progress },
    actions: { canDisplayFlow, onTaskAddClicked },
  } = useFTUE();
  const { handleEvent } = useAnalytics();
  const { onTaskCreated } = useCoach();
  const { palette } = useTheme();

  const { onPremiumFeatureClick, permissions } = useSubscription();

  const [selectedTask, setSelectedTask] = useState<Task | undefined>(undefined);
  const [showTaskEdition, setShowTaskEdition] = useState(false);
  const [taskOperation, setTaskOperation] = useState<TaskOperation>(
    TaskOperation.Add
  );
  const [showTaskLimit, setShowTaskLimit] = useState(false);

  const taskCount = tasks.filter((t) => !t.deleted).length;

  const onTaskAdd = () => {
    onTaskCreated(tasks);
    setTaskOperation(TaskOperation.Add);
    setSelectedTask({
      id: v4(),
      name: "",
      icon: 0,
      reminder: 0,
      routine: routine.id,
      creation: Tools.Time.Dates.getTimeStamp(),
      index: tasks.length,
      category: null,
    });
    onTaskAddClicked();
    setShowTaskEdition(true);
  };

  const onTaskEdit = (taskId: string) => {
    const task = tasks.find((t) => t.id === taskId);

    if (task) {
      setTaskOperation(TaskOperation.Edit);
      setSelectedTask({ ...task });
      setShowTaskEdition(true);
    }
  };

  const onTaskCopy = (taskId: string) => {
    const task = tasks.find((t) => t.id === taskId);

    if (task) {
      const newTask = {
        ...task,
        id: v4(),
        name: task.name + " (copy)",
        creation: Tools.Time.Dates.getTimeStamp(),
      };

      setTaskOperation(TaskOperation.Copy);
      setSelectedTask(newTask);
      setShowTaskEdition(true);
    }
  };

  const onTaskDelete = (taskId: string) => {
    const event: TaskDeleted = {
      name: "Task Deleted",
      eventProperties: {
        "Type": "App",
        "Routine ID": routine.id,
      },
    };
    handleEvent(event);

    setTasks((current) => {
      const clone = [...current];

      const index = clone.findIndex((t) => t.id === taskId);

      clone[index].deleted = true;

      // Move deleted task to end of list.
      Array.prototype.splice.call(
        clone,
        clone.length - 1,
        0,
        Array.prototype.splice.call(clone, index, 1)[0]
      );

      return clone;
    });
  };

  const onTaskChanged = (task: Task) => {
    if (taskOperation === TaskOperation.Copy) {
      setTasks((current) => {
        return [...current, task];
      });
    } else {
      setTasks((current) => {
        const clone = [...current];

        const index = clone.findIndex((t) => t.id === task.id);

        if (index === -1) {
          clone.push(task);
        } else {
          clone[index] = task;
        }

        return clone;
      });
    }

    setShowTaskEdition(false);
    setSelectedTask(undefined);
  };

  const onDragEnd = (result: DropResult) => {
    setTasks((current) => {
      if (result.destination) {
        const deleted = current.filter((t) => t.deleted);

        const clone = [...current.filter((t) => !t.deleted)];

        Array.prototype.splice.call(
          clone,
          result.destination.index,
          0,
          Array.prototype.splice.call(clone, result.source.index, 1)[0]
        );

        return clone.concat(deleted);
      } else {
        return current;
      }
    });
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="task-display">
          <Droppable droppableId="task-display__droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="task-display__tasks"
              >
                {tasks
                  .filter((t) => !t.deleted)
                  .slice(0, 2)
                  .map((t, i) =>
                    i === 0 ? (
                      <TaskCard
                        key={t.id}
                        task={t}
                        index={i}
                        id={t.id}
                        onCopy={() => {
                          onTaskCopy(t.id);
                        }}
                        onEdit={() => {
                          onTaskEdit(t.id);
                        }}
                        onDelete={() => {
                          onTaskDelete(t.id);
                        }}
                      />
                    ) : (
                      <TaskCard
                        key={t.id}
                        task={t}
                        index={i}
                        id={t.id}
                        onCopy={() => {
                          onTaskCopy(t.id);
                        }}
                        onEdit={() => {
                          onTaskEdit(t.id);
                        }}
                        onDelete={() => {
                          onTaskDelete(t.id);
                        }}
                      />
                    )
                  )}
                {tasks
                  .filter((t) => !t.deleted)
                  .slice(2)
                  .map((t, i) => (
                    <TaskCard
                      key={t.id}
                      task={t}
                      index={i + 2}
                      id={t.id}
                      onCopy={() => {
                        onTaskCopy(t.id);
                      }}
                      onEdit={() => {
                        onTaskEdit(t.id);
                      }}
                      onDelete={() => {
                        onTaskDelete(t.id);
                      }}
                    />
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <FTUEFlowDefinitions.FirstRoutineFTUEFlow.Hints.AddTask>
            <Box mt="1em">
              <PremiumLock
                active={taskCount >= permissions.tasksPerRoutine}
                onClick={() => onPremiumFeatureClick(PremiumFeature.AddTask)}
                variant="long"
                borderRadius="15px"
                display="block"
              >
                <ButtonBase
                  data-cy="add-task-button"
                  onClick={onTaskAdd}
                  disabled={
                    canDisplayFlow(FTUEFlow.FirstRoutine) &&
                    ![
                      FirstRoutineProgress.TaskOrder,
                      FirstRoutineProgress.TaskComplete,
                      FirstRoutineProgress.TemplateChosen,
                      FirstRoutineProgress.RoutineComplete,
                    ].includes(progress[FTUEFlow.FirstRoutine].progress)
                  }
                  sx={{
                    "backgroundColor": "#fff",
                    "boxShadow": "0 4px 13px 3px rgb(0 0 0 / 15%)",
                    "borderRadius": "15px",
                    "width": "100%",
                    "height": "5em",
                    "&:disabled": {
                      opacity: 0.5,
                    },
                    "svg": {
                      width: "2em",
                      height: "2em",
                    },
                  }}
                >
                  <Icons.AddIcon color={palette.primary.main} />
                </ButtonBase>
              </PremiumLock>
            </Box>
          </FTUEFlowDefinitions.FirstRoutineFTUEFlow.Hints.AddTask>
        </div>
        {showTaskEdition && selectedTask ? (
          <TaskEdition
            task={selectedTask}
            operation={taskOperation}
            routineId={routine.id}
            routineStart={routineStart}
            routineEnd={routineEnd}
            open={showTaskEdition}
            onClose={() => setShowTaskEdition(false)}
            setTask={onTaskChanged}
          />
        ) : null}

        <CoachErrorDialog
          open={showTaskLimit}
          onClose={() => setShowTaskLimit(false)}
          error={CoachError.TasksPerRoutine}
        />
      </DragDropContext>
    </>
  );
};

const TaskDisplay: React.FC<TaskDisplayProps> = (props) => {
  return <TaskDisplayComponent {...props} />;
};

export default TaskDisplay;
