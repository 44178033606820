import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import AppProviders from "AppProviders";
import FirebaseContext from "./common/Services/FireBase/Firebase";
import * as Sentry from "@sentry/react";
import Tools from "@neurosolutionsgroup/tools";
import environmentVariable from "common/Tools/Env";

FirebaseContext.tools.initializeFirebaseApp();

Sentry.init({
  dsn:
    Tools.Environment.getEnvironmentVariable(environmentVariable.SentryDsn) ??
    "",
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 0.1,
  environment: Tools.Environment.isStaging() ? "staging" : "production",
});

Sentry.setTag("web_build", Tools.Environment.webviewsEnvTools.getBuildNumber());

const container = document.getElementById("root");

if (!container) {
  throw new Error("Container element not found in DOM.");
}

const root = createRoot(container);

root.render(
  <AppProviders>
    <App />
  </AppProviders>
);
