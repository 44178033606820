import FirebaseAPI from "@neurosolutionsgroup/api-client";
import useParameters from "common/hooks/Parameters/useParameters";
import { MedPeriod } from "./PeriodPicker";
import {
  Language,
  RoutinePeriod,
  SideEffectChoice,
} from "@neurosolutionsgroup/models";
import * as Sentry from "@sentry/react";
import {
  DashboardPrescriptionExported,
  DashboardRoutineExported,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import {
  medPeriodLoc,
  routinePeriodLoc,
} from "@neurosolutionsgroup/localization";
import { UniWebViewActions } from "common/hooks/Parameters/UniWebViewActions";

interface UsePDFExport {
  onExportMedical: (
    gamerChildId: string,
    period: MedPeriod,
    choice: SideEffectChoice,
    language: Language,
    sideEffectId?: string,
    test?: boolean
  ) => Promise<boolean>;
  onExportRoutine: (
    gamerChildId: string,
    period: RoutinePeriod,
    language: Language,
    test?: boolean
  ) => Promise<boolean>;
  onExportSymptom: (
    gamerChildId: string,
    period: MedPeriod,
    choice: SideEffectChoice,
    language: Language,
    sideEffectId?: string,
    test?: boolean
  ) => Promise<boolean>;
}

const blobToBase64 = (blob: Blob): Promise<string | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result?.toString() ?? null);
    reader.onerror = (error) => reject(error);
  });

const usePDFExport = (): UsePDFExport => {
  const { sendMessageToUnity } = useParameters();
  const { handleEvent } = useAnalytics();

  const onExportMedical = async (
    gamerChildId: string,
    period: MedPeriod,
    choice: SideEffectChoice,
    language: Language,
    sideEffectId?: string,
    test = false
  ): Promise<boolean> => {
    const exportTransaction = Sentry.startTransaction({
      name: "export-medical",
    });

    const requestSpan = exportTransaction.startChild({
      op: "request",
      description: "request pdf from backend",
    });

    const event: DashboardPrescriptionExported = {
      name: "Dashboard Prescription Exported",
      eventProperties: {
        period: medPeriodLoc.en[period],
        option: choice,
      },
    };

    handleEvent(event);

    const blob = await FirebaseAPI.DataExport.getMedicalPdf({
      gamerChildId,
      period,
      choice,
      language,
      sideEffectId,
    });

    requestSpan.finish();

    const unityMessageSpan = exportTransaction.startChild({
      op: "message",
      description: "send message to Unity",
    });

    await sendResponse(
      "medical",
      language,
      medPeriodLoc[language][period],
      blob,
      test
    );

    unityMessageSpan.finish();
    exportTransaction.finish();

    return true;
  };

  const onExportRoutine = async (
    gamerChildId: string,
    period: RoutinePeriod,
    language: Language,
    test = false
  ) => {
    const exportTransaction = Sentry.startTransaction({
      name: "export-routine",
    });

    const requestSpan = exportTransaction.startChild({
      op: "request",
      description: "request pdf from backend",
    });

    const event: DashboardRoutineExported = {
      name: "Dashboard Routine Exported",
      eventProperties: {
        period: routinePeriodLoc.en[period],
      },
    };

    handleEvent(event);

    const blob = await FirebaseAPI.DataExport.getRoutinePdf({
      gamerChildId,
      period,
      language,
    });

    requestSpan.finish();

    const unityMessageSpan = exportTransaction.startChild({
      op: "message",
      description: "send message to Unity",
    });

    await sendResponse(
      "routine",
      language,
      routinePeriodLoc[language][period],
      blob,
      test
    );

    unityMessageSpan.finish();
    exportTransaction.finish();

    return true;
  };

  const onExportSymptom = async (
    gamerChildId: string,
    period: MedPeriod,
    choice: SideEffectChoice,
    language: Language,
    sideEffectId?: string,
    test = false
  ): Promise<boolean> => {
    const event: DashboardPrescriptionExported = {
      name: "Dashboard Prescription Exported",
      eventProperties: {
        period: medPeriodLoc.en[period],
        option: choice,
      },
    };

    handleEvent(event);

    const blob = await FirebaseAPI.DataExport.getSymptomPdf({
      gamerChildId,
      period,
      choice,
      language,
      sideEffectId,
    });

    await sendResponse(
      "symptom",
      language,
      medPeriodLoc[language][period],
      blob,
      test
    );

    return true;
  };

  const sendResponse = async (
    requestType: "routine" | "medical" | "symptom",
    language: Language,
    period: string,
    blob: Blob,
    test?: boolean
  ): Promise<boolean> => {
    const dataUri = await blobToBase64(blob);

    if (dataUri !== null) {
      const dataUriSplit = dataUri.split(",");

      const base64data = dataUriSplit[dataUriSplit.length - 1];

      let requestParams;

      if (requestType === "medical") {
        const fileName = `${language.toUpperCase()}_prescription_${period.replace(
          " ",
          "-"
        )}`;

        requestParams = `fileName=${fileName}&data=${base64data}`;
      } else if (requestType === "symptom") {
        const fileName = `${language.toUpperCase()}_symptom_${period.replace(
          " ",
          "-"
        )}`;

        requestParams = `fileName=${fileName}&data=${base64data}`;
      } else {
        const fileName = `${language.toUpperCase()}_routine_${period.replace(
          " ",
          "-"
        )}`;

        requestParams = `fileName=${fileName}&data=${base64data}`;
      }

      sendMessageToUnity(UniWebViewActions.Download, requestParams);

      if (test) {
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      }

      return true;
    }

    return false;
  };

  return {
    onExportMedical,
    onExportRoutine,
    onExportSymptom,
  };
};

export default usePDFExport;
