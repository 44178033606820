import { Button, Dialog, DialogActions, dialogClasses } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import ChallengeSummaryDetails from "./ChallengeSummary/ChallengeSummaryDetails";
import ChallengeSummaryError from "./ChallengeSummary/ChallengeSummaryError";

interface ChallengeSummaryProps {
  open: boolean;
  onContinue: VoidFunction;
  onDismiss: VoidFunction;
  childName: string;
  title: string;
  days: number | null;
  startTime: number | null;
  endTime: number | null;
  endDateMS: number | null;
  occurrences: number;
  canBeSummited: boolean;
}

const ChallengeSummary = ({
  open,
  onContinue,
  onDismiss,
  childName,
  title,
  days,
  startTime,
  endTime,
  endDateMS,
  occurrences,
  canBeSummited,
}: ChallengeSummaryProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onDismiss}
      sx={{
        [`& .${dialogClasses.paper}`]: {
          minWidth: "80vw",
        },
      }}
      data-cy="challenge-summary-pop-up"
    >
      {canBeSummited ? (
        <ChallengeSummaryDetails
          childName={childName}
          title={title}
          days={days}
          startTime={startTime}
          endTime={endTime}
          endDateMS={endDateMS}
          occurrences={occurrences}
        />
      ) : (
        <ChallengeSummaryError />
      )}
      <DialogActions>
        <Button onClick={() => onDismiss()}>
          {t("routine.challenge.summary.back")}
        </Button>
        {canBeSummited && (
          <Button
            color="secondary"
            onClick={() => onContinue()}
            data-cy="challenge-summary-confirm-button"
          >
            {t("general.actions.agree")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ChallengeSummary;
