import { Box, Typography } from "@mui/material";
import { Challenge } from "@neurosolutionsgroup/models";
import ChallengeInfoDrawer from "pages/Objectives/ChallengeInfoDrawer/ChallengeInfoDrawer";
import React from "react";
import { useTranslation } from "react-i18next";
import ChallengeCard from "./ChallengeCard";

export interface ActiveChallengesProps {
  activeChallenges: Challenge[];
  onChallengeDelete: (c: Challenge) => void;
}

const ActiveChallenges: React.FC<ActiveChallengesProps> = ({
  activeChallenges,
  onChallengeDelete,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h2" my={1}>
          {t("routine.challenge.title")}
        </Typography>
        <ChallengeInfoDrawer />
      </Box>
      <Box data-cy="active-challenge-list">
        {activeChallenges
          .sort((a, b) => (b.createdDate ?? 0) - (a.createdDate ?? 0))
          .map((c) => (
            <Box key={c.id} pb={2}>
              <ChallengeCard
                challenge={c}
                onDelete={() => onChallengeDelete(c)}
              />
            </Box>
          ))}
      </Box>
    </>
  );
};
export default ActiveChallenges;
