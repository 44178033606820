import {
  FollowedSideEffect,
  Prescription,
  Record,
  RecordSummary,
  SimplifiedRecordWithQuestion,
} from "@neurosolutionsgroup/models";
import { useCallback, useMemo } from "react";
import useChildren from "../children/useChildren";
import { HookResult } from "../HookResult";
import {
  FollowedSideEffectsByGamerChildId,
  PrescriptionsByGamerChildId,
  useFollowUpContext,
} from "./FollowUpContext";
import FollowUpLogic from "./FollowUpLogic";
import FirebaseAPI from "@neurosolutionsgroup/api-client";
import { useAppDataContext } from "../AppDataContext";
import Tools from "@neurosolutionsgroup/tools";
import { sub } from "date-fns";
import {
  PrescriptionArchived,
  PrescriptionCreated,
  PrescriptionUpdated,
  SideEffectsUpdated,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import useMedication from "../medications/useMedication";
import useSideEffect from "../sideEffect/useSideEffect";

export interface useFollowUpSelector {
  anyChildHasObservation: boolean;
  followedSideEffectsByGamerChildId: FollowedSideEffectsByGamerChildId;
  getActiveFollowedSideEffects: (gamerChildId?: string) => FollowedSideEffect[];
  getActivePrescriptionsForSelectedChild: () => Prescription[];
  getLastWeeksPrescriptionsByChild: (gamerChildId: string) => Prescription[];
  getObservationsToDo: (gamerChildId?: string) => FollowedSideEffect[];
  getRecordsForSideEffectAndTimePeriod: (
    sideEffect: string,
    periodWeeks: number,
    medicalChildId: string
  ) => { records: SimplifiedRecordWithQuestion[]; prescriptions: string[] };
  prescriptionsByGamerChildId: PrescriptionsByGamerChildId;
  recordsInitializationComplete: boolean;
  userHasPrescription: boolean;
  childrenWithFilledFollowUp: string[];
}

export interface useFollowUpActions {
  createFollowUp: (
    gamerChildId: string,
    prescriptions: Prescription[],
    fse: string[]
  ) => Promise<void>;
  createPrescription: (p: Prescription) => void;
  createRecords: (gamerChildId: string, records: Record[]) => Promise<void>;
  terminatePrescription: (prescriptionIds: string[]) => Promise<void>;
  updateFollowedSideEffects: (
    gamerChildId: string,
    sideEffectIds: string[]
  ) => void;
  updatePrescription: (
    gamerChildId: string,
    prescription: Prescription
  ) => Promise<void>;
}

const useFollowUp = (): HookResult<useFollowUpSelector, useFollowUpActions> => {
  // Context.
  const {
    prescriptionsByGamerChildId,
    setPrescriptionsByGamerChildId,
    followedSideEffectsByGamerChildId,
    setFollowedSideEffectsByGamerChildId,
    recordsSummaries,
    recordsInitializationComplete,
    anyChildHasObservation,
  } = useFollowUpContext();

  const { setLoading } = useAppDataContext();

  // Hooks.
  const {
    selectors: { childrenById, medicalChildrenByGamerId, selectedChild },
    actions: { childHasMedicalInfo },
  } = useChildren();
  const {
    selectors: { medicationById },
  } = useMedication();
  const {
    selectors: { sideEffectById },
  } = useSideEffect();

  const { handleEvent } = useAnalytics();

  const userHasPrescription = useMemo(() => {
    let i = 0;
    Object.values(prescriptionsByGamerChildId).forEach((value) => {
      i = i + value.length;
    });
    return i > 0;
  }, [prescriptionsByGamerChildId]);

  // #region Selectors.

  const getAccountPrescriptionsCount = (): number => {
    let count = 0;

    Object.values(prescriptionsByGamerChildId).forEach((prescriptions) => {
      count += prescriptions.filter((p) => !p.endDate).length;
    });

    return count;
  };

  const getAccountSideEffectsCount = (): number => {
    let count = 0;

    Object.values(followedSideEffectsByGamerChildId).forEach((sideEffects) => {
      count += sideEffects.filter((se) => se.isActive).length;
    });

    return count;
  };

  /**
   * Get follow up side effects for last weeks journal, if no child ID is passed, uses global selected child.
   */
  const getActiveFollowedSideEffects = useCallback(
    (gamerChildId?: string): FollowedSideEffect[] => {
      if (!gamerChildId) {
        if (selectedChild) {
          gamerChildId = selectedChild;
        } else {
          return [];
        }
      }

      const followedSideEffects =
        followedSideEffectsByGamerChildId[gamerChildId] ?? [];

      return followedSideEffects.filter((fse) => fse.isActive);
    },
    [followedSideEffectsByGamerChildId, selectedChild]
  );

  const getActivePrescriptionsForSelectedChild =
    useCallback((): Prescription[] => {
      return selectedChild && prescriptionsByGamerChildId[selectedChild]
        ? prescriptionsByGamerChildId[selectedChild].filter((p) => !p.endDate)
        : [];
    }, [prescriptionsByGamerChildId, selectedChild]);

  const getLastWeeksPrescriptionsByChild = useCallback(
    (gamerChildId: string): Prescription[] => {
      return FollowUpLogic.getLastWeeksPrescriptions(
        prescriptionsByGamerChildId[gamerChildId] ?? []
      );
    },
    [prescriptionsByGamerChildId]
  );

  const getMedicalChildId = (gamerChildId: string): string => {
    if (!childHasMedicalInfo(gamerChildId)) {
      throw new Error(
        `Medical hook request received for a child that does not have a corresponding medical child, gamerChildId: ${gamerChildId}.`
      );
    }

    return medicalChildrenByGamerId[gamerChildId].medicalChildId;
  };

  const getRecordsForSideEffectAndTimePeriod = useCallback(
    (
      sideEffect: string,
      periodWeeks: number,
      medicalChildId: string
    ): { records: SimplifiedRecordWithQuestion[]; prescriptions: string[] } => {
      const allRecordSummariesForSideEffect: RecordSummary[] =
        recordsSummaries[sideEffect]?.filter(
          (r) => r.medicalChildId === medicalChildId
        ) ?? [];

      const earliestDate: Date = sub(new Date(), { weeks: periodWeeks + 1 });

      const result = Tools.Data.Medical.extractRecordsFromSummaries(
        allRecordSummariesForSideEffect,
        earliestDate.toISOString()
      );

      return {
        records: result.recordsMap[sideEffect] ?? [],
        prescriptions: result.joinedPrescriptionIds,
      };
    },
    [recordsSummaries]
  );

  /**
   * Get all child that have at least on one observation done
   */
  const childrenWithFilledFollowUp: string[] = useMemo(() => {
    let output: string[] = [];
    Object.values(recordsSummaries).forEach((rs) => {
      rs.forEach((r) => {
        output = output
          .filter((f) => f !== r.medicalChildId)
          .concat([r.medicalChildId]);
      });
    });
    return output;
  }, [recordsSummaries]);

  /**
   * Get follow up side effects that were active and have not yet been answered for last weeks journal, if no child ID is passed, uses global selected child.
   */
  const getObservationsToDo = useCallback(
    (gamerChildId?: string): FollowedSideEffect[] => {
      if (!gamerChildId) {
        if (!selectedChild) {
          return [];
        } else {
          gamerChildId = selectedChild;
        }
      }

      if (childrenById[gamerChildId]?.isDisabled) {
        return [];
      }

      const followedSideEffects =
        followedSideEffectsByGamerChildId[gamerChildId] ?? [];

      return FollowUpLogic.getObservationsToDo(
        followedSideEffects,
        recordsSummaries
      );
    },
    [followedSideEffectsByGamerChildId, selectedChild, recordsSummaries]
  );

  // #endregion

  // #region Actions.

  const createFollowUp = async (
    gamerChildId: string,
    prescriptions: Prescription[],
    followedSideEffects: string[]
  ) => {
    setLoading(true);
    try {
      const medicalChildId = getMedicalChildId(gamerChildId);

      await Promise.all([
        FirebaseAPI.Medical.Prescription.createPrescriptions(medicalChildId, {
          prescriptions,
        }),
        FirebaseAPI.Medical.FollowedSideEffect.updateFollowedSideEffects(
          medicalChildId,
          { sideEffects: followedSideEffects }
        ),
      ]).then((responses) => {
        const resultPrescriptions = responses[0];

        setPrescriptionsByGamerChildId((current) => {
          const clone = { ...current };

          if (clone[gamerChildId]) {
            clone[gamerChildId].push(...resultPrescriptions);
          } else {
            clone[gamerChildId] = resultPrescriptions;
          }

          return clone;
        });

        const resultSideEffects = responses[1];
        const responsesForActiveChild = resultSideEffects.filter(
          (fse) => fse.medicalChildId === gamerChildId
        );

        setFollowedSideEffectsByGamerChildId((current) => {
          const clone = { ...current };

          if (clone[gamerChildId]) {
            clone[gamerChildId] = clone[gamerChildId].filter(
              (fse) =>
                !responsesForActiveChild
                  .map((fse) => fse.sideEffectId)
                  .includes(fse.sideEffectId)
            );

            clone[gamerChildId].push(...responsesForActiveChild);
          } else {
            clone[gamerChildId] = responsesForActiveChild;
          }

          return clone;
        });

        // Analytics.
        prescriptions.forEach((p) => {
          const event: PrescriptionCreated = {
            name: "Prescription Created",
            eventProperties: {
              "Kid ID": gamerChildId,
              "Medication Name":
                medicationById && medicationById[p.drug.drugId]
                  ? medicationById[p.drug.drugId].name.en
                  : "",
              "Date": new Date(p.startDate).toISOString(),
              "Hour": p.drug.doses[0]?.time,
              "Dosage": p.drug.doses[0]?.quantity,
              "Hour2":
                p.drug.doses.length > 1 ? p.drug.doses[1]?.time : undefined,
              "Dosage2":
                p.drug.doses.length > 1 ? p.drug.doses[1]?.quantity : undefined,
              "Hour3":
                p.drug.doses.length > 2 ? p.drug.doses[2]?.time : undefined,
              "Dosage3":
                p.drug.doses.length > 2 ? p.drug.doses[2]?.quantity : undefined,
            },
            setProperties: {
              "Prescriptions Count": getAccountPrescriptionsCount(),
            },
          };

          handleEvent(event);
        });

        const event: SideEffectsUpdated = {
          name: "Side Effects Updated",
          eventProperties: {
            "Kid ID": gamerChildId,
            "Side Effects": followedSideEffects.flatMap((fse) =>
              sideEffectById && sideEffectById[fse]
                ? [sideEffectById[fse].name.en]
                : []
            ),
          },
          setProperties: {
            "Side Effects Count": getAccountSideEffectsCount(),
          },
        };

        handleEvent(event);
      });
    } finally {
      setLoading(false);
    }
  };

  const createPrescription = async (p: Prescription) => {
    if (selectedChild) {
      const medicalChildId = getMedicalChildId(selectedChild);
      await FirebaseAPI.Medical.Prescription.createPrescriptions(
        medicalChildId,
        {
          prescriptions: [p],
        }
      ).then((res) => {
        setPrescriptionsByGamerChildId((current) => {
          const clone = { ...current };

          if (clone[selectedChild]) {
            clone[selectedChild].push(...res);
          } else {
            clone[selectedChild] = res;
          }

          return clone;
        });
        const event: PrescriptionCreated = {
          name: "Prescription Created",
          eventProperties: {
            "Kid ID": selectedChild,
            "Medication Name":
              medicationById && medicationById[p.drug.drugId]
                ? medicationById[p.drug.drugId].name.en
                : "",
            "Date": new Date(p.startDate).toISOString(),
            "Hour": p.drug.doses[0]?.time,
            "Dosage": p.drug.doses[0]?.quantity,
            "Hour2":
              p.drug.doses.length > 1 ? p.drug.doses[1]?.time : undefined,
            "Dosage2":
              p.drug.doses.length > 1 ? p.drug.doses[1]?.quantity : undefined,
            "Hour3":
              p.drug.doses.length > 2 ? p.drug.doses[2]?.time : undefined,
            "Dosage3":
              p.drug.doses.length > 2 ? p.drug.doses[2]?.quantity : undefined,
          },
          setProperties: {
            "Prescriptions Count": getAccountPrescriptionsCount(),
          },
        };

        handleEvent(event);
      });
    }
  };

  const createRecords = async (
    gamerChildId: string,
    records: Record[]
  ): Promise<void> => {
    const sideEffectId = records[0]?.sideEffectId;
    if (sideEffectId && followedSideEffectsByGamerChildId[gamerChildId]) {
      const fseId = followedSideEffectsByGamerChildId[gamerChildId].find(
        (fse) => fse.sideEffectId === sideEffectId
      )?.sideEffectId;
      if (records.length > 0 && fseId) {
        setLoading(true);
        try {
          await FirebaseAPI.Medical.Record.postRecords(
            medicalChildrenByGamerId[gamerChildId].medicalChildId,
            fseId,
            { records }
          );
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const terminatePrescription = async (
    prescriptionIds: string[]
  ): Promise<void> => {
    setLoading(true);
    try {
      if (selectedChild) {
        const medicalChildId = getMedicalChildId(selectedChild);
        prescriptionIds.forEach(async (id) => {
          const prescriptionToTerminate = prescriptionsByGamerChildId[
            selectedChild
          ].find((p) => p.prescriptionId === id);

          if (prescriptionToTerminate) {
            prescriptionToTerminate.endDate =
              Tools.Time.Strings.getDateStringFromDate(new Date());

            await FirebaseAPI.Medical.Prescription.updatePrescription(
              medicalChildId,
              prescriptionToTerminate
            );

            setPrescriptionsByGamerChildId((current) => {
              const clone = { ...current };
              const i = clone[selectedChild].findIndex(
                (p) => p.prescriptionId === id
              );
              clone[selectedChild][i] = prescriptionToTerminate;
              return clone;
            });

            const event: PrescriptionArchived = {
              name: "Prescription Archived",
              eventProperties: {
                "Kid ID": prescriptionToTerminate.medicalChildId,
                "Medication Name":
                  medicationById &&
                  medicationById[prescriptionToTerminate.drug.drugId]
                    ? medicationById[prescriptionToTerminate.drug.drugId].name
                        .en
                    : "",
                "Date": new Date(
                  prescriptionToTerminate.startDate
                ).toISOString(),
                "Hour": prescriptionToTerminate.drug.doses[0]?.time,
                "Dosage": prescriptionToTerminate.drug.doses[0]?.quantity,
                "Hour2":
                  prescriptionToTerminate.drug.doses.length > 1
                    ? prescriptionToTerminate.drug.doses[1]?.time
                    : undefined,
                "Dosage2":
                  prescriptionToTerminate.drug.doses.length > 1
                    ? prescriptionToTerminate.drug.doses[1]?.quantity
                    : undefined,
                "Hour3":
                  prescriptionToTerminate.drug.doses.length > 2
                    ? prescriptionToTerminate.drug.doses[2]?.time
                    : undefined,
                "Dosage3":
                  prescriptionToTerminate.drug.doses.length > 2
                    ? prescriptionToTerminate.drug.doses[2]?.quantity
                    : undefined,
              },
              setProperties: {
                "Prescriptions Count": getAccountPrescriptionsCount(),
              },
            };

            handleEvent(event);
          }
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const updateFollowedSideEffects = async (
    gamerChildId: string,
    sideEffectIds: string[]
  ) => {
    setLoading(true);
    try {
      const medicalChildId = getMedicalChildId(gamerChildId);

      const updatedFollowedSideEffects =
        await FirebaseAPI.Medical.FollowedSideEffect.updateFollowedSideEffects(
          medicalChildId,
          { sideEffects: sideEffectIds }
        );

      setFollowedSideEffectsByGamerChildId((current) => {
        const clone = { ...current };

        clone[gamerChildId] = clone[gamerChildId].filter(
          (fse) =>
            !updatedFollowedSideEffects
              .map((fse) => fse.sideEffectId)
              .includes(fse.sideEffectId)
        );

        clone[gamerChildId].push(...updatedFollowedSideEffects);

        return clone;
      });

      const event: SideEffectsUpdated = {
        name: "Side Effects Updated",
        eventProperties: {
          "Kid ID": gamerChildId,
          "Side Effects": sideEffectIds.flatMap((fse) =>
            sideEffectById && sideEffectById[fse]
              ? [sideEffectById[fse].name.en]
              : []
          ),
        },
        setProperties: {
          "Side Effects Count": getAccountSideEffectsCount(),
        },
      };

      handleEvent(event);
    } finally {
      setLoading(false);
    }
  };

  const updatePrescription = async (
    gamerChildId: string,
    prescription: Prescription
  ): Promise<void> => {
    setLoading(true);
    try {
      const medicalChildId = getMedicalChildId(gamerChildId);

      await FirebaseAPI.Medical.Prescription.updatePrescription(
        medicalChildId,
        prescription
      );

      const oldPrescription = prescriptionsByGamerChildId[gamerChildId].find(
        (p) => p.prescriptionId === prescription.prescriptionId
      );

      setPrescriptionsByGamerChildId((current) => {
        const clone = { ...current };

        const i = clone[gamerChildId].findIndex(
          (p) => p.prescriptionId === prescription.prescriptionId
        );

        clone[gamerChildId][i] = prescription;

        return clone;
      });

      if (oldPrescription) {
        const event: PrescriptionUpdated = {
          name: "Prescription Updated",
          eventProperties: {
            "Kid ID": prescription.medicalChildId,
            "Medication Name":
              medicationById && medicationById[prescription.drug.drugId]
                ? medicationById[prescription.drug.drugId].name.en
                : "",
            "Date": new Date(prescription.startDate).toISOString(),
            "Hour": prescription.drug.doses[0]?.time,
            "Dosage": prescription.drug.doses[0]?.quantity,
            "Hour2":
              prescription.drug.doses.length > 1
                ? prescription.drug.doses[1]?.time
                : undefined,
            "Dosage2":
              prescription.drug.doses.length > 1
                ? prescription.drug.doses[1]?.quantity
                : undefined,
            "Hour3":
              prescription.drug.doses.length > 2
                ? prescription.drug.doses[2]?.time
                : undefined,
            "Dosage3":
              prescription.drug.doses.length > 2
                ? prescription.drug.doses[2]?.quantity
                : undefined,
            "Old Medication Name":
              medicationById && medicationById[oldPrescription.drug.drugId]
                ? medicationById[oldPrescription.drug.drugId].name.en
                : "",
            "Old Date": new Date(oldPrescription.startDate),
            "Old Hour": oldPrescription.drug.doses[0]?.time,
            "Old Dosage": oldPrescription.drug.doses[0]?.quantity,
            "Old Hour2":
              oldPrescription.drug.doses.length > 1
                ? oldPrescription.drug.doses[1]?.time
                : undefined,
            "Old Dosage2":
              oldPrescription.drug.doses.length > 1
                ? oldPrescription.drug.doses[1]?.quantity
                : undefined,
            "Old Hour3":
              oldPrescription.drug.doses.length > 2
                ? oldPrescription.drug.doses[2]?.time
                : undefined,
            "Old Dosage3":
              oldPrescription.drug.doses.length > 2
                ? oldPrescription.drug.doses[2]?.quantity
                : undefined,
          },
          setProperties: {
            "Prescriptions Count": getAccountPrescriptionsCount(),
          },
        };

        handleEvent(event);
      }
    } finally {
      setLoading(false);
    }
  };

  // #endregion

  return {
    selectors: {
      anyChildHasObservation,
      followedSideEffectsByGamerChildId,
      getActiveFollowedSideEffects,
      getActivePrescriptionsForSelectedChild,
      getLastWeeksPrescriptionsByChild,
      getRecordsForSideEffectAndTimePeriod,
      getObservationsToDo,
      prescriptionsByGamerChildId,
      recordsInitializationComplete,
      userHasPrescription,
      childrenWithFilledFollowUp,
    },
    actions: {
      createFollowUp,
      createPrescription,
      createRecords,
      terminatePrescription,
      updateFollowedSideEffects,
      updatePrescription,
    },
  };
};

export default useFollowUp;
