import { Loader } from "@neurosolutionsgroup/components";
import React, {
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useChildrenContext } from "./children/ChildrenContext";
import { useRoutineContext } from "./routines/RoutinesContext";
import { useProfileContext } from "./account/UserProfileContext";
import { useFollowUpContext } from "./FollowUp/FollowUpContext";
import { createPortal } from "react-dom";
import * as Sentry from "@sentry/react";
import useParameters from "./Parameters/useParameters";
import FullScreenLoader from "common/Components/Loader/FullScreenLoader";
import useAuth from "./auth/useAuth";
import { useSideEffectContext } from "./sideEffect/SideEffectContext";
import { useMedicationContext } from "./medications/MedicationContext";
import { useTasksContext } from "./routines/TasksContext";
import Tools from "@neurosolutionsgroup/tools";
import { useAppInitializationContext } from "./AppInitializationContext";
import { useSubscriptionContext } from "./subscription/SubscriptionContext";

const portalRoot = document.getElementById("loader");

export interface AppDataContextData {
  loading: boolean;
  setLoading: React.Dispatch<SetStateAction<boolean>>;
  backgroundLoading: boolean;
  setBackgroundLoading: React.Dispatch<SetStateAction<boolean>>;
  refreshAppData: VoidFunction;
}

const [useAppDataContext, AppDataContextProvider] =
  Tools.Context.createGenericContext<AppDataContextData>(__filename);

const AppDataProvider: React.FC<PropsWithChildren> = (props) => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [backgroundLoading, setBackgroundLoading] = useState(false);

  // Contexts required for initial loading.
  const { profileInitializationComplete, refreshUserProfile } =
    useProfileContext();
  const { routineInitializationComplete, resetRoutine, refreshRoutines } =
    useRoutineContext();
  const { childInitilizationComplete, resetChildren, refreshChildren } =
    useChildrenContext();
  const { followUpInitializationComplete, resetFollowUp } =
    useFollowUpContext();
  const { user } = useAuth();
  const { resetSideEffects } = useSideEffectContext();
  const { resetMedication } = useMedicationContext();
  const { resetTask } = useTasksContext();
  const { dataInitiated, setDataInitiated, userInitiated } =
    useAppInitializationContext();
  const { subscriptionStatus } = useSubscriptionContext();

  const { addLoadingProgress } = useParameters();

  const transaction = useMemo(() => {
    const transaction = Sentry.startTransaction({ name: "initial-load" });

    const spanRoutines = transaction.startChild({
      op: "routines",
      description: "get routines",
    });

    const spanChildren = transaction.startChild({
      op: "children",
      description: "get children",
    });

    const spanProfile = transaction.startChild({
      op: "profile",
      description: "get user profile",
    });

    const spanMedical = transaction.startChild({
      op: "medical",
      description: "get medical data",
    });

    return {
      transaction,
      spans: {
        spanRoutines,
        spanChildren,
        spanProfile,
        spanMedical,
      },
    };
  }, []);

  const resetAppData = () => {
    resetSideEffects();
    resetFollowUp();
    resetMedication();
    resetRoutine();
    resetTask();
    resetChildren();
  };

  useEffect(() => {
    if (
      routineInitializationComplete &&
      !transaction.spans.spanRoutines.endTimestamp
    ) {
      transaction.spans.spanRoutines.finish();
    }

    if (
      childInitilizationComplete &&
      !transaction.spans.spanChildren.endTimestamp
    ) {
      transaction.spans.spanChildren.finish();
    }

    if (
      profileInitializationComplete &&
      !transaction.spans.spanProfile.endTimestamp
    ) {
      transaction.spans.spanProfile.finish();
    }

    if (
      followUpInitializationComplete &&
      !transaction.spans.spanMedical.endTimestamp
    ) {
      transaction.spans.spanMedical.finish();
    }

    if (
      routineInitializationComplete &&
      childInitilizationComplete &&
      profileInitializationComplete &&
      followUpInitializationComplete
    ) {
      // Add complete loading progress in case one was missed somewhere.
      addLoadingProgress(100);
      transaction.transaction.finish();
      setDataInitiated(true);
    }
  }, [
    routineInitializationComplete,
    childInitilizationComplete,
    profileInitializationComplete,
    followUpInitializationComplete,
  ]);

  useEffect(() => {
    if (!user) {
      setDataInitiated(false);
      resetAppData();
    }
  }, [user]);

  const refreshAppData = async () => {
    setBackgroundLoading(true);

    await Promise.all([
      refreshUserProfile(),
      refreshRoutines(),
      refreshChildren(),
    ]);

    setBackgroundLoading(false);
  };

  const subscriptionInitiated = useMemo(
    () => subscriptionStatus !== null,
    [subscriptionStatus]
  );

  useEffect(() => {
    if (dataInitiated && userInitiated && subscriptionInitiated) {
      setTimeout(() => {
        setInitialLoading(false);
      }, 250);
    }
  }, [dataInitiated, userInitiated, subscriptionInitiated]);

  return (
    <AppDataContextProvider
      value={{
        loading,
        setLoading,
        backgroundLoading,
        setBackgroundLoading,
        refreshAppData,
      }}
    >
      {initialLoading ? <FullScreenLoader /> : null}
      {userInitiated && dataInitiated ? props.children : null}
      {loading && portalRoot ? createPortal(<Loader />, portalRoot) : null}
    </AppDataContextProvider>
  );
};

export { useAppDataContext, AppDataProvider };
