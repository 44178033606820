import React, { SetStateAction, version } from "react";
import { useTranslation } from "react-i18next";
import {
  useRemoteConfig,
  FeatureFlag,
} from "@neurosolutionsgroup/remote-config";
import { Filter } from "common/Components";

export const enum FilterValue {
  All,
  Observations,
  Routines,
  Challenges,
}

interface CategoryFilterProps {
  filter: FilterValue[];
  setFilter: React.Dispatch<SetStateAction<number[]>>;
  observationsToDo: number;
  validationsToDo: number;
  challengesToDo: number;
}

const CategoryFilter = ({
  filter,
  setFilter,
  observationsToDo,
  validationsToDo,
  challengesToDo,
}: CategoryFilterProps): JSX.Element => {
  const { t } = useTranslation();

  const { checkFeatureFlagVersion } = useRemoteConfig();

  return (
    <Filter
      options={[
        {
          id: FilterValue.All,
          label: t("general.all"),
          count: observationsToDo + validationsToDo,
        },
        {
          id: FilterValue.Observations,
          label: t("validation.observations.title"),
          count: observationsToDo,
        },
        {
          id: FilterValue.Routines,
          label: t("validation.routines.title"),
          count: validationsToDo,
        },
        ...(checkFeatureFlagVersion(FeatureFlag.Challenges, version)
          ? [
              {
                id: FilterValue.Challenges,
                label: t("validation.challenge.title"),
                count: challengesToDo,
              },
            ]
          : []),
      ]}
      onChange={setFilter}
      value={filter}
      single
    />
  );
};

export default CategoryFilter;
