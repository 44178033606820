import {
  ChildAccountCreated,
  DefaultChildEdited,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import { Loader } from "@neurosolutionsgroup/components";
import {
  Gender,
  ChildSecurityOption,
  orderedNeedKeys,
} from "@neurosolutionsgroup/models";
import Tools from "@neurosolutionsgroup/tools";
import {
  ChildCodeCreationPage,
  ChildCreationFormState,
  ChildCreationPage,
  ChildNeedsPage,
} from "@neurosolutionsgroup/webviews-pages";
import ChildIcons from "assets/child-icons";
import CoachAssets from "assets/coach";
import PinAssets from "assets/pin-icons";
import useChildren from "common/hooks/children/useChildren";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import { differenceInYears } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SAFE_AREAS } from "stylesheets";
import { v4 } from "uuid";

export interface ChildCreationProps {
  open: boolean;
  onClose: () => void;
  onBack?: () => void;
  placeholderName?: string;
  setSelectedChildOnComplete?: boolean;
  codeStyle?: 0 | 1 | 2 | null;
}

const ChildCreation: React.FC<ChildCreationProps> = ({
  open,
  placeholderName,
  onBack,
  onClose,
  setSelectedChildOnComplete,
  codeStyle = null,
}) => {
  const { handleEvent } = useAnalytics();
  const { handleUnknownError } = useErrorsContext();
  const { t } = useTranslation();

  const {
    actions: { setSelectedChild, updateChild },
    selectors: { childIds, selectedChild, childrenById },
  } = useChildren();

  const BLANK_FORM_STATE: ChildCreationFormState = {
    icon: null,
    name: "",
    gender: undefined,
    birthDateMS: null,
    childSecurity: codeStyle,
  };

  const [page, setPage] = useState<"child" | "security" | "needs">("child");
  const [formState, setFormState] =
    useState<ChildCreationFormState>(BLANK_FORM_STATE);
  const [code, setCode] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFormState((current) => {
      return { ...current, childSecurity: codeStyle };
    });
  }, [codeStyle]);

  const getGenderString = (gender: Gender | null): string | null => {
    switch (gender) {
      case null:
      case undefined:
        return null;
      case Gender.MALE:
        return t("settings.child.male", { lng: "en" });
      case Gender.FEMALE:
        return t("settings.child.female", { lng: "en" });
      case Gender.NON_BINARY:
        return t("settings.child.nonBinary", { lng: "en" });
      case Gender.OTHER:
        return t("settings.child.otherGender", { lng: "en" });
    }
  };

  const onChildFormConfirm = (formState: ChildCreationFormState) => {
    if (formState.childSecurity !== ChildSecurityOption.None) {
      setFormState(formState);
      setPage("security");
    } else {
      setFormState(formState);
      setPage("needs");
    }
  };

  const onSecurityConfirm = (newCode: string) => {
    setCode(newCode);
    setPage("needs");
  };

  const onNeedsConfirm = (needs: string[]) => {
    if (
      formState.icon !== null &&
      formState.name &&
      formState.gender !== undefined
    ) {
      saveChild(
        formState.icon,
        formState.name,
        code ?? null,
        formState.childSecurity === ChildSecurityOption.Icons,
        formState.gender ?? null,
        formState.birthDateMS ? Math.floor(formState.birthDateMS / 1000) : null,
        needs
      );
    }
  };

  const resetForm = () => {
    setFormState(BLANK_FORM_STATE);
    setPage("child");
  };

  const saveChild = async (
    icon: number,
    name: string,
    code: string | null,
    useImageNip: boolean,
    gender: Gender | null,
    birthDateSeconds: number | null,
    needs: string[]
  ) => {
    setLoading(true);

    let id = v4();

    let isDefaultEdition = false;

    if (selectedChild && childrenById[selectedChild]?.isDefault) {
      isDefaultEdition = true;
      id = selectedChild;
    }

    const numberOfExistingChildren = childIds.length;

    updateChild(
      {
        id,
        icon,
        name,
        creation: Tools.Time.Dates.getTimeStamp(),
        nip: code,
        useImageNip,
        gender,
        birthDate: birthDateSeconds,
        history: [],
        isDisabled: null,
        isDefault: null,
      },
      needs
    )
      .then(() => {
        if (isDefaultEdition) {
          const defaultChildEvent: DefaultChildEdited = {
            name: "Default Child Edited",
          };

          handleEvent(defaultChildEvent);
        }

        const event: ChildAccountCreated = {
          name: "Kid Account Created",
          eventProperties: {
            "Kid ID": id,
            "Gender": getGenderString(gender),
            "Icon Picked": icon.toFixed(),
            "Age": birthDateSeconds
              ? differenceInYears(new Date(), new Date(birthDateSeconds * 1000))
              : null,
            "DOB": birthDateSeconds
              ? new Date(birthDateSeconds * 1000).toISOString()
              : null,
          },
          setProperties: {
            "Nb Children": numberOfExistingChildren + 1,
          },
        };

        if (setSelectedChildOnComplete) {
          setSelectedChild(id);
        }

        handleEvent(event);
        resetForm();
      })
      .catch((err: unknown) => {
        handleUnknownError(err);
      })
      .finally(() => {
        setLoading(false);

        onClose();
      });
  };

  return (
    <>
      {open ? (
        page === "child" ? (
          <ChildCreationPage
            childIcons={ChildIcons}
            formState={formState}
            setFormState={setFormState}
            placeholderName={placeholderName}
            infoCoachImage={CoachAssets.CoachDynamic}
            onBack={
              onBack
                ? () => {
                    resetForm();
                    onBack();
                  }
                : undefined
            }
            onComplete={onChildFormConfirm}
            safeAreas={SAFE_AREAS}
            sx={{
              zIndex: 10000,
            }}
          />
        ) : page === "security" ? (
          <ChildCodeCreationPage
            childName={formState?.name ?? ""}
            iconCode={formState.childSecurity === ChildSecurityOption.Icons}
            onBack={() => setPage("child")}
            onConfirm={onSecurityConfirm}
            pinIcons={PinAssets.PinIcons}
            safeAreas={SAFE_AREAS}
            sx={{
              zIndex: 10000,
            }}
          />
        ) : (
          <ChildNeedsPage
            safeAreas={SAFE_AREAS}
            sx={{
              zIndex: 10000,
            }}
            onSubmit={onNeedsConfirm}
            orderedNeedKeys={orderedNeedKeys}
          />
        )
      ) : null}
      {loading ? <Loader /> : null}
    </>
  );
};

export default ChildCreation;
