import {
  backdropClasses,
  Box,
  Button,
  CircularProgress,
  Drawer,
  drawerClasses,
  Grid,
  Typography,
} from "@mui/material";
import Logos from "assets/logo";
import { SAFE_AREA_TOP, SAFE_AREAS } from "stylesheets";
import useAuth from "common/hooks/auth/useAuth";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import useLanguage from "common/hooks/Parameters/useLanguage";
import useParameters from "common/hooks/Parameters/useParameters";
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithEmailAndPassword,
  signInWithRedirect,
} from "firebase/auth";
import React, { MouseEventHandler, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import AppleButton from "../Components/AppleButton";
import AuthTextField from "../Components/AuthTextField";
import GoogleButton from "../Components/GoogleButton";
import Toast from "common/Components/Toast/Toast";
import { useAppInitializationContext } from "common/hooks/AppInitializationContext";
import Tools from "@neurosolutionsgroup/tools";
import { AgeGateV2, WebviewLink } from "@neurosolutionsgroup/components";
import {
  ExternalLink,
  UnityAuthAction,
  UnityAuthProvider,
  UniWebViewActions,
} from "common/hooks/Parameters/UniWebViewActions";
import ShowPasswordAdornment from "../Components/ShowPasswordAdornment";
import { CustomWindow } from "custom.window";
import {
  ConfigString,
  useRemoteConfig,
  WebviewsFeatureFlag,
} from "@neurosolutionsgroup/remote-config";
import { useAnalytics } from "@neurosolutionsgroup/analytics";

declare let window: CustomWindow;

const Login: React.FC = () => {
  const {
    functions: { pageView },
  } = useAnalytics();
  const { language } = useLanguage();
  const { t } = useTranslation();
  const { checkFeatureFlagVersion, getRemoteConfigValue } = useRemoteConfig();
  const { linkHandler, os, sendMessageToUnity, version } = useParameters();
  const { handleFirebaseError } = useErrorsContext();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { trackLogin, setupAuthFromCustomToken } = useAuth();
  const { userInitiated } = useAppInitializationContext();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayPassword, setDisplayPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openAgeGate, setOpenAgeGate] = useState(false);
  const [openForgottenPasswordAgeGate, setOpenForgottenPasswordAgeGate] =
    useState(false);
  const [openRedirectToast, setOpenRedirectToast] = useState(false);

  const passwordRef = useRef<HTMLInputElement>(null);

  const allowThirdPartyAuth: boolean =
    getRemoteConfigValue(ConfigString.ThirdPartyAuth).asString() === "true";

  window.nativeAuthResult = async (
    successful: boolean,
    customToken?: string,
    _isNewUser?: boolean,
    error?: string
  ) => {
    if (error && error === "noAccount") {
      setLoading(false);
      navigate("/signup");
      return;
    }
    if (!successful || !customToken) {
      setLoading(false);
      return;
    }

    await setupAuthFromCustomToken(customToken);
  };

  useEffect(() => {
    pageView("login");
  }, []);

  useEffect(() => {
    if (state && state.email) {
      setEmail(state.email);
      setOpenRedirectToast(true);
    }
  }, [state]);

  useEffect(() => {
    if (userInitiated) {
      navigate("/");
    }
  }, [userInitiated]);

  const onContinueGoogle = () => {
    setLoading(true);

    if (
      checkFeatureFlagVersion(WebviewsFeatureFlag.NativeThirdPartyAuth, version)
    ) {
      sendMessageToUnity(
        UniWebViewActions.NativeAuthenication,
        `provider=${UnityAuthProvider.Google}&language=${language}&flow=${UnityAuthAction.Login}`
      );
    } else {
      const provider = new GoogleAuthProvider();

      provider.addScope("https://www.googleapis.com/auth/userinfo.email");
      provider.addScope("https://www.googleapis.com/auth/userinfo.profile");

      const auth = getAuth();

      auth.languageCode = language;

      signInWithRedirect(auth, provider);
    }
  };

  const onContinueApple = () => {
    setLoading(true);

    if (
      os === "ios" &&
      checkFeatureFlagVersion(WebviewsFeatureFlag.NativeThirdPartyAuth, version)
    ) {
      sendMessageToUnity(
        UniWebViewActions.NativeAuthenication,
        `provider=${UnityAuthProvider.Apple}&language=${language}&flow=${UnityAuthAction.Login}`
      );
    } else {
      const provider = new OAuthProvider("apple.com");

      provider.addScope("email");
      provider.addScope("name");

      const auth = getAuth();

      provider.setCustomParameters({
        locale: language,
      });

      signInWithRedirect(auth, provider);
    }
  };

  const onSubmit: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();

    setLoading(true);

    const auth = getAuth();

    auth.languageCode = language;

    try {
      const userCred = await signInWithEmailAndPassword(auth, email, password);

      trackLogin("email", userCred.user.uid);
    } catch (err) {
      handleFirebaseError(err);
      setLoading(false);
    }
  };

  const onSignUpClick = () => {
    setOpenAgeGate(true);
  };

  const handleForgottenPasswordLinkClicked = () => {
    setOpenForgottenPasswordAgeGate(true);
  };

  const onForgottenPasswordAgeGateComplete = () => {
    setOpenForgottenPasswordAgeGate(false);
    linkHandler(
      ExternalLink.WebAppForgottenPassword,
      getRemoteConfigValue(ConfigString.ForgottenPasswordLink).asString()
    );
  };

  return (
    <Box>
      <Drawer
        open={true}
        anchor="bottom"
        sx={(theme) => ({
          [`& .${backdropClasses.root}`]: {
            backgroundColor: "transparent",
          },
          [`& .${drawerClasses.paper}`]: {
            overflow: "visible",
            backgroundColor: theme.palette.background.default,
            height: "100%",
          },
        })}
      >
        <form>
          <Grid
            container
            direction="column"
            spacing={2}
            p={1}
            px={2}
            mt={SAFE_AREA_TOP}
          >
            <Grid item display="flex" alignItems="start">
              <Box
                sx={{
                  marginX: "auto",
                  img: {
                    height: "100px",
                  },
                }}
              >
                <img
                  src={Tools.Language.languageSwitch(language, {
                    en: Logos.KairosIllustratedEN,
                    fr: Logos.KairosIllustratedFR,
                  })}
                  alt={Tools.Environment.getProductName()}
                />
              </Box>
            </Grid>
            <Grid item>
              <Typography variant="h4">{t("auth.login.title")}</Typography>
            </Grid>
            <Grid item>
              <AuthTextField
                id="email-input"
                data-cy="email-input"
                label={t("auth.inputs.email.label")}
                placeholder={t("auth.inputs.email.placeholder")}
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
                type="email"
                autoComplete="username"
                onReturnKey={() => {
                  passwordRef.current?.focus();
                  passwordRef.current?.click();
                }}
              />
            </Grid>
            <Grid item>
              <AuthTextField
                ref={passwordRef}
                id="password-input"
                data-cy="password-input"
                label={t("auth.inputs.password.label")}
                placeholder={t("auth.inputs.password.placeholder")}
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
                type={displayPassword ? "text" : "password"}
                autoComplete="current-password"
                endAdornment={
                  <ShowPasswordAdornment
                    active={displayPassword}
                    setActive={setDisplayPassword}
                  />
                }
              />
              <Typography fontSize={"0.8rem"} textAlign="right" mt={0.5}>
                <WebviewLink
                  onClick={() => {
                    handleForgottenPasswordLinkClicked();
                  }}
                >
                  {t("auth.login.forgottenPassword")}
                </WebviewLink>
              </Typography>
            </Grid>
            <Grid item>
              <Button
                data-cy="login-submit-button"
                onClick={onSubmit}
                color="secondary"
                variant="contained"
                fullWidth
                disabled={email.length === 0 || password.length === 0}
                type="submit"
              >
                {loading ? (
                  <CircularProgress
                    size="1.5rem"
                    style={{
                      color: "#fff",
                    }}
                  />
                ) : (
                  t("auth.login.action")
                )}
              </Button>
            </Grid>
            {allowThirdPartyAuth ? (
              <>
                <Grid item display="flex" justifyContent="center" my={-1}>
                  <div className="signup__divider">
                    <Typography
                      className="signup__divider--text"
                      fontWeight="bold"
                      fontSize="0.8rem"
                    >
                      {t("general.or")}
                    </Typography>
                  </div>
                </Grid>
                {os === "android" ? (
                  <>
                    <Grid item>
                      <GoogleButton
                        onClick={onContinueGoogle}
                        type="button"
                        authVariant="signin"
                        disabled={loading}
                      />
                    </Grid>
                    <Grid item>
                      <AppleButton
                        onClick={onContinueApple}
                        type="button"
                        authVariant="signin"
                        disabled={loading}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item>
                      <AppleButton
                        onClick={onContinueApple}
                        type="button"
                        authVariant="signin"
                        disabled={loading}
                      />
                    </Grid>
                    <Grid item>
                      <GoogleButton
                        onClick={onContinueGoogle}
                        type="button"
                        authVariant="signin"
                        disabled={loading}
                      />
                    </Grid>
                  </>
                )}
              </>
            ) : null}
            <Grid item>
              <Typography
                fontSize={"0.8rem"}
                textAlign="center"
                color="#secondary"
              >
                <WebviewLink
                  onClick={() => {
                    onSignUpClick();
                  }}
                >
                  {t("auth.login.signup")}
                </WebviewLink>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Drawer>
      <AgeGateV2
        show={openAgeGate}
        onClose={() => setOpenAgeGate(false)}
        onComplete={() => {
          navigate("/signup?skip-intro=true");
        }}
        safeAreas={SAFE_AREAS}
      />
      <AgeGateV2
        show={openForgottenPasswordAgeGate}
        onClose={() => setOpenForgottenPasswordAgeGate(false)}
        onComplete={() => {
          onForgottenPasswordAgeGateComplete();
        }}
        safeAreas={SAFE_AREAS}
      />
      <Toast
        open={openRedirectToast}
        onClose={() => setOpenRedirectToast(false)}
        timeout={6000}
        title={t("auth.redirect.title")}
        text={t("auth.redirect.text")}
        transparent
      />
    </Box>
  );
};

export default Login;
