import { DialogContent, DialogContentText } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const ChallengeSummaryError = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <DialogContent>
      <DialogContentText
        component="div"
        color="secondary"
        sx={{ wordWrap: "break-word" }}
      >
        {t("routine.challenge.summary.error")}
      </DialogContentText>
    </DialogContent>
  );
};

export default ChallengeSummaryError;
