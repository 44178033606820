import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Typography,
  dialogClasses,
  useTheme,
} from "@mui/material";
import { Icons } from "@neurosolutionsgroup/components";
import { Challenge, ChallengeHistory } from "@neurosolutionsgroup/models";
import Tools from "@neurosolutionsgroup/tools";
import useLanguage from "common/hooks/Parameters/useLanguage";
import { format } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";

interface ChallengeDetailsProps extends DialogProps {
  challenge: Challenge;
  onClose: VoidFunction;
}

const ChallengeDetails = ({
  challenge,
  ...props
}: ChallengeDetailsProps): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { language, dateLocale } = useLanguage();

  const renderHistoryDate = (history: ChallengeHistory): string => {
    let dateMS = undefined;

    if (history.childExecutionDate) {
      dateMS = history.childExecutionDate * 1000;
    } else if (history.validationDate) {
      dateMS = history.validationDate * 1000;
    }

    if (!dateMS) {
      return "";
    }

    return format(dateMS, "dd/MM/yyyy", { locale: dateLocale });
  };

  const renderHistoryStatus = (
    history: ChallengeHistory
  ): string | JSX.Element => {
    if (history.parentStatus === null) {
      return "";
    }

    return history.parentStatus ? (
      <Icons.CheckMarkIcon
        color={theme.palette.success.main}
        checkStyle="kairos"
      />
    ) : (
      <Icons.CloseIcon color={theme.palette.error.main} />
    );
  };

  return (
    <Dialog
      {...props}
      sx={{
        [`& .${dialogClasses.paper}`]: {
          minWidth: "80vw",
        },
      }}
      data-cy="challenge-details"
    >
      <DialogTitle
        sx={{ wordWrap: "break-word", maxWidth: "85%" }}
        data-cy="challenge-title"
      >
        {challenge.title}
      </DialogTitle>
      <DialogContent>
        {challenge.description ? (
          <DialogContentText
            color="secondary"
            component="div"
            data-cy="challenge-description"
          >
            <h4>{t("routine.challenge.inputs.description.label")}</h4>
            {challenge.description}
          </DialogContentText>
        ) : null}
        <DialogContentText
          color="secondary"
          component="div"
          data-cy="challenge-reward"
        >
          <h4>{t("routine.challenge.inputs.reward.label")}</h4>
          {challenge.reward.text}
        </DialogContentText>
        {challenge.days ? (
          <DialogContentText
            color="secondary"
            component="div"
            data-cy="challenge-days"
          >
            <h4>{t("routine.edit.inputs.days.labelSingle")}</h4>
            {Tools.Time.Days.dayToString(challenge.days, dateLocale)}
          </DialogContentText>
        ) : null}
        {challenge.startTime && challenge.endTime ? (
          <DialogContentText
            color="secondary"
            component="div"
            data-cy="challenge-time"
          >
            <h4>{t("routine.edit.inputs.time.label")}</h4>
            {Tools.Time.Strings.localizedTimePeriodFromSeconds(
              challenge.startTime,
              challenge.endTime,
              language
            )}
          </DialogContentText>
        ) : null}
        {challenge.endDate ? (
          <DialogContentText
            color="secondary"
            component="div"
            data-cy="challenge-endDate"
          >
            <h4>{t("routine.challenge.inputs.enddate.label")}</h4>
            {new Date(challenge.endDate * 1000).toLocaleDateString(language, {
              dateStyle: "long",
            })}
          </DialogContentText>
        ) : null}
        {challenge.history.filter((history) => history.parentStatus !== null)
          .length > 0 ? (
          <DialogContentText color="secondary" component="div">
            <h4>
              {t("routine.challenge.details.occurrences", {
                count: challenge.occurrences ?? 1,
              })}
            </h4>

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              {challenge.history
                .sort(
                  (a, b) =>
                    (a.childExecutionDate ?? a.validationDate ?? 0) -
                    (b.childExecutionDate ?? b.validationDate ?? 0)
                )
                .map((history, i) => (
                  <Box
                    key={i}
                    display="flex"
                    alignItems="center"
                    sx={{
                      svg: {
                        width: "1em",
                        height: "1em",
                        marginRight: 1,
                      },
                    }}
                  >
                    {renderHistoryStatus(history)}
                    <Typography>{renderHistoryDate(history)}</Typography>
                  </Box>
                ))}
            </Box>
          </DialogContentText>
        ) : null}
        {challenge.cancelled ? (
          <DialogContentText
            fontWeight="bold"
            color="secondary"
            data-cy="challenge-cancelled"
            mt={1}
          >
            {t("routine.challenge.cancelled")}
          </DialogContentText>
        ) : null}
      </DialogContent>
      <DialogActions data-cy="challenge-details-close-button">
        <Button onClick={() => props.onClose && props.onClose()}>
          {t("general.actions.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChallengeDetails;
