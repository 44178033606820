import { FirestoreChallenge } from "@neurosolutionsgroup/models";
import useChallenges from "common/hooks/challenges/useChallenges";
import React, { useMemo } from "react";
import ActiveChallenges from "./Challenge/ActiveChallenges";
import ChallengesForApproval from "./Challenge/ChallengesForApproval";
import ChallengeHistory from "./Challenge/ChallengeHistory";
import { Box } from "@mui/material";

export interface ChallengeSectionProps {
  onChallengeDelete: (c: FirestoreChallenge) => void;
  selectedChild: string | null;
}

const ChallengeSection: React.FC<ChallengeSectionProps> = ({
  onChallengeDelete,
  selectedChild,
}) => {
  const {
    selectors: {
      challengeFeatureFlagActive,
      challenges,
      challengesForApproval,
    },
  } = useChallenges();

  const filteredChallenges = useMemo(() => {
    if (selectedChild) {
      return challenges.filter((c) => c.childId === selectedChild);
    } else {
      return challenges;
    }
  }, [challenges, selectedChild]);

  const filteredChallengesForApproval = useMemo(() => {
    if (selectedChild) {
      return challengesForApproval.filter((c) => c.childId === selectedChild);
    } else {
      return challengesForApproval;
    }
  }, [challengesForApproval, selectedChild]);

  return (
    <Box pb="60px">
      {filteredChallengesForApproval.length > 0 ? (
        <ChallengesForApproval
          filteredChallengesForApproval={filteredChallengesForApproval}
          onChallengeDelete={onChallengeDelete}
        />
      ) : null}
      {challengeFeatureFlagActive && filteredChallenges.length > 0 ? (
        <ActiveChallenges
          activeChallenges={filteredChallenges}
          onChallengeDelete={onChallengeDelete}
        />
      ) : null}
      <ChallengeHistory selectedChild={selectedChild} />
    </Box>
  );
};
export default ChallengeSection;
