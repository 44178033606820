import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ChildIconPicker,
  CloseablePage,
  ConditionSelect,
  Select,
  StickyButtons,
  TaskButton,
  TextInput,
} from "common/Components";
import InformationButton from "common/Components/Buttons/InformationButton";
import { PageSection } from "common/Components/PageSection";
import { SelectOption } from "common/Components/Select/Select";
import { SettingsInputGroup } from "common/Settings";
import ChildPassword, { PasswordType } from "./ChildPassword/ChildPassword";
import {
  Condition,
  GamerChild,
  Gender,
  MedicationResponse,
} from "@neurosolutionsgroup/models";
import { Box, Button, ButtonBase, Grid, Typography } from "@mui/material";
import { DatePicker, Dialogs, Icons } from "@neurosolutionsgroup/components";
import useChildren from "common/hooks/children/useChildren";
import { v4 } from "uuid";
import Tools from "@neurosolutionsgroup/tools";
import InfoDrawer from "common/Components/Drawer/InfoDrawer";
import {
  ChildAccountDeleted,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import { sub } from "date-fns";
import { STICKY_BUTTONS_PAGE_PADDING } from "common/Components/Buttons/StickyButtons";
import isChildYoungerThanSix from "../isChildYoungerThanSix";
import ChildAgeWarning from "../ChildAgeWarning";
import GroupsEdition from "./GroupsEdition";
import DialogAssets from "assets/dialogs";
import EditNeeds from "./sections/EditNeeds";
import useLanguage from "common/hooks/Parameters/useLanguage";

interface EditChildProps {
  open: boolean;
  onClose: VoidFunction;
  canBeDeleted: boolean;
  child?: GamerChild | null;
}

export const EditChild: React.FC<EditChildProps> = ({
  open,
  onClose,
  canBeDeleted,
  child,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: "settings.child" });
  const { language } = useLanguage();
  const { t: generalT } = useTranslation();
  const { handleEvent } = useAnalytics();
  const { handleUnknownError } = useErrorsContext();

  const {
    selectors: { childIds, medicalChildrenByGamerId },
    actions: { childHasMedicalInfo, updateChild, deleteChild },
  } = useChildren();

  const [name, setName] = useState(child?.name ?? "");
  const [gender, setGender] = useState<Gender | null>(
    child?.gender ? (child.gender as Gender) : null
  );
  const [icon, setIcon] = useState<number | null>(child?.icon ?? null);
  const [birthDateMS, setBirthDateMS] = useState<number | null>(
    child?.birthDate ? child.birthDate * 1000 : null
  );
  const [showDobWarning, setShowDobWarning] = useState(false);
  const [needs, setNeeds] = useState<string[]>([]);
  const [conditions, setConditions] = useState<Condition[] | undefined>(
    undefined
  );
  const [medication, setMedication] = useState(MedicationResponse.UNKNOWN);
  const [passwordOpen, setPasswordOpen] = useState(false);
  const [passwordType, setPasswordType] = useState(
    child?.nip
      ? child?.isIcon
        ? PasswordType.ICONS
        : PasswordType.DIGITS
      : PasswordType.NONE
  );
  const [password, setPassword] = useState<string | null>(child?.nip ?? null);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openInfoDrawer, setOpenInfoDrawer] = useState(false);
  const [infoText, setInfoText] = useState("");

  useEffect(() => {
    setShowDobWarning(isChildYoungerThanSix(birthDateMS));
  }, [birthDateMS]);

  useEffect(() => {
    setName(child?.name ?? "");
    setIcon(child?.icon ?? null);
    setGender(child?.gender ?? null);
    setPassword(child?.nip ?? null);
    setPasswordType(
      child?.nip
        ? child?.useImageNip
          ? PasswordType.ICONS
          : PasswordType.DIGITS
        : PasswordType.NONE
    );
    setBirthDateMS(child?.birthDate ? child.birthDate * 1000 : null);
    if (child && childHasMedicalInfo(child.id)) {
      const medicalChild = medicalChildrenByGamerId[child.id];
      setConditions(medicalChild.diagnosis);
      setMedication(medicalChild.takeMedication ?? MedicationResponse.UNKNOWN);
      if (medicalChild.needs && medicalChild.needs.length > 0) {
        setNeeds(medicalChild.needs);
      } else {
        setNeeds([]);
      }
    } else {
      setConditions(undefined);
      setMedication(MedicationResponse.UNKNOWN);
    }
  }, [child, medicalChildrenByGamerId]);

  useEffect(() => {
    if (conditions === undefined || conditions.length === 0) {
      setMedication(MedicationResponse.UNKNOWN);
    }
  }, [conditions]);

  interface EnumSelectOption<TEnum extends number | string | null>
    extends SelectOption {
    value: TEnum;
  }

  type GenderOption = EnumSelectOption<Gender | null>;
  const genderOptions: GenderOption[] = [
    { value: Gender.MALE, label: t("male") },
    { value: Gender.FEMALE, label: t("female") },
    { value: Gender.NON_BINARY, label: t("nonBinary") },
    { value: Gender.OTHER, label: t("otherGender") },
    { value: null, label: t("noAnswer") },
  ];

  type MedicationOption = EnumSelectOption<MedicationResponse>;
  const medicationOptions: MedicationOption[] = [
    { value: MedicationResponse.YES, label: t("yesMed") },
    { value: MedicationResponse.NO, label: t("noMed") },
    { value: MedicationResponse.UNKNOWN, label: t("noAnswer") },
  ];

  const goToPassword = () => setPasswordOpen(true);
  const onPasswordClose = () => setPasswordOpen(false);
  const onPasswordChange = (type: PasswordType, password: string | null) => {
    setPasswordType(type);
    setPassword(password);
  };

  const onChildDeleteClick = () => {
    setOpenDeleteConfirmation(true);
  };

  const onChildDeleteConfirmed = () => {
    if (child) {
      setOpenDeleteConfirmation(false);
      deleteChild(child.id).then(() => {
        const event: ChildAccountDeleted = {
          name: "Kid Account Deleted",
          eventProperties: {
            "Kid ID": child.id,
          },
          setProperties: {
            "Nb Children": childIds.length - 1,
          },
        };

        handleEvent(event);

        onClose();
      });
    }
  };

  const withInfoButton = (title: string, text: string) => (
    <h2 className="page-section__title">
      {title}
      <InformationButton
        sx={{ marginLeft: "0.5em" }}
        onClick={() => {
          setInfoText(text);
          setOpenInfoDrawer(true);
        }}
      />
    </h2>
  );

  const header = (
    <>
      <ButtonBase
        onClick={onClose}
        sx={{
          "marginBottom": "0.5em",
          "& svg": {
            width: "1.5em",
            height: "1.5em",
          },
        }}
      >
        <Icons.CloseIcon color={"#fff"} />
      </ButtonBase>
      <h2>{t("editTitle", { childName: name })}</h2>
    </>
  );

  const onConfirm = () => {
    if (icon !== null && name) {
      updateChild(
        {
          id: child?.id ?? v4(),
          icon: icon,
          name: name,
          creation: child?.creation ?? Tools.Time.Dates.getTimeStamp(),
          nip: password,
          useImageNip: passwordType === PasswordType.ICONS,
          gender: gender,
          birthDate: birthDateMS ? Math.floor(birthDateMS / 1000) : null,
          history: [],
          isDisabled: null,
        },
        needs,
        conditions,
        medication
      )
        .then(() => onClose())
        .catch((err: unknown) => {
          handleUnknownError(err);
        });
    }
  };

  return (
    <CloseablePage
      isOpen={open}
      onClose={onClose}
      color="secondary"
      header={header}
      overrideNavHeader
    >
      <Box
        sx={{
          paddingBottom: STICKY_BUTTONS_PAGE_PADDING,
        }}
      >
        {/* profile: name, gender, icon, bd  */}
        <PageSection title={t("profile")}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography>
                <label htmlFor="child-name">{t("name") + "*"}</label>
              </Typography>
              <TextInput
                id="child-name"
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <SettingsInputGroup labelText={t("gender") + "*"}>
                <Select
                  label={t("gender")}
                  value={gender}
                  onChange={(option) => setGender(option.value)}
                  options={genderOptions}
                  placeholder={t("noAnswer")}
                />
              </SettingsInputGroup>
            </Grid>
            <Grid item xs={12}>
              <SettingsInputGroup labelText={t("icon") + "*"}>
                <ChildIconPicker icon={icon} setIcon={setIcon} />
              </SettingsInputGroup>
            </Grid>
            <Grid item xs={12}>
              <SettingsInputGroup
                labelText={
                  t("birthDate") + " (" + generalT("general.optional") + ")"
                }
              >
                <DatePicker
                  label={t("birthDate")}
                  date={birthDateMS ? new Date(birthDateMS) : null}
                  onChange={(date) =>
                    setBirthDateMS(date ? date.valueOf() : null)
                  }
                  language={language}
                  minDate={sub(new Date(), { years: 22 })}
                  maxDate={new Date()}
                  fullWidth
                />
              </SettingsInputGroup>
              <ChildAgeWarning show={showDobWarning} sx={{ marginTop: 1 }} />
            </Grid>
          </Grid>
        </PageSection>
        {/* Needs section */}
        <EditNeeds needs={needs} onNeedsChange={setNeeds} />
        {/* medical conditions */}
        <PageSection
          title={withInfoButton(t("conditions"), t("conditionsInfo"))}
        >
          <Typography>
            <label htmlFor="child-condition">{t("conditionsQuestion")}</label>
          </Typography>
          <Box mt={1}>
            <ConditionSelect conditions={conditions} onChange={setConditions} />
          </Box>
        </PageSection>
        {conditions && conditions.length > 0 && (
          // medication
          <PageSection title={withInfoButton(t("meds"), t("medsInfo"))}>
            <Typography>
              <label htmlFor="child-medication">{t("medsQuestion")}</label>
            </Typography>
            <Box mt={1}>
              <Select
                label={t("medsQuestion")}
                value={medication}
                onChange={(option) => setMedication(option.value)}
                options={medicationOptions}
              />
            </Box>
          </PageSection>
        )}
        <InfoDrawer
          open={openInfoDrawer}
          onClose={() => setOpenInfoDrawer(false)}
          text={infoText}
        />
        <PageSection title={t("security")}>
          <TaskButton onClick={goToPassword}>{t("password")}</TaskButton>
        </PageSection>
        {canBeDeleted ? (
          <PageSection title={t("childData")}>
            <SettingsInputGroup labelText={t("manageData")}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => onChildDeleteClick()}
                data-cy="delete-child-data"
              >
                {t("deleteData")}
              </Button>
            </SettingsInputGroup>
            <Dialogs.ConfirmationDialog
              open={openDeleteConfirmation}
              onClose={() => setOpenDeleteConfirmation(false)}
              title={t("delete.title")}
              text={t("delete.text")}
              onPositiveAction={onChildDeleteConfirmed}
              onNegativeAction={() => setOpenDeleteConfirmation(false)}
              imgSrc={DialogAssets.DeleteTag}
              data-cy="child-delete-confirm"
            />
          </PageSection>
        ) : null}

        {child?.id ? <GroupsEdition childId={child.id} /> : null}
      </Box>
      <ChildPassword
        name={name}
        isOpen={passwordOpen}
        onClose={onPasswordClose}
        passwordType={passwordType}
        password={password}
        onChange={onPasswordChange}
      />
      <StickyButtons
        onConfirm={onConfirm}
        disabled={!(icon !== null && name)}
      />
    </CloseablePage>
  );
};
