import { Box, ButtonBase, SxProps, Theme, useTheme } from "@mui/material";
import { MouseEventHandler, PropsWithChildren } from "react";
import { PremiumLock } from "../Premium";
import { CypressProps } from "@neurosolutionsgroup/models";

interface DrawerMenuSectionProps extends PropsWithChildren, CypressProps {
  variant?: "header" | "standard" | "blank";
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  premiumLock?: boolean;
  onPremiumLockClick?: VoidFunction;
  hidePremiumTag?: boolean;
  sx?: SxProps<Theme>;
}

const DrawerMenuSection = ({
  variant = "standard",
  onClick,
  className,
  onPremiumLockClick,
  premiumLock = false,
  hidePremiumTag = false,
  sx,
  ...props
}: DrawerMenuSectionProps): JSX.Element => {
  const { palette } = useTheme();

  const standardStyle: SxProps = {
    height: "12.5vh",
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderTopColor: palette.secondary.main,
  };

  const blankStyle: SxProps = {
    height: "12.5vh",
    borderBottom: "none",
  };

  const headerStyle: SxProps = {
    height: "calc(12.5vh - 12px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const style: SxProps =
    variant === "header"
      ? headerStyle
      : variant === "blank"
      ? blankStyle
      : standardStyle;

  return (
    <PremiumLock
      onClick={() => onPremiumLockClick && onPremiumLockClick()}
      active={premiumLock}
      variant={hidePremiumTag ? "none" : "long"}
    >
      {onClick ? (
        <ButtonBase
          onClick={onClick}
          sx={{
            width: "100%",
            height: "100%",
            flexShrink: 0,
            flexGrow: 0,
            ...style,
            ...sx,
          }}
          className={className}
          data-cy={props["data-cy"]}
        >
          {props.children}
        </ButtonBase>
      ) : (
        <Box
          sx={{
            flexShrink: 0,
            flexGrow: 0,
            ...style,
            ...sx,
          }}
          className={className}
          data-cy={props["data-cy"]}
        >
          {props.children}
        </Box>
      )}
    </PremiumLock>
  );
};

export default DrawerMenuSection;
