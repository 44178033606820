import { Typography, Button, Box, useTheme } from "@mui/material";
import { Icons } from "@neurosolutionsgroup/components";
import { needsLoc } from "@neurosolutionsgroup/localization";
import { ChildNeedsPage } from "@neurosolutionsgroup/webviews-pages";
import { PageSection } from "common/Components/PageSection";
import useLanguage from "common/hooks/Parameters/useLanguage";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SAFE_AREAS } from "stylesheets";

export interface EditNeedsProps {
  needs: string[];
  onNeedsChange: (needs: string[]) => void;
  childName: string;
}

const EditNeeds: React.FC<EditNeedsProps> = ({
  needs,
  onNeedsChange,
  childName,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "settings.child.needs",
  });
  const { language } = useLanguage();

  const { palette } = useTheme();

  const [needsEditionOpen, setNeedsEditionOpen] = useState<boolean>(false);

  const orderedNeedKeys = useMemo((): string[] => {
    return Object.entries(needsLoc[language].needs)
      .sort((a, b) => a[1].localeCompare(b[1]))
      .map((need) => need[0])
      .filter((n) => {
        return n !== "other";
      });
  }, [language]);

  return (
    <PageSection title={t("editionPage.title")}>
      <Button
        onClick={() => {
          console.log(needsEditionOpen);
          setNeedsEditionOpen(true);
        }}
        fullWidth
        sx={{ marginTop: 1 }}
      >
        <Typography>{t("editionPage.edition")}</Typography>
        <Box ml="auto">
          {
            <Icons.ArrowIcon
              arrowDirection="right"
              color={palette.secondary.main}
            />
          }
        </Box>
      </Button>
      {needs.length > 0 ? (
        <ul>
          {needs.map((n) => {
            return (
              <li key={n}>
                <Typography>{t("needs." + n)}</Typography>
              </li>
            );
          })}
        </ul>
      ) : (
        <Typography>{t("editionPage.noNeeds")}</Typography>
      )}
      {needsEditionOpen && (
        <ChildNeedsPage
          safeAreas={SAFE_AREAS}
          sx={{
            zIndex: 10000,
          }}
          onSubmit={(needs) => {
            onNeedsChange(needs);
            setNeedsEditionOpen(false);
          }}
          orderedNeedKeys={orderedNeedKeys}
          preselectedNeeds={needs}
          childName={childName}
        />
      )}
    </PageSection>
  );
};
export default EditNeeds;
