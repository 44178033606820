import { Box, Button, Typography } from "@mui/material";
import { Challenge, FirestoreCollection } from "@neurosolutionsgroup/models";
import useAuth from "common/hooks/auth/useAuth";
import {
  QueryDocumentSnapshot,
  QueryFieldFilterConstraint,
  and,
  collection,
  getDocs,
  getFirestore,
  limit,
  or,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ChallengeCard from "./ChallengeCard";
import useChallenges from "common/hooks/challenges/useChallenges";

interface ChallengeHistoryProps {
  selectedChild: string | null;
}

const ChallengeHistory = ({
  selectedChild,
}: ChallengeHistoryProps): JSX.Element | null => {
  const { t } = useTranslation();
  const { user, tenantId } = useAuth();
  const {
    selectors: { deactivatedChallenges },
  } = useChallenges();

  const [history, setHistory] = useState<Challenge[]>([]);
  const [lastHistoryRef, setLastHistoryRef] = useState<
    QueryDocumentSnapshot | undefined
  >();
  const [hasNextPage, setHasNextPage] = useState(false);

  const getHistory = async (
    childId: string | null,
    cursor?: QueryDocumentSnapshot,
    refresh?: boolean,
    pageLength = 10
  ) => {
    if (!user) {
      return;
    }

    const filters: QueryFieldFilterConstraint[] = [
      where("deleted", "==", false),
      where("userId", "==", user.uid),
      where("tenantId", "==", tenantId),
    ];

    if (childId) {
      filters.push(where("childId", "==", childId));
    }

    const db = getFirestore();

    const q = query(
      collection(db, FirestoreCollection.Challenges),
      and(
        ...filters,
        or(where("successful", "==", true), where("successful", "==", false))
      ),
      orderBy("createdDate", "desc"),
      ...(cursor ? [startAfter(cursor)] : []),
      // Load one extra to know if another page.
      limit(pageLength + 1)
    );

    const queryResult = await getDocs(q);

    if (queryResult.size === pageLength + 1) {
      const docs = queryResult.docs
        .slice(0, -1)
        .map((doc) => ({ ...doc.data(), id: doc.id } as Challenge));

      setHistory((current) =>
        cursor && !refresh ? [...current, ...docs] : docs
      );

      const ref = queryResult.docs[queryResult.docs.length - 2];

      setLastHistoryRef(ref);

      setHasNextPage(true);
    } else {
      const docs = queryResult.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id } as Challenge)
      );

      setHistory((current) =>
        cursor && !refresh ? [...current, ...docs] : docs
      );

      const ref = queryResult.docs[queryResult.docs.length - 1];

      setLastHistoryRef(ref);

      setHasNextPage(false);
    }
  };

  useEffect(() => {
    getHistory(selectedChild);
  }, [selectedChild]);

  useEffect(() => {
    if (deactivatedChallenges.length > 0) {
      getHistory(selectedChild, undefined, true);
    }
  }, [deactivatedChallenges]);

  const onLoadMore = () => {
    getHistory(selectedChild, lastHistoryRef);
  };

  return history.length > 0 ? (
    <Box data-cy="challenge-history-container">
      <Typography variant="h2" my={1}>
        {t("routine.challenge.history.title")}
      </Typography>
      <Box>
        {history.map((challenge) => (
          <Box key={challenge.id} pb={2}>
            <ChallengeCard challenge={challenge} />
          </Box>
        ))}
      </Box>

      {hasNextPage ? (
        <Button onClick={onLoadMore}>
          {t("routine.challenge.history.loadMore")}
        </Button>
      ) : null}
    </Box>
  ) : null;
};

export default ChallengeHistory;
