import React from "react";
import {
  Accordion,
  accordionClasses,
  AccordionDetails,
  AccordionSummary,
  useTheme,
} from "@mui/material";
import { Icons } from "@neurosolutionsgroup/components";
import { CypressProps } from "@neurosolutionsgroup/models";

export interface ExtendableCardProps extends CypressProps {
  summaryComponent: JSX.Element;
  detailsComponent: JSX.Element;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExtendableCard: React.FC<ExtendableCardProps> = ({
  summaryComponent,
  detailsComponent,
  open,
  setOpen,
  ...props
}) => {
  const { palette } = useTheme();

  return (
    <Accordion
      sx={{
        [`&.${accordionClasses.root}`]: {
          borderRadius: "15px",
        },
      }}
      expanded={open}
      data-cy={props["data-cy"]}
    >
      <AccordionSummary
        sx={(theme) => ({
          "&.Mui-expanded": {
            borderRadius: "15px",
            boxShadow: "0px 4px 13px 3px rgba(0, 0, 0, 0.15)",
          },
          "& .icon-arrow": {
            path: {
              fill: theme.palette.secondary.main,
            },
          },
        })}
        expandIcon={
          <Icons.ArrowIcon
            arrowDirection="down"
            color={palette.secondary.main}
          />
        }
        onClick={() => setOpen((current) => !current)}
      >
        {summaryComponent}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          paddingTop: "2em",
        }}
      >
        {detailsComponent}
      </AccordionDetails>
    </Accordion>
  );
};
export default ExtendableCard;
