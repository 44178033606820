import { Box, Typography } from "@mui/material";
import { BackgroundHexagons, Loader } from "@neurosolutionsgroup/components";
import Tools from "@neurosolutionsgroup/tools";
import React, {
  PropsWithChildren,
  SetStateAction,
  useMemo,
  useState,
} from "react";

export interface AppInitializationContextData {
  authNoUserConfirmed: boolean;
  setAuthNoUserConfirmed: React.Dispatch<SetStateAction<boolean>>;
  userInitiated: boolean;
  setUserInitiated: React.Dispatch<SetStateAction<boolean>>;
  dataInitiated: boolean;
  setDataInitiated: React.Dispatch<SetStateAction<boolean>>;
}

const [useAppInitializationContext, AppInitializationContextProvider] =
  Tools.Context.createGenericContext<AppInitializationContextData>(__filename);

const AppInitializationProvider = (props: PropsWithChildren): JSX.Element => {
  const [authNoUserConfirmed, setAuthNoUserConfirmed] = useState(false);
  const [userInitiated, setUserInitiated] = useState(false);
  const [dataInitiated, setDataInitiated] = useState(false);

  const debug = false;

  const initialLoadingComplete = useMemo(() => {
    if (authNoUserConfirmed) {
      return true;
    } else {
      return userInitiated && dataInitiated;
    }
  }, [authNoUserConfirmed, userInitiated, dataInitiated]);

  return (
    <AppInitializationContextProvider
      value={{
        authNoUserConfirmed,
        setAuthNoUserConfirmed,
        userInitiated,
        setUserInitiated,
        dataInitiated,
        setDataInitiated,
      }}
    >
      {props.children}
      {debug ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 9999999,
          }}
        >
          <Typography color="#000" fontSize="10px">
            {JSON.stringify(
              {
                authNoUserConfirmed,
                userInitiated,
                dataInitiated,
              },
              null,
              2
            )}
          </Typography>
        </Box>
      ) : null}
      {!initialLoadingComplete ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 999999,
            backgroundColor: "primary.dark",
          }}
        >
          <BackgroundHexagons />
          <Loader dark />
        </Box>
      ) : null}
    </AppInitializationContextProvider>
  );
};

export { useAppInitializationContext, AppInitializationProvider };
