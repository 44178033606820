import { Box, Card, Divider, Typography, useTheme } from "@mui/material";
import {
  DashboardMedicalTimePeriodSelected,
  PremiumFeature,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import {
  IconButton,
  Icons,
  PremiumLock,
} from "@neurosolutionsgroup/components";
import {
  SideEffectChoice,
  SimplifiedRecordWithQuestion,
  isApiError,
  medicalPeriodWeeks,
} from "@neurosolutionsgroup/models";
import InformationButton from "common/Components/Buttons/InformationButton";
import InfoDrawer from "common/Components/Drawer/InfoDrawer";
import { FTUEFlowDefinitions } from "@neurosolutionsgroup/webviews-ftue";
import useChildren from "common/hooks/children/useChildren";
import useFollowUp from "common/hooks/FollowUp/useFollowUp";
import useSideEffect from "common/hooks/sideEffect/useSideEffect";
import useLanguage from "common/hooks/Parameters/useLanguage";
import {
  FREQUENCY_QUESTION_ID,
  INTENSITY_QUESTION_ID,
} from "common/Models/Medical";
import { endOfWeek, isBefore, startOfWeek, sub } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PeriodPicker, {
  MedPeriod,
  PeriodOption,
  medPeriodAbbreviations,
} from "../PeriodPicker";
import Legend from "./Legend";
import ExportDrawer from "./ExportDrawer";
import usePDFExport from "../usePDFExport";
import useParameters from "common/hooks/Parameters/useParameters";
import GraphContainer from "../GraphContainer";
import YAxis from "../YAxis";
import Toast from "common/Components/Toast/Toast";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import {
  FrequencyGraph,
  GraphModels,
  GraphTools,
  IntensityGraph,
} from "@neurosolutionsgroup/graphs";
import { ResponsiveContainer } from "recharts";
import Tools from "@neurosolutionsgroup/tools";
import {
  useRemoteConfig,
  WebviewsFeatureFlag,
} from "@neurosolutionsgroup/remote-config";
import SymptomPicker from "./SymptomPicker";
import useSubscription from "common/hooks/subscription/useSubscription";
import { freeMedicalPeriods } from "common/hooks/subscription/SubscriptionContext";

const periodTicks = {
  [MedPeriod.LastTwoWeeks]: Array.from({ length: 2 }, (_, i) => i + 1),
  [MedPeriod.ThisMonth]: Array.from({ length: 4 }, (_, i) => i + 1),
  [MedPeriod.LastTwoMonths]: Array.from({ length: 8 }, (_, i) => i + 1),
  [MedPeriod.LastThreeMonths]: Array.from({ length: 12 }, (_, i) => i + 1),
  [MedPeriod.LastSixMonths]: Array.from({ length: 24 }, (_, i) => i + 1),
  [MedPeriod.LastNineMonths]: Array.from({ length: 36 }, (_, i) => i + 1),
  [MedPeriod.LastYear]: Array.from({ length: 52 }, (_, i) => i + 1),
};

const MedicationGraphCard: React.FC = () => {
  const { t } = useTranslation();
  const { language, dateLocale } = useLanguage();
  const { handleEvent } = useAnalytics();
  const { palette } = useTheme();
  const { version } = useParameters();
  const { handleUnknownError } = useErrorsContext();
  const { checkFeatureFlagVersion } = useRemoteConfig();

  const {
    selectors: { selectedChild, childrenById },
  } = useChildren();

  const {
    selectors: { getRecordsForSideEffectAndTimePeriod },
  } = useFollowUp();

  const {
    selectors: { sideEffectById },
  } = useSideEffect();

  const { onPremiumFeatureClick, permissions } = useSubscription();

  const { onExportMedical } = usePDFExport();

  const [period, setPeriod] = useState<MedPeriod>(MedPeriod.LastTwoWeeks);
  const [sideEffect, setSideEffect] = useState<string | null>(null);
  const [openInfoDrawer, setOpenInfoDrawer] = useState(false);
  const [infoDrawerType, setInfoDrawerType] = useState<
    "intensity" | "frequency"
  >("intensity");
  const [openExportDrawer, setOpenExportDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noDataError, setNoDataError] = useState(false);

  const [graphData, setGraphData] = useState<GraphData>({
    intensityData: [],
    frequencyData: [],
    prescriptions: [],
  });

  const canExportData = useMemo(() => {
    if (graphData.prescriptions.length === 0) {
      return false;
    }

    if (
      graphData.frequencyData.some((datum) => Object.keys(datum).length > 1)
    ) {
      return true;
    }

    if (
      graphData.intensityData.some((datum) => Object.keys(datum).length > 1)
    ) {
      return true;
    }

    return false;
  }, [graphData]);

  const periodDates: { start: Date; end: Date } = useMemo(() => {
    return {
      start: sub(startOfWeek(new Date(), { weekStartsOn: 1 }), {
        weeks: medicalPeriodWeeks[period],
      }),
      end: sub(endOfWeek(new Date(), { weekStartsOn: 1 }), { weeks: 1 }),
    };
  }, [period]);

  const periodText: string = useMemo(
    () =>
      Tools.Time.Strings.localizedPeriodFromDates(
        periodDates.start,
        periodDates.end,
        dateLocale
      ),
    [periodDates, dateLocale]
  );

  const isPeriodDisabled = (k: number): boolean => {
    if (k === 0 || !selectedChild) {
      return false;
    }

    const childCreationMS = childrenById[selectedChild].creation * 1000;

    // Check the smaller period because if creation before smaller period, larger period must be available.
    const startOfSmallerPeriod = sub(startOfWeek(new Date()), {
      weeks: medicalPeriodWeeks[(k - 1) as 0 | 1 | 2 | 3 | 4 | 5],
    });

    return !isBefore(childCreationMS, startOfSmallerPeriod);
  };

  const getPeriodOptions = useCallback((): PeriodOption[] => {
    return Object.values(MedPeriod)
      .filter((k) => typeof k === "number")
      .map((k) => ({
        value: k as number,
        label: t("dashboard.period.med." + k.toString()),
        disabled: isPeriodDisabled(k as number),
        display: medPeriodAbbreviations[k as number][language],
        premiumLock:
          !permissions.allMedicalPeriods &&
          !freeMedicalPeriods.some((period) => period === k),
      }));
  }, [t, selectedChild, permissions]);

  const onPeriodChange = (period: number) => {
    const event: DashboardMedicalTimePeriodSelected = {
      name: "Dashboard Medical Time Period Selected",
      eventProperties: {
        "Selected Time Period": t("dashboard.period.med." + period.toString(), {
          lng: "en",
        }),
      },
    };

    handleEvent(event);

    setPeriod(period);
  };

  interface GraphData {
    intensityData: GraphModels.Datum[];
    frequencyData: GraphModels.Datum[];
    prescriptions: string[];
  }

  useEffect(() => {
    const getData = async () => {
      if (selectedChild && sideEffect) {
        const sideEffectDef = sideEffectById
          ? sideEffectById[sideEffect]
          : null;

        const weeks = medicalPeriodWeeks[period];

        if (sideEffectDef) {
          let records: SimplifiedRecordWithQuestion[] = [];
          let prescriptions: string[] = [];

          const result = await getRecordsForSideEffectAndTimePeriod(
            sideEffect,
            weeks,
            selectedChild
          );

          records = result.records;
          prescriptions = result.prescriptions;

          const intensityData = GraphTools.formatMedicalGraphData(
            weeks,
            records,
            INTENSITY_QUESTION_ID
          );

          const frequencyData = GraphTools.formatMedicalGraphData(
            weeks,
            records,
            FREQUENCY_QUESTION_ID
          );

          setGraphData({
            intensityData: intensityData.data,
            frequencyData: frequencyData.data,
            prescriptions,
          });

          return;
        }
      }

      setGraphData({
        intensityData: Array.from(Array(medicalPeriodWeeks[period]).keys()).map(
          (k) => ({ valueX: k + 1 })
        ),
        frequencyData: Array.from(Array(medicalPeriodWeeks[period]).keys()).map(
          (k) => ({ valueX: k + 1 })
        ),
        prescriptions: [],
      });
    };

    getData();
  }, [period, sideEffect, selectedChild]);

  const frequencyDaysCount: string = useMemo(() => {
    const { count, total } = GraphTools.getFrequencyCount(
      graphData.frequencyData,
      graphData.prescriptions,
      periodDates.start,
      periodDates.end
    );

    return `${count}/${total} ${t("general.day", {
      count: total,
    })}`;
  }, [graphData, periodDates, t]);

  const exportPDF = async (value: SideEffectChoice) => {
    if (selectedChild) {
      setLoading(true);

      try {
        await onExportMedical(
          selectedChild,
          period,
          value,
          language,
          value === "current" ? sideEffect ?? undefined : undefined,
          Tools.Environment.isDevBuild()
        );
      } catch (err) {
        if (isApiError(err) && err.code === 404) {
          setNoDataError(true);
        } else {
          handleUnknownError(err);
        }

        setOpenExportDrawer(false);
      } finally {
        setOpenExportDrawer(false);
        setLoading(false);
      }
    }
  };

  return (
    <Card
      sx={{
        padding: 0,
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={2}
        pt={2}
        pb={1}
      >
        <Typography variant="h4">{t("dashboard.med.graph.title")}</Typography>
        {version &&
        checkFeatureFlagVersion(
          WebviewsFeatureFlag.DashboardExport,
          version
        ) ? (
          <PremiumLock
            active={!permissions.export}
            variant="icon-button"
            onClick={() => onPremiumFeatureClick(PremiumFeature.ExportMedical)}
          >
            <IconButton
              onClick={() => setOpenExportDrawer(true)}
              disabled={!canExportData}
            >
              <Icons.ExportIcon color={palette.secondary.main} />
            </IconButton>
          </PremiumLock>
        ) : null}
      </Box>
      <FTUEFlowDefinitions.DashboardPrescriptionsFTUEFlow.Hints.DashboardPrescriptionsSideEffectsTimePeriod>
        <Box px={1} py={1}>
          <SymptomPicker
            sideEffect={sideEffect}
            setSideEffect={setSideEffect}
            selectedChild={selectedChild}
          />
        </Box>
        <Divider
          sx={(theme) => ({
            borderColor: theme.palette.secondary.main,
          })}
        />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          pt={2}
          pb={1}
        >
          <Typography variant="h5">{t("dashboard.period.label")}</Typography>
          <Box>
            <PeriodPicker
              options={getPeriodOptions()}
              value={period}
              onChange={onPeriodChange}
              hasPremiumLock={!permissions.allMedicalPeriods}
              onPremiumOptionClick={(option) =>
                onPremiumFeatureClick(PremiumFeature.GraphPeriodMedical, {
                  period: t("dashboard.period.med." + option.value.toString(), {
                    lng: "en",
                  }),
                })
              }
            />
          </Box>
        </Box>
      </FTUEFlowDefinitions.DashboardPrescriptionsFTUEFlow.Hints.DashboardPrescriptionsSideEffectsTimePeriod>
      <FTUEFlowDefinitions.DashboardPrescriptionsFTUEFlow.Hints.DashboardPrescriptionsIntensityGraph>
        <Box display="flex" alignItems="center" px={2} py={1}>
          <Typography variant="h5" mr={1}>
            {t("dashboard.med.graph.intensity.title")}
          </Typography>
          <InformationButton
            onClick={() => {
              setInfoDrawerType("intensity");
              setOpenInfoDrawer(true);
            }}
            iconVariant="outline"
          />
          <Typography ml="auto" fontSize="0.9rem">
            {periodText}
          </Typography>
        </Box>
        <Box display="flex" ml={1} position="relative">
          <GraphContainer dataLength={graphData.intensityData.length}>
            {selectedChild ? (
              <ResponsiveContainer height="100%" width="100%">
                <IntensityGraph
                  data={graphData.intensityData}
                  prescriptions={graphData.prescriptions}
                  ticks={periodTicks[period]}
                  start={periodDates.start}
                  tickColor={palette.secondary.main}
                  shouldShowTickLabel={() => true}
                  dateLocale={dateLocale}
                  showYAxis={false}
                  fontSizeX={12}
                  fontSizeY={12}
                />
              </ResponsiveContainer>
            ) : null}
          </GraphContainer>
          <YAxis
            ticks={[
              {
                label: t("dashboard.med.graph.intensity.yAxis.ticks.0"),
                value: 0,
              },
              {
                label: t("dashboard.med.graph.intensity.yAxis.ticks.1"),
                value: 1,
              },
              {
                label: t("dashboard.med.graph.intensity.yAxis.ticks.2"),
                value: 2,
              },
              {
                label: t("dashboard.med.graph.intensity.yAxis.ticks.3"),
                value: 3,
              },
            ]}
            maxValue={3}
          />
        </Box>
      </FTUEFlowDefinitions.DashboardPrescriptionsFTUEFlow.Hints.DashboardPrescriptionsIntensityGraph>
      <Divider
        sx={(theme) => ({
          borderColor: theme.palette.secondary.main,
        })}
      />
      <FTUEFlowDefinitions.DashboardPrescriptionsFTUEFlow.Hints.DashboardPrescriptionsFrequencyGraph>
        <Box display="flex" alignItems="center" px={2} py={1}>
          <Typography variant="h5" mr={1}>
            {t("dashboard.med.graph.frequency.title")}
          </Typography>
          <InformationButton
            onClick={() => {
              setInfoDrawerType("frequency");
              setOpenInfoDrawer(true);
            }}
            iconVariant="outline"
          />
          <Typography ml="auto" fontSize="0.9rem">
            {periodText}
          </Typography>
        </Box>
        <Box px={1} display="flex">
          <Typography fontWeight="bold" ml="auto" mr={8} fontSize="0.8rem">
            {frequencyDaysCount}
          </Typography>
        </Box>
        <Box display="flex" ml={1} position="relative">
          <GraphContainer dataLength={graphData.frequencyData.length}>
            {selectedChild ? (
              <ResponsiveContainer height="100%" width="100%">
                <FrequencyGraph
                  data={graphData.frequencyData}
                  prescriptions={graphData.prescriptions}
                  ticks={periodTicks[period]}
                  start={periodDates.start}
                  tickColor={palette.secondary.main}
                  shouldShowTickLabel={() => true}
                  showYAxis={false}
                  dateLocale={dateLocale}
                  fontSizeX={12}
                  fontSizeY={12}
                  allYTicks={true}
                />
              </ResponsiveContainer>
            ) : null}
          </GraphContainer>
          <YAxis
            ticks={Array.from({ length: 8 }, (_, i) => ({
              label:
                i +
                " " +
                t("dashboard.med.graph.frequency.yAxis.days", { count: i }),
              value: i,
            }))}
            maxValue={7}
          />
        </Box>
      </FTUEFlowDefinitions.DashboardPrescriptionsFTUEFlow.Hints.DashboardPrescriptionsFrequencyGraph>
      <Divider
        sx={(theme) => ({
          borderColor: theme.palette.secondary.main,
        })}
      />
      <Box p={2}>
        <Legend prescriptions={graphData.prescriptions} />
      </Box>
      <InfoDrawer
        open={openInfoDrawer}
        title={t(`dashboard.med.graph.${infoDrawerType}.title`)}
        onClose={() => setOpenInfoDrawer(false)}
        text={t("dashboard.med.graph.info.text")}
      />
      <ExportDrawer
        open={openExportDrawer}
        onDismiss={() => setOpenExportDrawer(false)}
        onSubmit={(value) => exportPDF(value as SideEffectChoice)}
        options={[
          {
            value: "current",
            label: t("dashboard.export.med.current"),
          },
          {
            value: "mediumHigh",
            label: t("dashboard.export.med.moderateHigh"),
          },
          {
            value: "all",
            label: t("dashboard.export.med.all"),
          },
        ]}
        title={t("dashboard.export.title")}
        loading={loading}
      />
      <Toast
        open={noDataError}
        onClose={() => setNoDataError(false)}
        timeout={3000}
        title={t("dashboard.export.error.title")}
        text={t("dashboard.export.error.textMed")}
      />
    </Card>
  );
};

export default MedicationGraphCard;
