import {
  Badge,
  badgeClasses,
  Box,
  Button,
  ButtonBase,
  Drawer,
  Grid,
  Theme,
  Typography,
} from "@mui/material";
import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import DateOptions from "./DateOptions";
import intervalToDuration from "date-fns/intervalToDuration";
import DrawerSection from "common/Components/Drawer/DrawerSection";
import { useTranslation } from "react-i18next";
import Tools from "@neurosolutionsgroup/tools";
import MiscAssets from "assets/misc";
import {
  PickersDay,
  PickersDayProps,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import { isBefore } from "date-fns";
import { SxProps } from "@mui/system";

interface SimpleDatePickerProps {
  date: Date;
  setDate: React.Dispatch<SetStateAction<Date>>;
  pastDays?: number;
  taggedDates?: Date[];
  label: string;
  withTitle?: boolean;
  minDate?: Date;
  maxDate?: Date;
  disableFuture?: boolean;
}

const SimpleDatePicker: React.FC<SimpleDatePickerProps> = ({
  date,
  setDate,
  pastDays = 7,
  taggedDates,
  label,
  withTitle,
  minDate,
  maxDate,
}) => {
  // The "first" (latest) date to be shown in the carousel.
  const [rootDate, setRootDate] = useState(date);
  const [openCalendarDrawer, setOpenCalendarDrawer] = useState(false);
  const [calendarDate, setCalendarDate] = useState(rootDate);

  const { t } = useTranslation();

  useEffect(() => {
    setCalendarDate(rootDate);
  }, [rootDate]);

  const setDateFromUTC = useCallback(
    (value: Date): void => {
      setDate(value);
    },
    [setDate]
  );

  const onDatePickerClose = () => {
    setCalendarDate(rootDate);
    setOpenCalendarDrawer(false);
  };

  const onDatePickerChange = (date: unknown) => {
    if (!(date instanceof Date)) {
      return;
    }

    setCalendarDate(Tools.Time.Dates.getMidnightDate(date));
  };

  const dateIsTagged = (dateArray: Date[], d: Date): boolean => {
    return dateArray.find((td) => td.getTime() === d.getTime()) !== undefined;
  };

  // Custom render for calendar days in order to highlight previous days.
  const renderDay = (
    day: Date,
    selectedDays: (Date | null)[],
    pickerDayProps: PickersDayProps<Date>
  ) => {
    if (
      selectedDays.length === 0 ||
      !(day instanceof Date) ||
      !(selectedDays[0] instanceof Date)
    ) {
      return <PickersDay {...pickerDayProps} />;
    }

    const { selected, ...propsToPass } = pickerDayProps;

    const selectedDate = selectedDays[0];

    const beforeSelectedDate = isBefore(day, selectedDate) || selected;

    let style = undefined;

    if (beforeSelectedDate) {
      const diff = intervalToDuration({
        start: day,
        end: selectedDate,
      });

      const dayDiff = diff.days;

      const monthDiff = diff.months;

      const baseHighlightStyle: SxProps<Theme> = (theme) => ({
        "borderWidth": "1px",
        "borderColor": theme.palette.secondary.main,
        "borderStyle": "solid",
        "boxSizing": "border-box",
        "marginX": 0,
        "width": "40px",
        "&:hover": {
          backgroundColor: theme.palette.secondary.main,
          color: "#fff",
        },
      });

      const startStyle: SxProps<Theme> = (theme) => ({
        ...baseHighlightStyle(theme),
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRight: "none",
      });

      const middleStyle: SxProps<Theme> = (theme) => ({
        ...baseHighlightStyle(theme),
        borderLeft: "none",
        borderRight: "none",
        borderRadius: 0,
      });

      const endStyle: SxProps<Theme> = (theme) => ({
        ...baseHighlightStyle(theme),
        borderLeft: "none",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        backgroundColor: theme.palette.secondary.main,
        color: "#fff",
      });

      if (dayDiff !== undefined && monthDiff === 0) {
        if (dayDiff === 7 - 1) {
          style = startStyle;
        } else if (dayDiff < 7 - 1 && dayDiff > 0) {
          style = middleStyle;
        } else if (selected) {
          style = endStyle;
        }
      }
    }

    return (
      <Badge
        variant="dot"
        color="secondary"
        sx={{ [`& .${badgeClasses.badge}`]: { top: "10px", right: "10px" } }}
        invisible={
          taggedDates
            ? !(
                dateIsTagged(taggedDates, day) &&
                !propsToPass.outsideCurrentMonth
              )
            : true
        }
        key={propsToPass.key}
      >
        <PickersDay sx={style} {...propsToPass} />
      </Badge>
    );
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent={withTitle ? "space-between" : "flex-end"}
        alignItems="center"
        sx={{ marginBottom: "0.5em" }}
      >
        {withTitle && <h3>{label}</h3>}

        <ButtonBase
          onClick={() => setOpenCalendarDrawer(true)}
          sx={{
            margin: "0.25rem",
            padding: "0.25rem",
            filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
            img: {
              height: "2rem",
              width: "2rem",
            },
          }}
        >
          <img src={MiscAssets.CalendarIcon} alt="Calendar" />
        </ButtonBase>
      </Box>
      <DateOptions
        rootDate={rootDate}
        setDate={setDateFromUTC}
        pastDays={pastDays}
        date={date}
        taggedDates={taggedDates}
      />
      <Drawer
        anchor="bottom"
        open={openCalendarDrawer}
        onClose={onDatePickerClose}
      >
        <Box p={2}>
          <DrawerSection variant="header">
            <Typography variant="h4">{label}</Typography>
          </DrawerSection>
          <Grid container justifyContent="center" spacing={2}>
            <Grid
              item
              xs={12}
              sx={(theme) => ({
                "& .Mui-selected": {
                  backgroundColor: theme.palette.secondary.main + " !important",
                },
              })}
            >
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                views={["year", "month", "day"]}
                value={calendarDate}
                onChange={onDatePickerChange}
                renderDay={renderDay}
                renderInput={() => <>{/* unused in desktop mode */}</>}
                minDate={minDate}
                maxDate={maxDate}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="secondary"
                disableElevation={true}
                onClick={() => {
                  setDate(calendarDate);
                  setRootDate(calendarDate);
                  setOpenCalendarDrawer(false);
                }}
              >
                {t("general.actions.confirm")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </>
  );
};

export default SimpleDatePicker;
