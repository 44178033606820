import { Task } from "@neurosolutionsgroup/models";
import Tools from "@neurosolutionsgroup/tools";
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from "react";

export type TasksById = {
  [key: string]: Task;
};

export type TaskRoutineMap = {
  [taskId: string]: string;
};

export interface TaskContextData {
  tasksById: TasksById;
  setTasksById: Dispatch<SetStateAction<TasksById>>;
  taskRoutineMap: TaskRoutineMap;
  setTaskRoutineMap: Dispatch<SetStateAction<TaskRoutineMap>>;
  resetTask: () => void;
}

const [useTasksContext, TasksContextProvider] =
  Tools.Context.createGenericContext<TaskContextData>(__filename);

const TasksProvider: React.FC<PropsWithChildren> = (props) => {
  const [tasksById, setTasksById] = useState<TasksById>({});
  const [taskRoutineMap, setTaskRoutineMap] = useState<TaskRoutineMap>({});

  const resetTask = () => {
    setTasksById({});
    setTaskRoutineMap({});
  };

  return (
    <TasksContextProvider
      value={{
        tasksById,
        setTasksById,
        taskRoutineMap,
        setTaskRoutineMap,
        resetTask,
      }}
    >
      {props.children}
    </TasksContextProvider>
  );
};

export { useTasksContext, TasksProvider };
