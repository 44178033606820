import { Button, Grid, Typography, useTheme } from "@mui/material";
import { Icons } from "@neurosolutionsgroup/components";
import { ChallengeV2, Language } from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";
import { useTranslation } from "react-i18next";
import ChallengeDisplayCardHistory from "./ChallengeDisplayCardHistory";
import { format, Locale } from "date-fns";

interface ChallengeDisplayCardDetailsProps {
  challenge: ChallengeV2;
  challengeEnded: boolean;
  dateLocale: Locale;
  language: Language;
  showDebug: boolean;
  onApproval: (approved: boolean) => void;
  onDelete: VoidFunction;
  onValidate: (done: boolean) => void;
  toApprove: boolean;
}

const ChallengeDisplayCardDetails = ({
  challenge,
  challengeEnded,
  showDebug,
  dateLocale,
  language,
  onApproval,
  onDelete,
  onValidate,
  toApprove,
}: ChallengeDisplayCardDetailsProps): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();

  const timeToString = () => {
    return challenge.startTime && challenge.endTime
      ? Tools.Time.Strings.localizedTimePeriodFromSeconds(
          challenge.startTime,
          challenge.endTime,
          language
        )
      : null;
  };

  return (
    <Grid container rowSpacing={1}>
      {challenge.description ? (
        <>
          <Grid item xs={12}>
            <Typography fontSize="0.85rem" fontWeight={700}>
              {t("routine.challenge.inputs.description.label")}
            </Typography>

            <Typography fontSize="0.9rem">{challenge.description}</Typography>
          </Grid>
        </>
      ) : null}

      <Grid item xs={12}>
        <Typography fontSize="0.85rem" fontWeight={700}>
          {t("routine.challenge.form.config.title")}
        </Typography>

        <Typography fontSize="0.9rem">
          {t("routine.challenge.inputs.frequency.option", {
            count: challenge.frequency,
          })}
        </Typography>

        <Typography fontSize="0.9rem">
          {t("routine.challenge.summary.numberOfWeeks", {
            count: challenge.duration,
          })}
        </Typography>

        <Typography fontSize="0.9rem">
          {t("routine.challenge.summary.startDate", {
            count: challenge.duration,
          })}{" "}
          {format(
            Tools.Time.Dates.parseDateStringToJsDate(challenge.startDate),
            "d MMM yyyy",
            { locale: dateLocale }
          )}
        </Typography>

        {challenge.days !== null && challenge.days !== 0 ? (
          <Typography fontSize="0.9rem">
            {Tools.Time.Days.dayIndexes.flatMap((day) =>
              Tools.Time.Days.dayInDays(day, challenge.days ?? 0) ? (
                <span key={day}>{t(`general.time.days.short.${day}`)} </span>
              ) : (
                []
              )
            )}
          </Typography>
        ) : null}

        {challenge.startTime && challenge.endTime ? (
          <Typography fontSize="0.9rem">{timeToString()}</Typography>
        ) : null}
      </Grid>

      {challenge.createdByName ? (
        <>
          <Grid item xs={12}>
            <Typography fontSize="0.85rem" fontWeight={700}>
              {t("routine.challenge.approval.title")}
            </Typography>

            <Typography fontSize="0.9rem">{challenge.createdByName}</Typography>
          </Grid>
        </>
      ) : null}

      {challengeEnded ? (
        <>
          <Grid item xs={12}>
            <Typography fontSize="0.85rem" fontWeight={700} mb={0.5}>
              {t("routine.challenge.details.title")}
            </Typography>

            <ChallengeDisplayCardHistory challenge={challenge} />
          </Grid>
        </>
      ) : null}

      {!challengeEnded ? (
        <Grid
          item
          xs={12}
          display="flex"
          flexWrap="wrap"
          justifyContent="end"
          alignItems="center"
          mt={1}
        >
          {toApprove ? (
            <>
              <Button
                onClick={() => onApproval(false)}
                color="error"
                variant="text"
                sx={{
                  marginRight: 1,
                }}
                data-cy="challenge-approval-reject"
              >
                <Icons.CloseIcon color={theme.palette.error.main} />
                {t("general.actions.reject")}
              </Button>
              <Button
                onClick={() => onApproval(true)}
                color="secondary"
                variant="text"
                data-cy="challenge-approval-accept"
              >
                <Icons.CheckMarkIcon
                  checkStyle="kairos"
                  color={theme.palette.secondary.main}
                />
                {t("general.actions.approve")}
              </Button>
            </>
          ) : (
            <>
              {showDebug ? (
                <Button
                  color="secondary"
                  variant="text"
                  onClick={() => onValidate(true)}
                  sx={{
                    marginRight: 1,
                  }}
                  data-cy="challenge-debug-child-validation"
                >
                  <Icons.CheckMarkIcon
                    checkStyle="kairos"
                    color={theme.palette.secondary.main}
                  />
                  &nbsp;Test
                </Button>
              ) : null}
              <Button
                color="error"
                variant="text"
                onClick={onDelete}
                data-cy="challenge-delete"
              >
                <Icons.DeleteIcon color={theme.palette.error.main} />
                &nbsp;
                {t("general.actions.delete")}
              </Button>
            </>
          )}
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ChallengeDisplayCardDetails;
