import {
  OnUserLoginFunction,
  PageViewFunction,
  AnalyticsEvent,
  AnalyticService,
  TrackEventFunction,
  SetProfilePropertiesFunction,
} from "@neurosolutionsgroup/analytics";
import Tools from "@neurosolutionsgroup/tools";
import axios from "axios";

let customerIOURL;
let userId;

const init = (url: string): void => {
  customerIOURL = url;
};

const onUserLogin: OnUserLoginFunction = (
  distinctId,
  language,
  email,
  created
) => {
  userId = distinctId;

  axios.post(customerIOURL + "/customerio/identify", {
    userId,
    userProperties: {
      language,
      email,
      created_at: created,
      timezone: Tools.Time.Dates.getTimezone(),
    },
  });
};

const pageView: PageViewFunction = (path) => {
  if (!userId) {
    console.warn(`[Analytics] Page view "${path}" called without userId set.`);

    return;
  }

  axios.post(customerIOURL + "/customerio/page", {
    userId,
    path,
  });
};

const trackEvent: TrackEventFunction = (eventName, properties) => {
  if (!userId) {
    console.warn(
      `[Analytics] Track event "${eventName}" called without userId set.`
    );

    return;
  }

  axios.post(customerIOURL + "/customerio/track", {
    userId,
    eventName,
    eventProperties: properties,
  });
};

const setProfileProperties: SetProfilePropertiesFunction = (properties) => {
  if (!userId) {
    console.warn("[Analytics] setProfileProperties called without userId set.");

    return;
  }

  axios.post(customerIOURL + "/customerio/identify", {
    userId,
    userProperties: stripUserAttributes(properties),
  });
};

const setOnceProfileProperties: SetProfilePropertiesFunction = (properties) => {
  console.warn("setOnceProperties is not implemented for Customer.IO");
};

const handleEvent = (event: AnalyticsEvent) => {
  if (!userId) {
    console.warn(
      `[Analytics] Track event "${event.name}" called without userId set.`
    );

    return;
  }

  axios.post(customerIOURL + "/customerio/track", {
    userId,
    eventName: event.name,
    eventProperties: event.eventProperties,
    userProperties: stripUserAttributes(event.setProperties),
  });
};

const stripUserAttributes = (
  attributes: Record<string, unknown>
): Record<string, unknown> => {
  const newAttributes = { ...attributes };

  const attributesToStrip = ["$email", "Email"];

  attributesToStrip.forEach((stripKey) => {
    if (Object.keys(newAttributes).includes(stripKey)) {
      delete newAttributes[stripKey];
    }
  });

  return newAttributes;
};

export const getCustomerIOService = (url: string): AnalyticService => {
  return {
    name: "CustomerIO",
    init: () => init(url),
    functions: {
      onUserLogin,
      pageView,
      trackEvent,
      setProfileProperties,
      setOnceProfileProperties,
    },
    handleEvent,
  };
};
