import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  Typography,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import without from "lodash/without";
import xor from "lodash/xor";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Drawer from "common/Components/Drawer/Drawer";
import DrawerSection from "common/Components/Drawer/DrawerSection";
import { Icons } from "@neurosolutionsgroup/components";
import { Condition, HasCondition } from "@neurosolutionsgroup/models";
import useUserProfile from "common/hooks/account/useUserProfile";
import MedicalConsentDrawer from "./MedicalConsentDrawer";

export interface ConditionSelectClasses {
  /** class name applied to the main container div */
  root?: string;
  /** class name applied to the select button */
  button?: string;
  /** class name applied to the drawers containing the options */
  drawer?: string;
  /** class name applied to each option inside the drawers */
  option?: string;
}

export interface ConditionSelectProps {
  /** 'id' attribute for the component, passed down to the containing 'div'. */
  id?: string;
  /** additional CSS classes for customizing the component's various elements. */
  classes?: ConditionSelectClasses;
  /** hides the indicator icon from the select button. */
  hideIndicator?: boolean;
  /** indicator icon shown at the end of the button. defaults to an ArrowIcon pointing down. */
  indicator?: JSX.Element;
  /** list of selected conditions. */
  conditions?: Condition[] | null;
  /** called whenever the selected conditions change. */
  onChange: (conditions: Condition[] | undefined) => void;
}

interface DrawerOption<T extends number> {
  value: T;
  label: string;
}

/**
 * Displays a selector for medical conditions.
 *
 * It first asks the user whether or not their child has a condition, and if
 * they select 'yes', it then asks to select which condition(s) they have.
 *
 * 'value' is set to an array of the conditions selected, which is empty if 'no'
 * was selected in the first stage, or `undefined` if 'prefer not to answer' was
 * selected.
 */
export const ConditionSelect: React.FC<ConditionSelectProps> = (props) => {
  const ROOT_CLASS = "kairos-condition-select";

  const { t } = useTranslation(undefined, { keyPrefix: "settings.child" });
  const { t: generalT } = useTranslation(undefined, { keyPrefix: "general" });
  const { palette } = useTheme();

  const {
    selectors: { userProfile },
    actions: { updateMasterSettingsKey },
  } = useUserProfile();

  const firstDrawerOptions: DrawerOption<HasCondition>[] = [
    { value: HasCondition.YES, label: t("yesCondition") },
    { value: HasCondition.NO, label: t("noCondition") },
    { value: HasCondition.UNKNOWN, label: t("noAnswer") },
  ];

  const secondDrawerOptions: DrawerOption<Condition>[] = [
    { value: Condition.ADD, label: t("add") },
    { value: Condition.ADHD, label: t("adhd") },
    { value: Condition.ASD, label: t("asd") },
    { value: Condition.UNLISTED, label: t("unlistedCondition") },
  ];

  const [medicalInfoStateDrawerOpen, setMedicalInfoStateDrawerOpen] =
    useState(false);
  const [consentDrawerOpen, setConsentDrawerOpen] = useState<boolean>(false);
  const [conditionDrawerOpen, setConditionDrawerOpen] = useState(false);

  const [selectedConditions, setSelectedConditions] = useState(
    props.conditions ?? []
  );

  const shouldSeeConsentOnMedicalInfo = (): boolean => {
    if (userProfile && userProfile.masterSettings) {
      if (
        userProfile.masterSettings.settings["medicalInfoInformedConsentGiven"]
      ) {
        return false;
      }
    }
    return true;
  };
  const onConsentGiven = () => {
    updateMasterSettingsKey("medicalInfoInformedConsentGiven", "True");
    setConditionDrawerOpen(true);
    setConsentDrawerOpen(false);
  };

  const onConsentDrawerClose = () => {
    setConsentDrawerOpen(false);
  };

  useEffect(() => {
    setSelectedConditions(props.conditions ?? []);
  }, [props.conditions]);

  const onFirstDrawerOptionClick = (hasCondition: HasCondition) => {
    setMedicalInfoStateDrawerOpen(false);

    switch (hasCondition) {
      case HasCondition.NO:
        props.onChange([]);
        break;

      case HasCondition.UNKNOWN:
        props.onChange(undefined);
        break;

      case HasCondition.YES:
        if (shouldSeeConsentOnMedicalInfo()) {
          setConsentDrawerOpen(true);
        } else {
          setConditionDrawerOpen(true);
        }
        break;
    }
  };

  const onSecondDrawerOptionClick = (
    condition: Condition,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.checked) {
      if (condition === Condition.UNLISTED) {
        // unlisted means 'none of the above', so clear those
        setSelectedConditions([condition]);
      } else {
        setSelectedConditions(
          [...without(selectedConditions, Condition.UNLISTED), condition].sort()
        );
      }
    } else {
      setSelectedConditions(without(selectedConditions, condition));
    }
  };

  const onSecondDrawerClose = () => {
    setConditionDrawerOpen(false);

    if (xor(props.conditions, selectedConditions).length > 0) {
      // something's been added or removed
      props.onChange(selectedConditions);
    }

    setSelectedConditions(props.conditions ?? []);
  };

  const findConditionLabel = (condition: Condition) =>
    secondDrawerOptions.find((opt) => opt.value === condition)?.label ?? "";

  const isSelected = useCallback(
    (condition: Condition) => {
      return selectedConditions.includes(condition);
    },
    [selectedConditions]
  );

  const renderValueLabel = () => {
    if (props.conditions === undefined) {
      return generalT("actions.select");
    }

    if (props.conditions === null) {
      return t("noAnswer");
    }

    if (props.conditions.length === 0) {
      return t("noCondition");
    }

    return t("yesCondition");
  };

  const renderValues = () => {
    if (!props.conditions || props.conditions.length === 0) {
      return null;
    }

    return (
      <ul>
        {props.conditions.map((c, i) => (
          <li key={i}>
            <Typography fontSize="0.9rem">{findConditionLabel(c)}</Typography>
          </li>
        ))}
      </ul>
    );
  };

  const renderIndicator = () => {
    if (props.hideIndicator) {
      return null;
    }

    if (props.indicator) {
      return props.indicator;
    }

    return (
      <Icons.ArrowIcon
        className={`${ROOT_CLASS}__indicator-icon`}
        arrowDirection="down"
        color={palette.secondary.main}
      />
    );
  };

  const renderMedicalInfoDrawerOption = (
    option: DrawerOption<HasCondition>,
    idx: number
  ) => (
    <DrawerSection
      key={idx}
      className={clsx(`${ROOT_CLASS}__option`, props.classes?.option)}
    >
      <ButtonBase
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => onFirstDrawerOptionClick(option.value)}
      >
        <Typography color="secondary">{option.label}</Typography>
      </ButtonBase>
    </DrawerSection>
  );

  const renderMedicalInfoDrawer = () => {
    return (
      <Drawer
        anchor="bottom"
        open={medicalInfoStateDrawerOpen}
        onClose={() => setMedicalInfoStateDrawerOpen(false)}
        classes={{
          paper: clsx(`${ROOT_CLASS}__drawer`, props.classes?.drawer),
        }}
      >
        <DrawerSection variant="header">
          <Typography variant="h4">{t("conditionYNHeader")}</Typography>
        </DrawerSection>
        {firstDrawerOptions.map(renderMedicalInfoDrawerOption)}
      </Drawer>
    );
  };

  const renderConditionDrawer = (
    option: DrawerOption<Condition>,
    idx: number
  ) => (
    <DrawerSection
      key={idx}
      className={clsx(`${ROOT_CLASS}__option`, props.classes?.option)}
    >
      <Box
        display="flex"
        justifyContent="stretch"
        alignItems="stretch"
        height="100%"
        width="100%"
      >
        <Box
          p={2}
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <label htmlFor={option.value.toString()}>
            <Typography>{option.label}</Typography>
          </label>

          <Checkbox
            id={option.value.toString()}
            checked={isSelected(option.value)}
            onChange={(e) => onSecondDrawerOptionClick(option.value, e)}
            icon={<Icons.CheckBoxIcon isChecked={false} />}
            checkedIcon={<Icons.CheckBoxIcon isChecked={true} />}
            color="secondary"
            sx={{
              "& svg": {
                width: "1.5rem",
                height: "1.5rem",
              },
            }}
          />
        </Box>
      </Box>
    </DrawerSection>
  );

  const renderSecondDrawer = () => {
    return (
      <Drawer
        anchor="bottom"
        open={conditionDrawerOpen}
        onClose={onSecondDrawerClose}
        classes={{
          paper: clsx(`${ROOT_CLASS}__drawer`, props.classes?.drawer),
        }}
      >
        <DrawerSection variant="header">
          <Typography variant="h4">{t("conditionListHeader")}</Typography>
        </DrawerSection>
        {secondDrawerOptions.map(renderConditionDrawer)}
        <DrawerSection>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                onSecondDrawerClose();
              }}
            >
              {generalT("actions.confirm")}
            </Button>
          </Box>
        </DrawerSection>
      </Drawer>
    );
  };

  return (
    <Box
      id={props.id}
      sx={(theme) => ({
        color: theme.palette.secondary.main,
      })}
    >
      <Button
        fullWidth
        color="secondary"
        variant="text"
        className={clsx(`${ROOT_CLASS}__button`, props.classes?.button)}
        onClick={() => setMedicalInfoStateDrawerOpen(true)}
        sx={{
          fontWeight: "normal",
        }}
      >
        {renderValueLabel()}
        <Box ml="auto">{renderIndicator()}</Box>
      </Button>
      {renderValues()}
      {renderMedicalInfoDrawer()}
      <MedicalConsentDrawer
        open={consentDrawerOpen}
        onAccept={onConsentGiven}
        onClose={onConsentDrawerClose}
      />
      {renderSecondDrawer()}
    </Box>
  );
};

export default ConditionSelect;
