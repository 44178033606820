import {
  Box,
  MobileStepper,
  mobileStepperClasses,
  Typography,
} from "@mui/material";
import Tools from "@neurosolutionsgroup/tools";
import BackgroundAssets from "assets/background";
import Logos from "assets/logo";
import { StickyButtons } from "common/Components";
import { AgeGateV2, WebviewLink } from "@neurosolutionsgroup/components";
import { STICKY_BUTTONS_PAGE_PADDING } from "common/Components/Buttons/StickyButtons";
import useAuth from "common/hooks/auth/useAuth";
import useLanguage from "common/hooks/Parameters/useLanguage";
import useParameters from "common/hooks/Parameters/useParameters";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { SlideRendererCallback, virtualize } from "react-swipeable-views-utils";
import { SAFE_AREA_TOP, SAFE_AREAS } from "stylesheets";
import IntroCoach from "./Screens/IntroCoach";
import IntroGame from "./Screens/IntroGame";
import IntroPrescription from "./Screens/IntroPrescription";
import IntroRoutines from "./Screens/IntroRoutines";
import IntroValidation from "./Screens/IntroValidation";
import IntroWelcome from "./Screens/IntroWelcome";

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

const Intro: React.FC = () => {
  const STEPS = 6;

  const { t } = useTranslation();
  const { language } = useLanguage();
  const { version, buildNumber, openPrivacyPolicy, openTerms } =
    useParameters();
  const navigate = useNavigate();
  const { user } = useAuth();

  const [index, setIndex] = useState(0);
  const [ageGate, setAgeGate] = useState<"terms" | "privacy">();

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);

  const onContinue = () => {
    navigate("/signup");
  };

  const handleExternalLinkClicked = (link: "terms" | "privacy") => {
    setAgeGate(link);
  };

  const handleAgeGateComplete = () => {
    switch (ageGate) {
      case "terms":
        openTerms(language);
        break;
      case "privacy":
        openPrivacyPolicy(language);
        break;
    }

    setAgeGate(undefined);
  };

  const renderSlide: SlideRendererCallback = ({ index: slideIndex, key }) => {
    switch (slideIndex % STEPS) {
      case 0:
        return (
          <IntroScreen active={index % STEPS === 0} key={key}>
            <IntroWelcome />
          </IntroScreen>
        );
      case 1:
        return (
          <IntroScreen active={index % STEPS === 1} key={key}>
            <IntroRoutines />
          </IntroScreen>
        );
      case 2:
        return (
          <IntroScreen active={index % STEPS === 2} key={key}>
            <IntroPrescription />
          </IntroScreen>
        );
      case 3:
        return (
          <IntroScreen active={index % STEPS === 3} key={key}>
            <IntroValidation />
          </IntroScreen>
        );
      case 4:
        return (
          <IntroScreen active={index % STEPS === 4} key={key}>
            <IntroCoach />
          </IntroScreen>
        );
      case 5:
        return (
          <IntroScreen active={index % STEPS === 5} key={key}>
            <IntroGame />
          </IntroScreen>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={(theme) => ({
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: theme.palette.primary.main,
        paddingTop: SAFE_AREA_TOP,
        paddingBottom: `calc(${STICKY_BUTTONS_PAGE_PADDING} + 6px)`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      })}
    >
      <Box
        my={1}
        flexGrow={1}
        sx={{
          boxSizing: "border-box",
          img: {
            height: 0,
            minHeight: "100%",
          },
        }}
      >
        <img
          src={Tools.Language.languageSwitch(language, {
            en: Logos.KairosIllustratedEN,
            fr: Logos.KairosIllustratedFR,
          })}
          alt={Tools.Environment.getProductName()}
        />
      </Box>
      <Box width="100vw" minHeight="calc(65vh + 8px)">
        <VirtualizeSwipeableViews
          slideRenderer={renderSlide}
          axis="x"
          index={index}
          min={0}
          onChangeIndex={(i) => {
            if (index >= 0) {
              setIndex(i);
            }
          }}
          style={{
            padding: "0 2rem",
          }}
          slideCount={100}
          overscanSlideAfter={4}
          overscanSlideBefore={3}
        />
      </Box>
      <MobileStepper
        steps={STEPS}
        activeStep={index % STEPS}
        position="static"
        variant="dots"
        nextButton={null}
        backButton={null}
        sx={{
          backgroundColor: "transparent",
          [`& .${mobileStepperClasses.dot}`]: {
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            borderColor: "#fff",
            borderRadius: "8px",
            transition: "width 0.5s ease, background-color 0.5s ease",
            width: "16px",
            height: "16px",
            marginX: "6px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          },
          [`& .${mobileStepperClasses.dotActive}`]: {
            backgroundColor: "#fff",
            width: "32px",
          },
        }}
      />
      <Box>
        <Typography fontSize={"0.8rem"} color="#fff">
          <WebviewLink
            color="#fff"
            onClick={() => {
              handleExternalLinkClicked("terms");
            }}
          >
            {t("settings.tos")}
          </WebviewLink>
          &nbsp;&&nbsp;
          <WebviewLink
            color="#fff"
            onClick={() => {
              handleExternalLinkClicked("privacy");
            }}
          >
            {t("settings.privacyPolicy")}
          </WebviewLink>
        </Typography>
      </Box>
      <Box>
        <Typography fontSize="0.6rem" color="#fff" sx={{ opacity: 0.5 }}>
          {t("settings.version", {
            version: version ?? "unknown",
            build: buildNumber ?? "unknown",
            webBuild: `#${
              Tools.Environment.webviewsEnvTools.getBuildNumber() ?? "unknown"
            }`,
          })}
        </Typography>
      </Box>
      <AgeGateV2
        show={!!ageGate}
        onComplete={() => handleAgeGateComplete()}
        onClose={() => setAgeGate(undefined)}
        safeAreas={SAFE_AREAS}
      />
      <StickyButtons
        onConfirm={onContinue}
        confirmText={t("navigation.intro.action")}
        confirmColor="success"
        fullWidth
        data-cy="start-adventure-button"
      />
    </Box>
  );
};

export default Intro;

interface IntroScreenProps extends PropsWithChildren {
  active: boolean;
}

const IntroScreen: React.FC<IntroScreenProps> = (props) => {
  return (
    <Box
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        backgroundImage: `url(${BackgroundAssets.HexagonSpritesMixed})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        height: props.active ? "65vh" : "55vh",
        marginTop: props.active ? 0 : "5vh",
        marginX: "0.5rem",
        marginBottom: "8px",
        transition: "height 0.5s ease, margin-top 0.5s ease",
        borderRadius: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      {props.children}
    </Box>
  );
};
