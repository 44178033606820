import { Box, Typography } from "@mui/material";
import { Challenge } from "@neurosolutionsgroup/models";
import React from "react";
import { useTranslation } from "react-i18next";
import ChallengeCard from "./ChallengeCard";

export interface ChallengesForApprovalProps {
  filteredChallengesForApproval: Challenge[];
  onChallengeDelete: (c: Challenge) => void;
}

const ChallengesForApproval: React.FC<ChallengesForApprovalProps> = ({
  filteredChallengesForApproval,
  onChallengeDelete,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h2" my={1}>
          {t("routine.challenge.titleApproval")}
        </Typography>
      </Box>
      <Box data-cy="for-approval-challenge-list">
        {filteredChallengesForApproval
          .sort((a, b) => (b.createdDate ?? 0) - (a.createdDate ?? 0))
          .map((c) => (
            <Box key={c.id} pb={2}>
              <ChallengeCard
                challenge={c}
                onDelete={() => onChallengeDelete(c)}
              />
            </Box>
          ))}
      </Box>
    </>
  );
};
export default ChallengesForApproval;
