import { Box, Grid } from "@mui/material";
import { Challenge } from "@neurosolutionsgroup/models";
import React from "react";
import DynamismAssets from "assets/dynamism";
import { useTranslation } from "react-i18next";
import ValidationSection from "../ValidationSection";
import ChallengeValidationCard from "./ChallengeValidationCard";

export interface ChallengeSectionProps {
  showTitle: boolean;
  challenges: Challenge[];
  onChallengeValidationComplete: (challenge: Challenge) => void;
}

const ChallengeSection: React.FC<ChallengeSectionProps> = ({
  showTitle,
  challenges,
  onChallengeValidationComplete,
}) => {
  const { t } = useTranslation();

  return (
    <ValidationSection
      title={t("validation.challenge.title")}
      showTitle={showTitle}
    >
      {challenges.length > 0 ? (
        <Box data-cy="challenge-validation-list">
          {challenges.map((c) => {
            return (
              <Box mb={1} key={c.id}>
                <ChallengeValidationCard
                  challenge={c}
                  onChallengeValidationComplete={(challenge) => {
                    onChallengeValidationComplete(challenge);
                  }}
                />
              </Box>
            );
          })}
        </Box>
      ) : (
        <ChallengeEmptyState />
      )}
    </ValidationSection>
  );
};
export default ChallengeSection;

const ChallengeEmptyState: React.FC = () => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        display: "flex",
        justifyContent: "center",
        img: {
          height: "150px",
          maxHeight: "100%",
        },
        height: "150px",
        transitionProperty: "height",
        transitionDelay: "1s",
        transitionDuration: "0s",
      }}
    >
      <img src={DynamismAssets.Routine_Validation} alt="" />
    </Grid>
  );
};
