import { Box, ButtonBase, Grid, Typography } from "@mui/material";
import { CharacterCount, CloseablePage, TextInput } from "common/Components";
import StickyButtons, {
  STICKY_BUTTONS_PAGE_PADDING,
} from "common/Components/Buttons/StickyButtons";
import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import ChildPicker from "../RoutineEdition/ChildPicker/ChildPicker";
import useChildren from "common/hooks/children/useChildren";
import IconPicker from "../RoutineEdition/TaskEdition/IconPicker/IconPicker";
import { PageSection } from "common/Components/PageSection";
import DayPicker from "../RoutineEdition/DayPicker/DayPicker";
import { TimeRangePicker } from "common/Components/TimePicker";
import useLanguage from "common/hooks/Parameters/useLanguage";
import Tools from "@neurosolutionsgroup/tools";
import { v4 } from "uuid";
import useChallenges from "common/hooks/challenges/useChallenges";
import { DatePicker, Dialogs, Loader } from "@neurosolutionsgroup/components";
import ChallengeInfoDrawer from "../ChallengeInfoDrawer/ChallengeInfoDrawer";
import Select, { SelectOption } from "common/Components/Select/Select";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import { HOURS, MINUTES } from "common/Utils/TimeUtils";
import ChallengeSummary from "./ChallengeSummary";
import React from "react";
import { calculateIfAChallengeIsAchievable } from "./ChallengeEditionLogic";
import { SAFE_AREA_BOTTOM } from "stylesheets";
import DialogAssets from "assets/dialogs";

interface ChallengeEditionProps {
  show: boolean;
  setShow: React.Dispatch<SetStateAction<boolean>>;
}

const ChallengeEdition = ({
  show,
  setShow,
}: ChallengeEditionProps): JSX.Element | null => {
  const { language } = useLanguage();
  const { t } = useTranslation();
  const { handleUnknownError } = useErrorsContext();
  const {
    selectors: { childIds, childrenById },
  } = useChildren();
  const {
    selectors: { loading, challenges },
    actions: { createChallenge },
  } = useChallenges();

  const MAX_TITLE_LENGTH = 65;
  const MAX_DESCRIPTION_LENGTH = 250;
  const MAX_REWARD_LENGTH = 150;

  const [selectedChildren, setSelectedChildren] = useState<string[]>([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [icon, setIcon] = useState<number>(0);
  const [rewardIndex, setRewardIndex] = useState<number>(0);
  const [reward, setReward] = useState("");
  const [days, setDays] = useState(0);
  const [startTime, setStartTime] = useState<number | null>(null);
  const [endTime, setEndTime] = useState<number | null>(null);
  const [endDateMS, setEndDateMS] = useState<number | null>(null);
  const [occurrences, setOccurrences] = useState<number>(1);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);

  const resetState = () => {
    setSelectedChildren([]);
    setTitle("");
    setDescription("");
    setIcon(0);
    setRewardIndex(0);
    setReward("");
    setDays(0);
    setStartTime(null);
    setEndTime(null);
    setEndDateMS(null);
    setOccurrences(1);
  };

  const isChildDisabled = (childId: string): boolean => {
    return challenges.some(
      (challenge) => challenge.childId === childId && !challenge.createdById
    );
  };

  const availableChild = useMemo((): string[] => {
    const output: string[] = [];
    childIds.forEach((id) => {
      if (!isChildDisabled(id)) {
        output.push(id);
      }
    });
    return output;
  }, [childIds, challenges]);

  useEffect(() => {
    if (availableChild.length === 1) {
      setSelectedChildren(availableChild);
    }
  }, [availableChild]);

  useEffect(() => {
    if (startTime && !endTime) {
      setEndTime(startTime + 3600);
    }
  }, [startTime]);

  useEffect(() => {
    if (endTime && !startTime) {
      setEndTime(endTime > 3600 ? endTime - 3600 : 0);
    }
  }, [endTime]);

  const isTitleValid = useMemo(() => {
    return title.length > 0 && title.length <= MAX_TITLE_LENGTH;
  }, [title]);

  const isDescriptionValid = useMemo(() => {
    return description.length <= MAX_DESCRIPTION_LENGTH;
  }, [description]);

  const isRewardValid = useMemo(() => {
    return reward.length > 0 && reward.length <= MAX_REWARD_LENGTH;
  }, [reward]);

  const canSubmit = useMemo(() => {
    return (
      selectedChildren.length === 1 &&
      isTitleValid &&
      isDescriptionValid &&
      icon > 0 &&
      isRewardValid
    );
  }, [selectedChildren, isTitleValid, isDescriptionValid, icon, isRewardValid]);

  const onClose = () => {
    resetState();
    setShow(false);
  };

  const onConfirm = () => {
    setOpenConfirmation(true);
  };

  const convertEndDateMSToTimestamp = (
    dateMS: number | null
  ): number | null => {
    if (!dateMS) {
      return null;
    } else {
      const startOfDate = Math.floor(dateMS / 1000);

      const endOfDate = startOfDate + 23 * HOURS + 59 * MINUTES + 59;

      return endOfDate;
    }
  };

  const onContinue = async () => {
    setOpenConfirmation(false);

    try {
      await createChallenge({
        id: v4(),
        childId: selectedChildren[0],
        title,
        description: description.length > 0 ? description : null,
        icon,
        reward: {
          text: reward,
        },
        days,
        startTime,
        endTime,
        endDate: convertEndDateMSToTimestamp(endDateMS),
        occurrences,
        active: true,
        successful: null,
        deleted: false,
        createdDate: Tools.Time.Dates.getTimeStamp(),
        history: [],
      });

      onClose();
    } catch (err) {
      handleUnknownError(err);
    }
  };

  interface RewardOption extends SelectOption {
    value: number;
    label: string;
  }

  const getRewardOptions = useCallback((): RewardOption[] => {
    return [
      {
        value: 0,
        label: t("routine.challenge.rewards.0"),
      },
      {
        value: 1,
        label: t("routine.challenge.rewards.1"),
      },
      {
        value: 2,
        label: t("routine.challenge.rewards.2"),
      },
      {
        value: 3,
        label: t("routine.challenge.rewards.3"),
      },
      {
        value: 4,
        label: t("routine.challenge.rewards.4"),
      },
      {
        value: 5,
        label: t("routine.challenge.rewards.5"),
      },
      {
        value: 6,
        label: t("routine.challenge.rewards.6"),
      },
      {
        value: 7,
        label: t("routine.challenge.rewards.7"),
      },
      {
        value: 8,
        label: t("routine.challenge.rewards.8"),
      },
      {
        value: 9,
        label: t("routine.challenge.rewards.9"),
      },
    ];
  }, [language]);

  interface OccurenceOption extends SelectOption {
    value: number;
    label: string;
  }

  const occurrenceOptions = (): OccurenceOption[] => {
    const options: OccurenceOption[] = [];
    for (let i = 0; i < 10; i++) {
      options.push({ value: i + 1, label: (i + 1).toString() });
    }
    return options;
  };

  useEffect(() => {
    if (rewardIndex !== 0) {
      setReward(t(`routine.challenge.rewards.${rewardIndex}`));
    }
  }, [rewardIndex]);

  const resetHeader = (title: string, onClick: VoidFunction) => {
    return (
      <Box display="flex" alignItems="center">
        <Typography variant="h3">{title}</Typography>
        <ButtonBase
          onClick={onClick}
          sx={(theme) => ({
            marginLeft: "auto",
            padding: theme.spacing(1),
          })}
        >
          <Typography sx={{ textDecoration: "underline" }}>
            {t("general.actions.reset")}
          </Typography>
        </ButtonBase>
      </Box>
    );
  };

  const isChallengeAchivable = useMemo((): boolean => {
    return calculateIfAChallengeIsAchievable(
      occurrences,
      days,
      endDateMS ? endDateMS + (23 * HOURS + 59 * MINUTES + 59) * 1000 : null
    );
  }, [occurrences, days, endDateMS]);

  const onCancel = () => {
    setShowCancelDialog(true);
  };

  return show ? (
    <CloseablePage
      isOpen={show}
      onClose={onClose}
      color="secondary"
      header={
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h3" color="#fff" my={1}>
            {t("routine.challenge.create")}
          </Typography>
          <ChallengeInfoDrawer color="high-vis" />
        </Box>
      }
      overrideNavHeader
    >
      {loading ? <Loader /> : null}
      <Grid
        container
        direction="row"
        alignItems="stretch"
        sx={{
          paddingBottom: SAFE_AREA_BOTTOM,
          marginBottom: STICKY_BUTTONS_PAGE_PADDING,
        }}
      >
        <Grid item xs={12}>
          <PageSection
            title={t("routine.challenge.inputs.child.label") + "*"}
            showBottomBorder={false}
          >
            <ChildPicker
              children={childIds.map((u) => {
                return childrenById[u];
              })}
              pickedChildren={selectedChildren}
              setPickedChildren={setSelectedChildren}
              multiple={false}
              isChildDisabled={isChildDisabled}
            />
          </PageSection>
        </Grid>
        <Grid item xs={12}>
          <PageSection
            title={t("routine.challenge.inputs.title.label") + "*"}
            showBottomBorder={false}
          >
            <TextInput
              placeholder={t("routine.challenge.inputs.title.placeholder")}
              value={title}
              onChange={(e) => setTitle(e.currentTarget.value)}
              fullWidth
              inputProps={{
                maxLength: MAX_TITLE_LENGTH,
              }}
              data-cy={"challenge-edition-title-input"}
            />
            <CharacterCount text={title} maxLength={MAX_TITLE_LENGTH} />
          </PageSection>
        </Grid>
        <Grid item xs={12}>
          <PageSection
            title={t("routine.challenge.inputs.description.label")}
            showBottomBorder={false}
          >
            <TextInput
              placeholder={t(
                "routine.challenge.inputs.description.placeholder"
              )}
              value={description}
              onChange={(e) => setDescription(e.currentTarget.value)}
              multiline
              fullWidth
              minRows={2}
              inputProps={{
                maxLength: MAX_DESCRIPTION_LENGTH,
              }}
              data-cy="challenge-edition-description-input"
            />
            <CharacterCount
              text={description}
              maxLength={MAX_DESCRIPTION_LENGTH}
            />
          </PageSection>
        </Grid>
        <Grid item xs={12}>
          <PageSection
            title={t("routine.challenge.inputs.icon.label") + "*"}
            showBottomBorder={false}
          >
            <IconPicker icon={icon} setIcon={setIcon} showEmptyState={false} />
          </PageSection>
        </Grid>
        <Grid item xs={12}>
          <PageSection
            title={t("routine.challenge.inputs.occurrence.label") + "*"}
            showBottomBorder={false}
          >
            <Select
              label={t("routine.challenge.inputs.occurrence.label")}
              options={occurrenceOptions()}
              value={occurrences}
              onChange={(o) => setOccurrences(o.value)}
              truncateOnOverflow
              data-cy="challenge-edition-occurences-field"
            />
          </PageSection>
        </Grid>
        <Grid item xs={12}>
          <PageSection
            title={t("routine.challenge.inputs.reward.label") + "*"}
            showBottomBorder={false}
          >
            <Select
              label={t("routine.challenge.inputs.reward.label")}
              options={getRewardOptions()}
              value={rewardIndex}
              onChange={(o) => setRewardIndex(o.value)}
              truncateOnOverflow
            />
            <Box mt={2}>
              <TextInput
                placeholder={t("routine.challenge.inputs.reward.placeholder")}
                value={reward}
                onChange={(e) => setReward(e.currentTarget.value)}
                multiline
                fullWidth
                minRows={2}
                inputProps={{
                  maxLength: MAX_REWARD_LENGTH,
                }}
                data-cy="challenge-edition-reward-input"
              />
              <CharacterCount text={reward} maxLength={MAX_REWARD_LENGTH} />
            </Box>
          </PageSection>
        </Grid>
        <Grid item xs={12}>
          <PageSection
            title={t("routine.edit.inputs.days.labelSingle")}
            showBottomBorder={false}
          >
            <DayPicker value={days} setValue={setDays} multiple={false} />
          </PageSection>
        </Grid>
        <Grid item xs={12}>
          <PageSection
            title={resetHeader(t("routine.edit.inputs.time.label"), () => {
              setStartTime(null);
              setEndTime(null);
            })}
            showBottomBorder={false}
          >
            <TimeRangePicker
              start={startTime}
              setStart={setStartTime}
              end={endTime}
              setEnd={setEndTime}
              language={language}
            />
          </PageSection>
        </Grid>
        <Grid item xs={12}>
          <PageSection
            title={resetHeader(
              t("routine.challenge.inputs.enddate.label"),
              () => setEndDateMS(null)
            )}
            showBottomBorder={false}
          >
            <DatePicker
              label={t("routine.challenge.inputs.enddate.label")}
              date={endDateMS ? new Date(endDateMS) : null}
              onChange={(date) =>
                setEndDateMS(Tools.Time.Dates.getTimeStampMS(date))
              }
              minDate={new Date()}
              language={language}
              fullWidth
            />
          </PageSection>
        </Grid>
      </Grid>
      <Dialogs.ConfirmationDialog
        open={showCancelDialog}
        onClose={() => setShowCancelDialog(false)}
        title={t("routine.challenge.cancel.title")}
        text={t("routine.challenge.cancel.text")}
        imgSrc={DialogAssets.CancelTag}
        onPositiveAction={() => {
          setShowCancelDialog(false);
          onClose();
        }}
        onNegativeAction={() => {
          setShowCancelDialog(false);
        }}
        hideIrreversibleWarning
      />
      <StickyButtons
        disabled={!canSubmit}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
      <ChallengeSummary
        open={openConfirmation}
        onContinue={() => onContinue()}
        onDismiss={() => setOpenConfirmation(false)}
        childName={childrenById[selectedChildren[0]]?.name}
        title={title}
        days={days}
        startTime={startTime}
        endTime={endTime}
        endDateMS={endDateMS}
        occurrences={occurrences}
        canBeSummited={isChallengeAchivable}
      />
    </CloseablePage>
  ) : null;
};

export default ChallengeEdition;
