import {
  calculateStickyButtonPagePadding,
  ChildDisplay,
  DatePicker,
  FormCard,
  FullscreenPage,
  Icons,
  PageContentContainer,
  StickyButtons,
  TextInput,
} from "@neurosolutionsgroup/components";
import { GenericPageProps } from "../../genericPageProps";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, ButtonBase, Grid, Typography, useTheme } from "@mui/material";
import {
  Language,
  SideEffectById,
  TaskCategoryLoc,
} from "@neurosolutionsgroup/models";
import SubjectPicker from "./Components/SubjectPicker";

export interface NoteFormState {
  title: string;
  text: string;
  date: Date | null;
  sideEffectId: string | null;
  routineCategoryId: string | null;
}

export interface NoteFormPageProps extends GenericPageProps {
  formState: NoteFormState;
  setFormState: Dispatch<SetStateAction<NoteFormState>>;
  childName: string;
  childIconSrc: string;
  onComplete: (formState: NoteFormState) => void;
  onCancel: VoidFunction;
  language: Language;
  sideEffectsById?: SideEffectById;
  followedSideEffects: string[];
  taskCategoryLoc: TaskCategoryLoc;
  userTaskCategoryIds: string[];
  loading: boolean;
  coachImageSrc: string;
  edit?: boolean;
  onDelete?: VoidFunction;
}

const NoteFormPage = ({
  formState,
  setFormState,
  childName,
  childIconSrc,
  onComplete,
  onCancel,
  safeAreas,
  sx,
  language,
  sideEffectsById,
  followedSideEffects,
  taskCategoryLoc,
  userTaskCategoryIds,
  loading,
  coachImageSrc,
  edit = false,
  onDelete,
}: NoteFormPageProps): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();

  const formIsValid = useMemo(() => {
    const hasSubject = formState.sideEffectId || formState.routineCategoryId;

    return (
      formState.date !== null &&
      formState.title.length > 0 &&
      formState.text.length > 0 &&
      hasSubject
    );
  }, [formState]);

  // Update title on subject change.
  useEffect(() => {
    if (sideEffectsById && formState.sideEffectId) {
      const title = sideEffectsById[formState.sideEffectId]?.name[language];

      if (title) {
        setFormState((current) =>
          current.title !== title
            ? {
                ...current,
                title,
              }
            : current
        );
      }
    } else if (formState.routineCategoryId) {
      const title = taskCategoryLoc[language][formState.routineCategoryId];

      if (title) {
        setFormState((current) =>
          current.title !== title
            ? {
                ...current,
                title,
              }
            : current
        );
      }
    }
  }, [formState]);

  const onSubjectChange = (
    sideEffectId: string | null,
    routineCategoryId: string | null
  ) => {
    setFormState((current) => ({
      ...current,
      sideEffectId,
      routineCategoryId,
    }));
  };

  const onConfirm = () => {
    onComplete(formState);
  };

  return (
    <FullscreenPage sx={sx}>
      <PageContentContainer safeAreas={safeAreas} dataCy="note-creation-page">
        <Box mx={1} mt={2} mb={calculateStickyButtonPagePadding()}>
          <Grid container direction="column" flexWrap="nowrap" spacing={2}>
            <Grid
              item
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {onDelete ? <Box m={1} width="20px" /> : null}
              <Typography
                variant="h3"
                textAlign="center"
                mx="auto"
                data-cy="note-form-title"
              >
                {edit
                  ? t("journal.notes.form.editTitle")
                  : t("journal.notes.form.add.title")}
              </Typography>
              {onDelete ? (
                <Box>
                  <ButtonBase
                    onClick={onDelete}
                    sx={{
                      padding: 1,
                      borderRadius: "10px",
                    }}
                    data-cy="note-delete-btn"
                  >
                    <Icons.DeleteIcon color={theme.palette.error.main} />
                  </ButtonBase>
                </Box>
              ) : null}
            </Grid>
            <Grid item display="flex" justifyContent="center">
              <ChildDisplay
                childName={childName}
                childIconSrc={childIconSrc}
                data-cy="note-form-child-display"
              />
            </Grid>
            <Grid item>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mb={1}
              >
                <Typography>{t("journal.notes.form.date.label")}*</Typography>

                <DatePicker
                  date={formState.date}
                  onChange={(value) =>
                    setFormState((current) => ({
                      ...current,
                      date: value,
                    }))
                  }
                  language={language}
                  disableFuture
                />
              </Box>
            </Grid>
            <Grid item>
              <FormCard title={t("journal.notes.form.details.title")}>
                <Box p={1}>
                  <Typography mb={1}>
                    {t("journal.notes.form.subject.label")}*
                  </Typography>
                  <SubjectPicker
                    initialSideEffectId={formState.sideEffectId}
                    initialRoutineCategoryId={formState.routineCategoryId}
                    onSubjectChange={onSubjectChange}
                    label={t("journal.notes.form.subject.title")}
                    language={language}
                    activeFollowedSideEffects={followedSideEffects}
                    taskCategoryLoc={taskCategoryLoc}
                    userTaskCategories={userTaskCategoryIds}
                    fullWidth
                    safeAreas={safeAreas}
                    sideEffectById={sideEffectsById ?? {}}
                  />

                  <Typography my={1}>
                    {t("journal.notes.form.text.label")}*
                  </Typography>
                  <TextInput
                    value={formState.text}
                    placeholder={t("journal.notes.form.text.placeholder")}
                    onChange={(e) =>
                      setFormState((current) => ({
                        ...current,
                        text: e.target.value,
                      }))
                    }
                    multiline
                    minRows={2}
                    fullWidth
                    data-cy="note-text-input"
                  />
                </Box>
              </FormCard>
            </Grid>
          </Grid>
        </Box>
      </PageContentContainer>
      <StickyButtons
        onConfirm={onConfirm}
        disabled={!formIsValid}
        cancelText={t("general.actions.cancel")}
        onCancel={onCancel}
        loading={loading}
        data-cy="note-creation-confirm"
      />
    </FullscreenPage>
  );
};

export default NoteFormPage;
