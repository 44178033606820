import {
  Box,
  Button,
  Divider,
  drawerClasses,
  Grid,
  Radio,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import {
  ControlledDrawer,
  Filter,
  SelectInputButton,
} from "@neurosolutionsgroup/components";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Language,
  SafeAreas,
  SideEffectById,
  TaskCategoryLoc,
} from "@neurosolutionsgroup/models";

export interface SubjectPickerProps {
  userTaskCategories: string[];
  taskCategoryLoc: TaskCategoryLoc;
  sideEffectById: SideEffectById;
  activeFollowedSideEffects: string[];
  initialSideEffectId: string | null;
  initialRoutineCategoryId: string | null;
  onSubjectChange: (
    sideEffectId: string | null,
    routineCategoryId: string | null
  ) => void;
  label: string;
  language: Language;
  safeAreas: SafeAreas;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
}

const enum FilterValue {
  Mine,
  SideEffects,
  RoutineCategories,
}

const SubjectPicker = ({
  userTaskCategories,
  taskCategoryLoc,
  sideEffectById,
  activeFollowedSideEffects,
  initialSideEffectId,
  initialRoutineCategoryId,
  onSubjectChange,
  label,
  language,
  safeAreas,
  fullWidth,
  sx,
}: SubjectPickerProps): JSX.Element => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const [filter, setFilter] = useState<FilterValue[]>([FilterValue.Mine]);

  const [sideEffectId, setSideEffectId] = useState<string | null>(null);
  const [routineCategoryId, setRoutineCategoryId] = useState<string | null>(
    null
  );

  useEffect(() => {
    setSideEffectId(initialSideEffectId);
  }, [initialSideEffectId]);

  useEffect(() => {
    setRoutineCategoryId(initialRoutineCategoryId);
  }, [initialRoutineCategoryId]);

  const setSideEffectValue = (value: string, checked: boolean) => {
    if (checked) {
      setSideEffectId(value);
      setRoutineCategoryId(null);
    } else {
      setSideEffectId(null);
    }
  };

  const setRoutineValue = (value: string, checked: boolean) => {
    if (checked) {
      setRoutineCategoryId(value);
      setSideEffectId(null);
    } else {
      setRoutineCategoryId(null);
    }
  };

  const onConfirm = () => {
    if (sideEffectId) {
      onSubjectChange(sideEffectId, null);
    } else if (routineCategoryId) {
      onSubjectChange(null, routineCategoryId);
    } else {
      onSubjectChange(null, null);
    }

    setOpen(false);
  };

  const value: string | null = useMemo(() => {
    if (initialSideEffectId && sideEffectById) {
      return sideEffectById[initialSideEffectId].name[language];
    } else if (initialRoutineCategoryId) {
      return taskCategoryLoc[language][initialRoutineCategoryId];
    }
    return t("journal.notes.form.subject.placeholder");
  }, [initialSideEffectId, initialRoutineCategoryId, sideEffectById, language]);

  const mySideEffectOptions: string[] = useMemo(() => {
    const sideEffects: string[] = [];

    activeFollowedSideEffects.forEach((fse) => {
      sideEffects.push(fse);
    });

    return sideEffects;
  }, [activeFollowedSideEffects]);

  const sideEffectOptions: string[] = useMemo(() => {
    return sideEffectById ? Object.keys(sideEffectById) : [];
  }, [sideEffectById]);

  const routineCategoryOptions: string[] = Object.keys(
    taskCategoryLoc[language]
  );

  return (
    <>
      <SelectInputButton
        onClick={() => setOpen(true)}
        color="secondary"
        fullWidth={fullWidth}
        data-cy={"subject-picker-button"}
      >
        {value}
      </SelectInputButton>
      <ControlledDrawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          [`& .${drawerClasses.paper}`]: {
            maxHeight: "calc(90vh)",
          },
        }}
        safeAreas={safeAreas}
      >
        <Box p={2}>
          <Typography variant="h4" textAlign="center">
            {label}
          </Typography>
        </Box>
        <Box my={1}>
          <Divider />
        </Box>
        <Box>
          <Filter
            options={[
              {
                id: FilterValue.Mine,
                label: t("journal.notes.form.subject.filter.mine"),
                count: mySideEffectOptions.length + userTaskCategories.length,
              },
              {
                id: FilterValue.SideEffects,
                label: t("journal.notes.form.subject.filter.symptoms"),
                count: sideEffectOptions.length,
              },
              {
                id: FilterValue.RoutineCategories,
                label: t("journal.notes.form.subject.filter.routineCategory"),
                count: routineCategoryOptions.length,
              },
            ]}
            value={filter}
            onChange={setFilter}
            single
            sx={{
              width: "calc(100vw + 1em)",
            }}
          />
        </Box>
        {filter.includes(FilterValue.Mine) && (
          <Grid
            container
            spacing={1}
            sx={{
              overflowY: "auto",
            }}
          >
            <Grid item xs={12}>
              <Box py={1} px={2}>
                <Typography variant="h4">
                  {t("journal.notes.form.subject.filter.symptoms")}
                </Typography>
              </Box>
            </Grid>
            {mySideEffectOptions.map((seid) => (
              <Grid item xs={12} key={seid}>
                <Box px={2} display="flex" alignItems="center">
                  <Typography
                    flexGrow={10}
                    sx={{
                      label: {
                        display: "block",
                        width: "100%",
                      },
                    }}
                  >
                    <label htmlFor={`sideeffect-check-${seid}`}>
                      {sideEffectById && sideEffectById[seid].name[language]}
                    </label>
                  </Typography>
                  <Radio
                    id={`sideeffect-check-${seid}`}
                    value={seid}
                    checked={sideEffectId === seid}
                    color="secondary"
                    onChange={(e) => setSideEffectValue(seid, e.target.checked)}
                  />
                </Box>
                <Divider />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Box py={1} px={2}>
                <Typography variant="h4">
                  {t("journal.notes.form.subject.filter.routineCategory")}
                </Typography>
              </Box>
            </Grid>
            {userTaskCategories.map((category) => (
              <Grid item xs={12} key={category}>
                <Box px={2} display="flex" alignItems="center">
                  <Typography
                    flexGrow={10}
                    sx={{
                      label: {
                        display: "block",
                        width: "100%",
                      },
                    }}
                  >
                    <label htmlFor={`category-check-${category}`}>
                      {taskCategoryLoc[language][category]}
                    </label>
                  </Typography>
                  <Radio
                    id={`category-check-${category}`}
                    value={category}
                    color="secondary"
                    checked={routineCategoryId === category}
                    onChange={(e) =>
                      setRoutineValue(category, e.target.checked)
                    }
                  />
                </Box>
                <Divider />
              </Grid>
            ))}
          </Grid>
        )}
        {filter.includes(FilterValue.SideEffects) && (
          <Grid
            container
            spacing={1}
            sx={{
              overflowY: "auto",
            }}
          >
            <Grid item xs={12}>
              <Box py={1} px={2}>
                <Typography variant="h4">
                  {t("journal.notes.form.subject.filter.symptoms")}
                </Typography>
              </Box>
            </Grid>
            {sideEffectOptions.map((seid) => (
              <Grid item xs={12} key={seid}>
                <Box px={2} display="flex" alignItems="center">
                  <Typography
                    flexGrow={10}
                    sx={{
                      label: {
                        display: "block",
                        width: "100%",
                      },
                    }}
                  >
                    <label htmlFor={`sideeffect-check-${seid}`}>
                      {sideEffectById && sideEffectById[seid].name[language]}
                    </label>
                  </Typography>
                  <Radio
                    id={`sideeffect-check-${seid}`}
                    value={seid}
                    color="secondary"
                    onChange={(e) => setSideEffectValue(seid, e.target.checked)}
                    checked={sideEffectId === seid}
                  />
                </Box>
                <Divider />
              </Grid>
            ))}
          </Grid>
        )}
        {filter.includes(FilterValue.RoutineCategories) && (
          <Grid
            container
            spacing={1}
            sx={{
              overflowY: "auto",
            }}
          >
            <Grid item xs={12}>
              <Box py={1} px={2}>
                <Typography variant="h4">
                  {t("journal.notes.form.subject.filter.routineCategory")}
                </Typography>
              </Box>
            </Grid>
            {routineCategoryOptions.map((category) => (
              <Grid item xs={12} key={category}>
                <Box px={2} display="flex" alignItems="center">
                  <Typography
                    flexGrow={10}
                    sx={{
                      label: {
                        display: "block",
                        width: "100%",
                      },
                    }}
                  >
                    <label htmlFor={`category-check-${category}`}>
                      {taskCategoryLoc[language][category]}
                    </label>
                  </Typography>
                  <Radio
                    id={`category-check-${category}`}
                    value={category}
                    color="secondary"
                    onChange={(e) =>
                      setRoutineValue(category, e.target.checked)
                    }
                    checked={routineCategoryId === category}
                  />
                </Box>

                <Divider />
              </Grid>
            ))}
          </Grid>
        )}
        <Box mt="auto" px={2} py={3} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="secondary"
            onClick={onConfirm}
            data-cy="subject-picker-confirm"
          >
            {t("general.actions.confirm")}
          </Button>
        </Box>
      </ControlledDrawer>
    </>
  );
};

export default SubjectPicker;
