import AlertDialog from "./AlertDialog";
import ConfirmationDialog from "./ConfirmDialog";
import InputErrorDialog from "./InputErrorDialog";

const Dialogs = {
  AlertDialog,
  ConfirmationDialog,
  InputErrorDialog,
};

export default Dialogs;
