import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  ButtonBase,
  drawerClasses,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import ControlledDrawer from "../../ControlledDrawer/ControlledDrawer";
import TextInput from "../TextInput/TextInput";
import { Filter } from "../../Filter";
import { calculateStickyButtonPagePadding, StickyButtons } from "../../Button";
import Icons from "../../Icons";
import { CypressProps, SafeAreas } from "@neurosolutionsgroup/models";

export interface SearchableSelectOption {
  value: string;
  label?: string | JSX.Element;
  display?: string | JSX.Element;
}

export interface SearchableSelectCategory {
  id: string;
  label: string;
  icon?: (color: string) => JSX.Element;
  options: SearchableSelectOption[];
}

export interface SearchableSelectInputProps extends CypressProps {
  value: string | null;
  onChange: (option: string | null) => void;
  categories: SearchableSelectCategory[];
  label: string;
  safeAreas: SafeAreas;
  fullWidth?: boolean;
  placeholder?: string | JSX.Element;
  searchPlaceholder?: string;
  sx?: SxProps<Theme>;
  variant?: "text" | "outlined" | "contained";
  truncateOnOverflow?: boolean;
  hideCancelButton?: boolean;
}

const SearchableSelectInput = ({
  value,
  onChange,
  categories,
  label,
  safeAreas,
  fullWidth = false,
  placeholder,
  searchPlaceholder,
  sx,
  variant = "text",
  truncateOnOverflow = true,
  hideCancelButton = false,
  ...props
}: SearchableSelectInputProps): JSX.Element => {
  const { palette } = useTheme();

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [filterValue, setFilterValue] = useState(
    categories.length > 0 ? [categories[0].id] : []
  );
  const [tempValue, setTempValue] = useState<string | null>(null);

  useEffect(() => {
    setTempValue(value);
  }, [value]);

  useEffect(() => {
    setFilterValue(categories.length > 0 ? [categories[0].id] : []);
  }, [categories]);

  const allOptions: SearchableSelectOption[] = categories.reduce(
    (acc, current) => [...acc, ...current.options],
    [] as SearchableSelectOption[]
  );

  const getValueLabel = useCallback((): string | JSX.Element | null => {
    if (value === null) {
      return placeholder ?? null;
    }

    const option = allOptions.find((o) => value === o.value);

    if (!option) {
      return placeholder ?? null;
    }

    return option.display ?? option.label ?? `${option.value}`;
  }, [allOptions, value]);

  const displayOptions = useMemo(() => {
    const categoryOptions = categories.flatMap((cat) =>
      filterValue.includes(cat.id) ? cat.options : []
    );

    if (search.length === 0) {
      return categoryOptions;
    }

    return categoryOptions.filter((option) =>
      (option.label?.toString() ?? option.value?.toString() ?? "")
        .toLowerCase()
        .includes(search.toLowerCase())
    );
  }, [categories, filterValue, search]);

  const onOptionClick = (option: string) => {
    setTempValue(option);
  };

  const onConfirm = () => {
    onChange(tempValue);
    setOpen(false);
  };

  const onCancel = () => {
    setTempValue(value);
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        color="secondary"
        variant={variant}
        fullWidth={fullWidth}
        data-cy={props["data-cy"]}
        sx={[
          {
            "justifyContent": "space-between",
            "paddingY": 0.5,
            "paddingX": "0.5rem",
            "& .icon-arrow": {
              width: "1rem",
              height: "1rem",
            },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <Box
          mx="auto"
          pr={1}
          sx={
            truncateOnOverflow
              ? {
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }
              : undefined
          }
        >
          {getValueLabel()}
        </Box>
        <Icons.ArrowIcon arrowDirection="down" color={palette.secondary.main} />
      </Button>
      <ControlledDrawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          [`& .${drawerClasses.paper}`]: {
            maxHeight: "calc(90vh)",
          },
        }}
        safeAreas={safeAreas}
      >
        <Box mt={3} mb={1} mx={2}>
          <Typography variant="h3" textAlign="left">
            {label}
          </Typography>
        </Box>
        <Box p={1}>
          <TextInput
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
            placeholder={searchPlaceholder}
            InputProps={{
              startAdornment: <Icons.SearchIcon color="#c4c4c4" />,
              endAdornment: (
                <ButtonBase
                  onClick={() => setSearch("")}
                  sx={{ padding: 0.5, borderRadius: "5px" }}
                >
                  <Icons.CloseIcon
                    color={
                      search.length > 0 ? palette.secondary.main : "#c4c4c4"
                    }
                  />
                </ButtonBase>
              ),
            }}
            fullWidth
          />
        </Box>
        <Box>
          <Filter
            options={categories.map((cat) => ({
              id: cat.id,
              label: cat.label,
              icon: cat.icon,
            }))}
            value={filterValue}
            onChange={setFilterValue}
            single
          />
        </Box>
        <Box px={1.5} pb={calculateStickyButtonPagePadding()}>
          <FormControl fullWidth>
            <RadioGroup
              value={tempValue}
              onChange={(e) => onOptionClick(e.target.value)}
            >
              {displayOptions.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio color="secondary" />}
                  label={option.label}
                  labelPlacement="start"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "2px solid #F0EAE3",
                    marginLeft: 0,
                    marginRight: 0,
                    width: "100%",
                  }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
        <StickyButtons
          onConfirm={onConfirm}
          onCancel={hideCancelButton ? undefined : onCancel}
          safeAreas={safeAreas}
        />
      </ControlledDrawer>
    </>
  );
};

export default SearchableSelectInput;
