import clsx from "clsx";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CypressProps, SafeAreas } from "@neurosolutionsgroup/models";
import StickyDrawer from "../../StickyDrawer/StickyDrawer";

interface StickyButtonsProps extends CypressProps {
  onCancel?: VoidFunction;
  onConfirm: VoidFunction;
  disabled?: boolean;
  confirmText?: string;
  cancelText?: string;
  fullWidth?: boolean;
  confirmColor?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
  cancelColor?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
  confirmVariant?: "text" | "contained" | "outlined";
  cancelVariant?: "text" | "contained" | "outlined" | "blank";
  vertical?: boolean;
  loading?: boolean;
  safeAreas?: SafeAreas;
}
/**
 *
 * @param safeAreas Should only be ignore if it's manage by a parent component
 * @returns
 */
export const calculateStickyButtonPagePadding = (
  safeAreas?: SafeAreas,
  vertical = false
) => {
  const baseHeight = vertical ? "100px" : "60px";

  return safeAreas ? `calc(${baseHeight} + ${safeAreas.bottom})` : baseHeight;
};

const StickyButtons = ({
  onCancel,
  onConfirm,
  disabled = false,
  confirmText,
  cancelText,
  fullWidth = false,
  confirmColor = "secondary",
  cancelColor = "primary",
  confirmVariant = "contained",
  cancelVariant = "text",
  vertical = false,
  loading,
  ...props
}: StickyButtonsProps): JSX.Element => {
  const rootClass = "parent-sticky-buttons";

  const { t } = useTranslation();

  return (
    <StickyDrawer safeAreas={props.safeAreas ?? null}>
      <Box
        className={rootClass}
        sx={{
          padding: 1,
        }}
      >
        <Grid
          container
          flexDirection={vertical ? "row" : "row-reverse"}
          alignItems="center"
          justifyContent="center"
          spacing={vertical ? 1 : 2}
        >
          <Grid
            item
            flexGrow={!onCancel && fullWidth ? 1 : undefined}
            xs={vertical ? 12 : undefined}
          >
            <Button
              id="routine-confirm-button"
              data-cy={
                props["data-cy"]
                  ? props["data-cy"] + "-confirm"
                  : "confirm-form-button"
              }
              color={confirmColor}
              variant={confirmVariant}
              onClick={onConfirm}
              disabled={disabled}
              className={clsx(
                rootClass + "__button",
                rootClass + "__button--positive"
              )}
              fullWidth={(!onCancel && fullWidth) || vertical}
              sx={{
                minWidth: "30vw",
              }}
            >
              {loading ? (
                <CircularProgress
                  size="1.5rem"
                  style={{
                    color: "#fff",
                  }}
                />
              ) : (
                confirmText ?? t("general.actions.confirm")
              )}
            </Button>
          </Grid>
          {onCancel && (
            <Grid item xs={vertical ? 12 : undefined}>
              <Button
                data-cy={
                  props["data-cy"]
                    ? props["data-cy"] + "-cancel"
                    : "cancel-form-button"
                }
                color={cancelColor}
                variant={cancelVariant !== "blank" ? cancelVariant : "text"}
                sx={[
                  { minWidth: "30vw" },
                  ...(cancelVariant === "blank"
                    ? [
                        {
                          "boxShadow": "none",
                          "background": "none",
                          "&:hover": {
                            background: "none",
                          },
                        },
                      ]
                    : []),
                ]}
                onClick={onCancel}
                classes={{
                  root: clsx(
                    rootClass + "__button",
                    rootClass + "__button--negative"
                  ),
                }}
                fullWidth={vertical}
              >
                {cancelText ?? t("general.actions.cancel")}
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </StickyDrawer>
  );
};

export default StickyButtons;
