import {
  ChallengeCreationPage,
  ChallengeCreationValues,
} from "@neurosolutionsgroup/webviews-pages";
import ChildIcons from "assets/child-icons";
import useChildren from "common/hooks/children/useChildren";
import React, { useEffect, useMemo, useState } from "react";
import {
  autonomyIconsOrder,
  familyIconsOrder,
  healthIconsOrder,
  householdIconsOrder,
  hygieneIconsOrder,
  instructionsIconsOrder,
  tidyingIconsOrder,
} from "../RoutineEdition/TaskEdition/IconPicker/IconOrders";
import TaskAssets from "assets/task-icons";
import useLanguage from "common/hooks/Parameters/useLanguage";
import { SAFE_AREAS } from "stylesheets";
import CoachAssets from "assets/coach";
import DialogAssets from "assets/dialogs";
import useChallenges from "common/hooks/challenges/useChallenges";
import { Tools } from "@neurosolutionsgroup/tools";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import useUserProfile from "common/hooks/account/useUserProfile";

interface ChallengeCreationProps {
  open: boolean;
  onClose: VoidFunction;
}

const ChallengeCreation = ({
  open,
  onClose,
}: ChallengeCreationProps): JSX.Element | null => {
  const {
    actions: { createChallenge },
    selectors: { challenges, loading },
  } = useChallenges();
  const {
    selectors: {
      childIds,
      childrenById,
      medicalChildrenByGamerId,
      selectedChild,
    },
  } = useChildren();
  const {
    selectors: { userProfile },
  } = useUserProfile();
  const { handleUnknownError } = useErrorsContext();
  const { dateLocale, language } = useLanguage();

  const childList = Object.values(childrenById);

  const [challengeChildId, setChallengeChildId] = useState<string | null>(
    selectedChild
  );

  const childSkillIds = useMemo(() => {
    if (challengeChildId && medicalChildrenByGamerId[challengeChildId]) {
      return medicalChildrenByGamerId[challengeChildId].needs ?? [];
    } else {
      return [];
    }
  }, [challengeChildId]);

  const disabledChildIds = useMemo(() => {
    return childIds.filter((childId) =>
      challenges.some(
        (challenge) => challenge.childId === childId && !challenge.createdById
      )
    );
  }, [childIds, challenges]);

  useEffect(() => {
    if (challengeChildId && disabledChildIds.includes(challengeChildId)) {
      for (const childId of childIds) {
        const isChildDisabled = disabledChildIds.includes(childId);

        if (!isChildDisabled) {
          setChallengeChildId(childId);

          break;
        }
      }
    }
  }, [challengeChildId, disabledChildIds]);

  const orderedTaskIcons = autonomyIconsOrder.concat(
    hygieneIconsOrder,
    instructionsIconsOrder,
    householdIconsOrder,
    tidyingIconsOrder,
    healthIconsOrder,
    familyIconsOrder
  );

  const resetForm = () => {
    setChallengeChildId(selectedChild);
  };

  const onCancel = () => {
    resetForm();

    onClose();
  };

  const onConfirm = async (
    values: ChallengeCreationValues,
    customReward: boolean
  ) => {
    if (!challengeChildId) {
      return false;
    }

    const createdDate = Tools.Time.Dates.getTimeStamp();

    const startDate = Tools.Data.Challenges.V2.calculateChallengeStartDate(
      createdDate,
      values.startThisWeek
    );

    try {
      await createChallenge(
        {
          childId: challengeChildId,
          createdDate,
          startDate,
          version: 2,
          skillId: values.skillId,
          title: values.title,
          icon: values.icon,
          reward: {
            text: values.reward,
          },
          frequency: values.frequency,
          duration: values.duration,
          description:
            values.description.length > 0 ? values.description : null,
          days: values.days ?? null,
          startTime: values.startTime,
          endTime: values.endTime,
        },
        userProfile ? userProfile.isFreeTrial : null,
        customReward
      );

      resetForm();

      onClose();

      return true;
    } catch (err) {
      handleUnknownError(err);

      return false;
    }
  };

  return (
    <ChallengeCreationPage
      challengeChildId={challengeChildId}
      setChallengeChildId={setChallengeChildId}
      childList={childList}
      childIcons={ChildIcons}
      disabledChildIds={disabledChildIds}
      mySkillOptions={childSkillIds}
      orderedTaskIcons={orderedTaskIcons}
      taskIconAssets={TaskAssets.TaskIcons}
      onCancel={onCancel}
      onCreate={onConfirm}
      open={open}
      dateLocale={dateLocale}
      language={language}
      loading={loading}
      coachErrorImgSrc={CoachAssets.CoachError}
      coachInfoIconImgSrc={CoachAssets.CoachInfoHead}
      coachCancelImgSrc={DialogAssets.CancelTag}
      daysInfoImgSrc={DialogAssets.ChallengeInfoDays}
      frequencyInfoImgSrc={DialogAssets.ChallengeInfoFrequency}
      rewardInfoImgSrc={DialogAssets.ChallengeInfoReward}
      skillInfoImgSrc={DialogAssets.ChallengeInfoSkills}
      summaryImgSrc={CoachAssets.CoachConfirm}
      safeAreas={SAFE_AREAS}
    />
  );
};

export default ChallengeCreation;
