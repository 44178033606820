import { CompleteAccountInfo } from "@neurosolutionsgroup/models";
import Tools from "@neurosolutionsgroup/tools";
import useLanguage from "common/hooks/Parameters/useLanguage";
import FirebaseAPI from "@neurosolutionsgroup/api-client";
import { CoachNotificationRecord } from "common/Services/Coach";
import React, {
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import useAuth from "../auth/useAuth";
import useParameters from "../Parameters/useParameters";
import { useAppInitializationContext } from "../AppInitializationContext";
import { useAnalytics } from "@neurosolutionsgroup/analytics";

interface CoachNotifications {
  coachNotifications: CoachNotificationRecord[];
}

export interface UserProfileContextData {
  loading: boolean;
  setLoading: React.Dispatch<SetStateAction<boolean>>;
  userProfile: CompleteAccountInfo | undefined;
  setUserProfile: React.Dispatch<
    SetStateAction<CompleteAccountInfo | undefined>
  >;
  coachNotifications: CoachNotificationRecord[];
  setCoachNotifications: React.Dispatch<
    SetStateAction<CoachNotificationRecord[]>
  >;
  profileInitializationComplete: boolean;
  refreshUserProfile: () => Promise<void>;
}

const [useProfileContext, ProfileContextProvider] =
  Tools.Context.createGenericContext<UserProfileContextData>(__filename);

const UserProfileProvider: React.FC<PropsWithChildren> = (props) => {
  const [loading, setLoading] = useState(false);
  const [userProfile, setUserProfile] = useState<CompleteAccountInfo>();
  const [coachNotifications, setCoachNotifications] = useState<
    CoachNotificationRecord[]
  >([]);
  const [profileInitializationComplete, setProfileInitializationComplete] =
    useState<boolean>(false);

  const { setLanguage } = useLanguage();
  const { addLoadingProgress } = useParameters();
  const { user } = useAuth();
  const { userInitiated } = useAppInitializationContext();
  const {
    functions: { setProfileProperties },
  } = useAnalytics();

  const refreshUserProfile = async (): Promise<void> => {
    FirebaseAPI.Account.getUserInfo().then((userInfo) => {
      setUserProfile(userInfo);
      if (userInfo?.masterSettings?.settings.language) {
        setLanguage(
          Tools.Language.settingsLanguageToLanguageCode(
            userInfo?.masterSettings?.settings.language as string
          )
        );
      }

      if (
        userInfo?.masterSettings?.settings?.CoachNotifications &&
        typeof userInfo.masterSettings.settings.CoachNotifications === "string"
      ) {
        const parsedCoachNotifications = JSON.parse(
          userInfo.masterSettings.settings.CoachNotifications
        ) as CoachNotifications;

        setCoachNotifications(parsedCoachNotifications.coachNotifications);
      }
    });
  };

  useEffect(() => {
    if (userInitiated) {
      refreshUserProfile().then(() => {
        setProfileInitializationComplete(true);
        addLoadingProgress(20);
      });
    }
  }, [userInitiated]);

  useEffect(() => {
    if (!user) {
      setLoading(false);
      setUserProfile(undefined);
      setCoachNotifications([]);
      setProfileInitializationComplete(false);
    }
  }, [user]);

  useEffect(() => {
    if (
      userProfile?.masterSettings?.settings?.CoachNotifications &&
      typeof userProfile.masterSettings.settings.CoachNotifications === "string"
    ) {
      const parsedCoachNotifications = JSON.parse(
        userProfile.masterSettings.settings.CoachNotifications
      ) as CoachNotifications;

      setCoachNotifications(parsedCoachNotifications.coachNotifications);
    }

    if (userProfile?.firstName) {
      setProfileProperties({
        name: userProfile.firstName,
      });
    }
  }, [userProfile]);

  return (
    <ProfileContextProvider
      value={{
        loading,
        setLoading,
        userProfile,
        setUserProfile,
        profileInitializationComplete,
        coachNotifications,
        setCoachNotifications,
        refreshUserProfile,
      }}
    >
      {props.children}
    </ProfileContextProvider>
  );
};

export { useProfileContext, UserProfileProvider };
