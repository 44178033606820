import React from "react";
import { useTranslation } from "react-i18next";

import CloseablePage from "common/Components/CloseablePage/CloseablePage";
import useParameters from "common/hooks/Parameters/useParameters";

import { About } from "./About/About";
import { AccountSettings } from "./AccountSettings/AccountSettings";
import { GeneralSettings } from "./GeneralSettings/GeneralSettings";
import { NotificationSettings } from "./NotificationSettings/NotificationSettings";
import { SettingsChildSelector } from "./Child/SettingsChildSelector/SettingsChildSelector";
import useNavigation from "common/hooks/navigation/useNavigation";
import useAuth from "common/hooks/auth/useAuth";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SAFE_AREA_BOTTOM } from "stylesheets";
import Tools from "@neurosolutionsgroup/tools";

const Settings: React.FC = () => {
  const { t } = useTranslation();

  const {
    actions: { setMenuPageOpen },
  } = useNavigation();
  const navigate = useNavigate();

  const { version, buildNumber } = useParameters();

  const { logOut } = useAuth();

  const onLogOut = async () => {
    await logOut();
  };

  return (
    <CloseablePage
      color="primary"
      className="settings__page"
      header={<h2 className="settings__title">{t("settings.settings")}</h2>}
      isOpen={true}
      onClose={() => {
        setMenuPageOpen(true);
        navigate("/dashboard");
      }}
    >
      <Box mb={SAFE_AREA_BOTTOM} pb={4}>
        <div className="settings__body">
          <GeneralSettings />
          <SettingsChildSelector />
          <AccountSettings />
          <NotificationSettings />
          <About />
          <Button
            onClick={onLogOut}
            sx={(theme) => ({
              border: "2px solid " + theme.palette.error.main,
              backgroundColor: "#fff",
              marginY: theme.spacing(1),
            })}
            fullWidth
          >
            {t("general.actions.logout")}
          </Button>

          <div className="settings__version">
            {t("settings.version", {
              version: version ?? "unknown",
              build: buildNumber ?? "unknown",
              webBuild: `#${
                Tools.Environment.webviewsEnvTools.getBuildNumber() ?? "unknown"
              }`,
            })}
          </div>
        </div>
      </Box>
    </CloseablePage>
  );
};

export default Settings;
