import { useEffect, useState } from "react";
import ControlledDrawer from "../ControlledDrawer/ControlledDrawer";
import { FilterOptionDef } from "./FilterOptionDef";
import {
  FilterList as FilterListIcon,
  Refresh as RefreshIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Filter from "./Filter";
import FilterTag from "./FilterTag";
import { calculateStickyButtonPagePadding, StickyButtons } from "../Button";
import { SafeAreas } from "@neurosolutionsgroup/models";

export interface FilterCategory<T> {
  label: string;
  options: FilterOptionDef<T>[];
}

export interface DrawerFilterProps<T extends number | string> {
  options: FilterCategory<T>[];
  value: T[];
  onChange: (filters: T[]) => void;
  safeAreas: SafeAreas;
  btnText?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

const DrawerFilter = <T extends number | string>({
  options,
  value,
  onChange,
  safeAreas,
  btnText,
  disabled = false,
  sx,
}: DrawerFilterProps<T>): JSX.Element => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [filterValues, setFilterValues] = useState<T[]>([]);

  useEffect(() => {
    setFilterValues(value);
  }, [value]);

  const onCancel = () => {
    setOpen(false);
    setFilterValues(value);
  };

  const onConfirm = () => {
    onChange(filterValues);
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        color="secondary"
        variant={value.length > 0 ? "contained" : "text"}
        disabled={disabled}
        sx={[
          {
            display: "flex",
            justifyContent: "space-between",
            paddingRight: value.length > 0 ? 1 : undefined,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <FilterListIcon />
        &nbsp;
        {btnText ?? t("general.filter.filter")}
        {value.length > 0 ? (
          <FilterTag count={value.length} active={value.length > 0} />
        ) : null}
      </Button>
      <ControlledDrawer
        open={open}
        onClose={() => setOpen(false)}
        safeAreas={safeAreas}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={1}
          mt={1}
        >
          <Box display="flex" alignItems="center">
            <FilterListIcon />
            &nbsp;
            <Typography color="#000" fontWeight="bold">
              {t("general.filter.filters")}
            </Typography>
            {filterValues.length > 0 ? (
              <FilterTag
                count={filterValues.length}
                active={false}
                color="#000"
              />
            ) : null}
          </Box>
          <ButtonBase
            onClick={() => setFilterValues([])}
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "0.9rem",
              opacity: filterValues.length > 0 ? 1 : 0.6,
            }}
          >
            <RefreshIcon color="secondary" />
            &nbsp;
            <Typography fontSize="inherit">
              {t("general.actions.reset")}
            </Typography>
          </ButtonBase>
        </Box>
        {options.map((category, i) => (
          <Box key={i}>
            <Typography mx={1} color="#000">
              {category.label}
            </Typography>
            <Filter
              value={filterValues}
              options={category.options}
              onChange={setFilterValues}
              sx={{
                marginLeft: 0,
                paddingTop: 1,
                paddingLeft: 0,
              }}
            />
          </Box>
        ))}
        <Box paddingBottom={calculateStickyButtonPagePadding()} />
        <Box>
          <StickyButtons onConfirm={onConfirm} onCancel={onCancel} />
        </Box>
      </ControlledDrawer>
    </>
  );
};

export default DrawerFilter;
