import { Box, dialogClasses, Typography } from "@mui/material";
import { Dialogs } from "@neurosolutionsgroup/components";
import {
  LAST_IN_APP_REVIEW_30_DAYS_DATE_KEY,
  LAST_IN_APP_REVIEW_DATE_KEY,
  STOP_SHOWING_IN_APP_REVIEW_30_DAYS_KEY,
  STOP_SHOWING_IN_APP_REVIEW_FLAG,
} from "@neurosolutionsgroup/models";
import DynamismAssets from "assets/dynamism";
import useUserProfile from "common/hooks/account/useUserProfile";
import useAuth from "common/hooks/auth/useAuth";
import useParameters from "common/hooks/Parameters/useParameters";
import ZendeskService from "common/Services/Zendesk";
import { getUnixTime } from "date-fns";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import NPSRatingDialog from "./Components/NPSRatingDialog";
import NPSCommentDialog from "./Components/NPSCommentDialog";
import Tools from "@neurosolutionsgroup/tools";
import { UniWebViewActions } from "common/hooks/Parameters/UniWebViewActions";
import { useNPSContext } from "./NPSContext";

const NPSController: React.FC = () => {
  const {
    shouldShowInAppReview,
    debugInAppReview,
    shouldShowPurchaseReview,
    debugPurchaseReview,
    setShouldShowInAppReview,
    setDebugInAppReview,
    setShouldShowPurchaseReview,
    setDebugPurchaseReview,
  } = useNPSContext();

  const {
    selectors: { userProfile },
    actions: { updateMasterSettingsKey },
  } = useUserProfile();
  const { user } = useAuth();

  const { sendMessageToUnity } = useParameters();

  const [showRatingThankYouDialog, setShowRatingThankYouDialog] =
    useState(false);
  const [showCommentDialog, setShowCommentDialog] = useState(false);
  const [showCommentThankYouDialog, setShowCommentThankYouDialog] =
    useState(false);

  const [isPurchaseFeedback, setIsPurchaseFeedback] = useState(false);

  const { t } = useTranslation();

  const onInAppRatingSubmit = (rating: number) => {
    setDebugInAppReview(false);
    setShouldShowInAppReview(false);

    updateMasterSettingsKey(STOP_SHOWING_IN_APP_REVIEW_FLAG, true);

    if (rating > 7) {
      setShowRatingThankYouDialog(true);
    } else {
      setShowCommentDialog(true);
    }
  };

  const onInAppSkip = () => {
    if (userProfile?.masterSettings?.settings[LAST_IN_APP_REVIEW_DATE_KEY]) {
      updateMasterSettingsKey(STOP_SHOWING_IN_APP_REVIEW_FLAG, true);
    } else {
      updateMasterSettingsKey(
        LAST_IN_APP_REVIEW_DATE_KEY,
        getUnixTime(new Date())
      );
    }

    setDebugInAppReview(false);
    setShouldShowInAppReview(false);
  };

  const onPurchaseRatingSubmit = (rating: number) => {
    setDebugPurchaseReview(false);
    setShouldShowPurchaseReview(false);

    updateMasterSettingsKey(STOP_SHOWING_IN_APP_REVIEW_30_DAYS_KEY, true);

    if (rating > 7) {
      setShowRatingThankYouDialog(true);
    } else {
      setShowCommentDialog(true);
    }
  };

  const onPurchaseSkip = () => {
    if (
      userProfile?.masterSettings?.settings[LAST_IN_APP_REVIEW_30_DAYS_DATE_KEY]
    ) {
      updateMasterSettingsKey(STOP_SHOWING_IN_APP_REVIEW_30_DAYS_KEY, true);
    } else {
      updateMasterSettingsKey(
        LAST_IN_APP_REVIEW_30_DAYS_DATE_KEY,
        getUnixTime(new Date())
      );
    }

    setIsPurchaseFeedback(true);
    setDebugPurchaseReview(false);
    setShouldShowPurchaseReview(false);
  };

  const onThankYouContinue = () => {
    setShowRatingThankYouDialog(false);
    sendMessageToUnity(UniWebViewActions.NativeReview);
  };

  const onCommentSubmit = (comment: string) => {
    if (user) {
      ZendeskService.createTicket(
        comment,
        isPurchaseFeedback,
        userProfile?.email,
        userProfile?.firstName +
          (userProfile?.lastName ? " " + userProfile?.lastName : ""),
        Tools.Environment.isStaging()
      );
    }

    setIsPurchaseFeedback(false);

    setShowCommentDialog(false);
    setShowCommentThankYouDialog(true);
  };

  const onCommentThankYouContinue = () => {
    setShowCommentThankYouDialog(false);
  };

  return (
    <>
      <NPSRatingDialog
        open={shouldShowInAppReview || debugInAppReview}
        title={t("ftue.nps.inapp.title")}
        onSubmit={onInAppRatingSubmit}
        onSkip={onInAppSkip}
      />
      <NPSRatingDialog
        open={shouldShowPurchaseReview || debugPurchaseReview}
        title={<Trans i18nKey="ftue.nps.purchase.title" />}
        onSubmit={onPurchaseRatingSubmit}
        onSkip={onPurchaseSkip}
      />
      <Dialogs.AlertDialog
        sx={{
          [`& .${dialogClasses.paper}`]: {
            marginX: "8px",
            flexGrow: 1,
            maxWidth: "500px",
          },
        }}
        open={showRatingThankYouDialog}
        text={
          <Box display="flex" flexDirection="column" alignItems="center" p={1}>
            <img src={DynamismAssets.AvatarCelebration} alt="" />
            <Typography textAlign="center">
              <Trans i18nKey={"ftue.nps.thankyou"} />
            </Typography>
          </Box>
        }
        positiveAction={{
          action: onThankYouContinue,
          text: t("general.ok"),
        }}
      />
      <NPSCommentDialog
        open={showCommentDialog}
        title={<Trans i18nKey="ftue.nps.comment.title" />}
        onSubmit={onCommentSubmit}
        onSkip={() => setShowCommentDialog(false)}
      />
      <Dialogs.AlertDialog
        sx={{
          [`& .${dialogClasses.paper}`]: {
            marginX: "8px",
            flexGrow: 1,
            maxWidth: "500px",
          },
        }}
        open={showCommentThankYouDialog}
        text={
          <Box display="flex" flexDirection="column" alignItems="center" p={1}>
            <img src={DynamismAssets.AvatarCelebration} alt="" />
            <Typography textAlign="center" maxWidth="100%">
              <Trans i18nKey="ftue.nps.comment.thankyou" />
              {userProfile?.email?.split("@").map((s, i, a) =>
                i !== a.length - 1 ? (
                  <span key={i}>
                    {s}@<wbr />
                  </span>
                ) : (
                  <span key={i}>{s}</span>
                )
              )}
              .
            </Typography>
          </Box>
        }
        positiveAction={{
          action: onCommentThankYouContinue,
          text: t("general.ok"),
        }}
      />
    </>
  );
};

export default NPSController;
