import {
  ChallengeValidationAvailable,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import { Challenge, FirestoreCollection } from "@neurosolutionsgroup/models";
import Tools from "@neurosolutionsgroup/tools";
import {
  Unsubscribe,
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import useAuth from "../auth/useAuth";
import { useChildrenContext } from "../children/ChildrenContext";
import {
  FeatureFlag,
  useRemoteConfig,
} from "@neurosolutionsgroup/remote-config";
import useParameters from "../Parameters/useParameters";
import FirebaseAPI from "@neurosolutionsgroup/api-client";

export interface ChallengeContextData {
  challengeFeatureFlagActive: boolean;
  challenges: Challenge[];
  setChallenges: Dispatch<SetStateAction<Challenge[]>>;
  challengesForApproval: Challenge[];
  setChallengesForApproval: Dispatch<SetStateAction<Challenge[]>>;
  challengeReadyToValidateForSelectedChild: Challenge[];
  deactivatedChallenges: string[];
  setDeactivatedChallenges: Dispatch<SetStateAction<string[]>>;
  totalChallengesToValidate: number;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const [useChallengeContext, ChallengeContextProvider] =
  Tools.Context.createGenericContext<ChallengeContextData>(__filename);

const ChallengeProvider = (props: PropsWithChildren): JSX.Element => {
  const { handleEvent } = useAnalytics();
  const { user, tenantId } = useAuth();
  const { selectedChild, childrenById } = useChildrenContext();
  const { version } = useParameters();
  const { checkFeatureFlagVersion } = useRemoteConfig();

  const [challenges, setChallenges] = useState<Challenge[]>([]);
  const [challengesForApproval, setChallengesForApproval] = useState<
    Challenge[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [deactivatedChallenges, setDeactivatedChallenges] = useState<string[]>(
    []
  );

  const challengeFeatureFlagActive = useMemo(
    () => checkFeatureFlagVersion(FeatureFlag.Challenges, version),
    [version]
  );

  const challengeToValidate = (challenge: Challenge): boolean => {
    if (childrenById[challenge.childId]?.isDisabled) {
      return false;
    }

    if (challenge.requiresApproval) {
      return false;
    }

    if (challenge.successful !== null) {
      return false;
    }

    if (challenge.active === false) {
      return true;
    }

    if (challenge.history.some((history) => history.parentStatus === null)) {
      return true;
    }

    return (
      challenge.endDate !== null &&
      challenge.endDate < Tools.Time.Dates.getTimeStamp()
    );
  };

  const challengeReadyToValidateForSelectedChild = useMemo((): Challenge[] => {
    const challengesToValidate: Challenge[] = selectedChild
      ? challenges.filter(
          (c) => c.childId === selectedChild && challengeToValidate(c)
        )
      : [];

    challengesToValidate.forEach((challenge, i) => {
      if (
        challenge.endDate &&
        challenge.endDate < Tools.Time.Dates.getTimeStamp()
      ) {
        const missingHistoriesLength =
          (challenge.occurrences ?? 1) - challenge.history.length;

        if (missingHistoriesLength > 0) {
          for (let i = 0; i < missingHistoriesLength; i++) {
            challenge.history.push({
              childStatus: null,
              childExecutionDate: null,
              parentStatus: null,
              validationDate: null,
            });
          }
        } else if (
          challenge.history.filter((history) => history.validationDate !== null)
            .length >= (challenge.occurrences ?? 1)
        ) {
          // Don't show challenges that have already been attempted the same number of times as occurrence.
          challengesToValidate.splice(i, 1);
        }
      }
    });

    return challengesToValidate;
  }, [challenges, selectedChild]);

  const totalChallengesToValidate = useMemo((): number => {
    return challenges.filter((c) => challengeToValidate(c)).length;
  }, [challenges]);

  useEffect(() => {
    if (totalChallengesToValidate > 0) {
      const event: ChallengeValidationAvailable = {
        name: "Challenge Validation Available",
        eventProperties: {
          "Number of validations": totalChallengesToValidate,
        },
      };

      handleEvent(event);
    }
  }, [totalChallengesToValidate]);

  useEffect(() => {
    let unsub: Unsubscribe | null = null;

    if (user && tenantId) {
      const getChallenges = async () => {
        // Check for expired challenges before getting.
        try {
          await FirebaseAPI.Challenge.terminateExpiredChallenges();
        } catch (err) {
          console.error(
            "Error in API call to terminate expired challenges.",
            err
          );
        }

        const db = getFirestore();

        const q = query(
          collection(db, FirestoreCollection.Challenges),
          where("userId", "==", user.uid),
          where("tenantId", "==", tenantId),
          where("deleted", "==", false),
          where("successful", "==", null)
        );

        unsub = onSnapshot(q, (snapshot) => {
          const docs = snapshot.docs.map(
            (doc) => ({ ...doc.data(), id: doc.id } as Challenge)
          );

          const approved: Challenge[] = [];
          const forApproval: Challenge[] = [];

          docs.forEach((doc) => {
            if (doc.requiresApproval && doc.approvalStatus === null) {
              if (
                // Expired challenges created by a professional are not shown to user.
                !(doc.endDate && doc.endDate < Tools.Time.Dates.getTimeStamp())
              ) {
                forApproval.push(doc);
              }
            } else if (doc.approvalStatus !== false) {
              // Don't show expired challenges that have been done max times.
              if (
                !(
                  doc.endDate &&
                  doc.endDate < Tools.Time.Dates.getTimeStamp() &&
                  doc.history.filter(
                    (history) => history.validationDate !== null
                  ).length >= (doc.occurrences ?? 1)
                )
              ) {
                approved.push(doc);
              }
            }
          });

          setChallenges(approved);

          setChallengesForApproval(forApproval);
        });
      };

      getChallenges();
    }

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [user]);

  return (
    <ChallengeContextProvider
      value={{
        challengeFeatureFlagActive,
        challenges,
        setChallenges,
        challengesForApproval,
        setChallengesForApproval,
        challengeReadyToValidateForSelectedChild,
        deactivatedChallenges,
        setDeactivatedChallenges,
        totalChallengesToValidate,
        loading,
        setLoading,
      }}
    >
      {props.children}
    </ChallengeContextProvider>
  );
};

export { useChallengeContext, ChallengeProvider };
