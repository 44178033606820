import { Box, Divider, Fab, Typography } from "@mui/material";
import {
  DrawerFilter,
  FilterCategory,
  Icons,
  Loader,
  NoteCard,
  WeekNotesDisplay,
  WeekPicker,
} from "@neurosolutionsgroup/components";
import {
  FTUEFlowDefinitions,
  useFTUE,
} from "@neurosolutionsgroup/webviews-ftue";
import { Page } from "common/Components";
import useChildren from "common/hooks/children/useChildren";
import { NotesProvider } from "common/hooks/notes/NoteContext";
import useNotes from "common/hooks/notes/useNotes";
import useLanguage from "common/hooks/Parameters/useLanguage";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SAFE_AREA_BOTTOM, SAFE_AREAS } from "stylesheets";
import NoteCreation from "./NoteCreation";
import NoteEdition from "./NoteEdition";
import { GamerChild, Note } from "@neurosolutionsgroup/models";
import useSideEffect from "common/hooks/sideEffect/useSideEffect";
import { format } from "date-fns";
import { Tools } from "@neurosolutionsgroup/tools";
import { taskCategoryLoc } from "@neurosolutionsgroup/localization";
import DynamismAssets from "assets/dynamism";
import { EmptyStateButton } from "@neurosolutionsgroup/webviews-pages";

const JournalComponent = (): JSX.Element | null => {
  const { language, dateLocale } = useLanguage();
  const { t } = useTranslation();
  const {
    actions: { onJournalAddClicked },
  } = useFTUE();

  const {
    selectors: { selectedChild, childrenById },
  } = useChildren();
  const {
    currentDate,
    loading,
    noteDates,
    noteDateCounts,
    notesForWeek,
    setCurrentDate,
  } = useNotes();
  const {
    selectors: { sideEffectById },
  } = useSideEffect();

  const [filters, setFilters] = useState<string[]>([]);
  const [selectedNote, setSelectedNote] = useState<Note | null>(null);
  const [openAddNoteForm, setOpenAddNoteForm] = useState(false);
  const [openEditNoteForm, setOpenEditNoteForm] = useState(false);

  const gamerChild: GamerChild | undefined = selectedChild
    ? childrenById[selectedChild]
    : undefined;

  const showNotesEmptyState = !Object.values(notesForWeek).some(
    (notes) => notes.length > 0
  );

  const filterOptions: FilterCategory<string>[] = useMemo(() => {
    const newFilters: FilterCategory<string>[] = [];

    let simpleNoteCount = 0;

    const sideEffectIds: string[] = [];

    const sideEffectCounts: { [id: string]: number } = {};

    Object.values(notesForWeek).forEach((notes) => {
      notes.forEach((note) => {
        if (sideEffectById) {
          const sideEffectId = note.sideEffectId;

          if (sideEffectId) {
            if (!sideEffectIds.includes(sideEffectId)) {
              sideEffectIds.push(sideEffectId);
            }

            sideEffectCounts[sideEffectId] = sideEffectCounts[sideEffectId]
              ? sideEffectCounts[sideEffectId] + 1
              : 1;
          } else {
            simpleNoteCount++;
          }
        }
      });
    });

    if (simpleNoteCount > 0) {
      newFilters.push({
        label: t("journal.filters.general"),
        options: [
          {
            id: "simple",
            label: t("journal.filters.simple"),
            count: simpleNoteCount,
          },
        ],
      });
    }

    if (sideEffectIds.length > 0 && sideEffectById) {
      newFilters.push({
        label: t("journal.filters.symptoms"),
        options: sideEffectIds.map((sid) => ({
          id: sid,
          label: sideEffectById[sid].name[language],
          count: sideEffectCounts[sid] ?? 0,
        })),
      });
    }

    return newFilters;
  }, [language, notesForWeek]);

  const noteFilter = (note: Note): boolean => {
    if (filters.length === 0) {
      return true;
    }

    if (filters.includes("simple")) {
      return !note.sideEffectId;
    }

    if (!note.sideEffectId) {
      return false;
    }

    return filters.includes(note.sideEffectId);
  };

  const noteSort = (a: Note, b: Note): number => {
    if (a.eventTime) {
      if (b.eventTime) {
        return a.eventTime - b.eventTime;
      } else {
        return 1;
      }
    } else {
      if (b.eventTime) {
        return -1;
      } else {
        return a.title.localeCompare(b.title);
      }
    }
  };

  const onAddNote = () => {
    onJournalAddClicked();
    setOpenAddNoteForm(true);
  };

  const onNoteClick = (note: Note) => {
    setSelectedNote(note);
    setOpenEditNoteForm(true);
  };

  return gamerChild ? (
    <Page
      sxBody={{
        paddingBottom: "62px",
      }}
    >
      <FTUEFlowDefinitions.JournalFTUEFlow.Screen />
      <Box display="flex" justifyContent="flex-end">
        <WeekPicker
          date={currentDate}
          onChange={setCurrentDate}
          language={language}
          locale={dateLocale}
          taggedDates={noteDates}
        />
      </Box>
      <Box mt={2}>
        <WeekNotesDisplay
          weekEndDate={currentDate}
          dateTags={noteDateCounts}
          dateLocale={dateLocale}
        />
      </Box>
      <Divider
        sx={{
          marginY: 1,
        }}
      />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">{t("journal.notes.title")}</Typography>

        <DrawerFilter
          options={filterOptions}
          value={filters}
          onChange={setFilters}
          disabled={filterOptions.length === 0}
          safeAreas={SAFE_AREAS}
        />
      </Box>

      <Box mt={2}>
        {loading ? (
          <Loader />
        ) : (
          Object.entries(notesForWeek).map(([date, notes]) => {
            const filteredNotes = notes.filter(noteFilter);

            return filteredNotes.length > 0 ? (
              <Box key={date} mt={1}>
                <Typography>
                  {format(
                    Tools.Time.Dates.parseDateStringToJsDate(date),
                    "d MMMM yyyy",
                    {
                      locale: dateLocale,
                    }
                  )}
                </Typography>
                {filteredNotes.sort(noteSort).map((note) => (
                  <NoteCard
                    key={note.noteId}
                    language={language}
                    note={note}
                    onClick={() => onNoteClick(note)}
                    sx={{
                      marginTop: 1,
                    }}
                    taskCategoryLoc={taskCategoryLoc}
                    sideEffectsById={sideEffectById}
                  />
                ))}
              </Box>
            ) : null;
          })
        )}
      </Box>

      {showNotesEmptyState ? (
        <EmptyStateButton
          imgSrc={DynamismAssets.Journal}
          onClick={onAddNote}
          text={t("ftue.journal.addNote.text")}
          imgSize="95vw"
          data-cy="journal-empty-state-add"
        />
      ) : (
        <Fab
          onClick={onAddNote}
          color="primary"
          sx={{
            position: "absolute",
            bottom: `calc(${SAFE_AREA_BOTTOM} + 138px)`,
            right: "12px",
            svg: {
              width: "30px",
              height: "30px",
            },
          }}
          data-cy="add-note"
        >
          <Icons.AddIcon color={"#fff"} />
        </Fab>
      )}

      <NoteCreation
        open={openAddNoteForm}
        onClose={() => setOpenAddNoteForm(false)}
        gamerChild={gamerChild}
      />
      <NoteEdition
        open={openEditNoteForm}
        onClose={() => {
          setOpenEditNoteForm(false);
          setSelectedNote(null);
        }}
        note={selectedNote}
        gamerChild={gamerChild}
      />
    </Page>
  ) : null;
};

const Journal = (): JSX.Element => {
  return (
    <NotesProvider>
      <JournalComponent />
    </NotesProvider>
  );
};

export default Journal;
