import { HookResult } from "../HookResult";
import { RoutinesById, useRoutineContext } from "./RoutinesContext";
import { Routine } from "@neurosolutionsgroup/models";
import useTasks from "./useTasks";
import FirebaseAPI from "@neurosolutionsgroup/api-client";
import {
  RoutineCreated,
  RoutineDeleted,
  RoutineUpdated,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import Tools from "@neurosolutionsgroup/tools";
import useNotifications from "../notifications/useNotifications";

export interface useRoutineSelectors {
  routineIds: string[];
  routinesById: RoutinesById;
  selectedRoutineId: string | undefined;
  loading: boolean;
}

export interface useRoutineActions {
  updateRoutine: (updatedRoutine: Routine) => Promise<void>;
  getActiveRoutines: (gamerChildId?: string) => Routine[];
}

const useRoutines = (): HookResult<useRoutineSelectors, useRoutineActions> => {
  const {
    routineIds,
    routinesById,
    selectedRoutineId,
    setRoutineIds,
    setRoutinesById,
    loading,
    setLoading,
  } = useRoutineContext();

  const {
    actions: { setTasksById, setTaskRoutineMap },
  } = useTasks();

  const { handleEvent } = useAnalytics();

  const { updateRoutineNotifications } = useNotifications();

  const updateRoutine = async (updatedRoutine: Routine) => {
    setLoading(true);

    try {
      const routine = await FirebaseAPI.Routine.updateRoutine(updatedRoutine);

      updateRoutineNotifications().catch((err) => {
        console.error(
          "[Notifications] Error encountered updating routine scheduled notifications: " +
            err
        );
      });

      setRoutinesById((current) => ({ ...current, [routine.id]: routine }));

      if (!routineIds.includes(routine.id)) {
        setRoutineIds((current) => [...current, routine.id]);

        const event: RoutineCreated = {
          name: "Routine Created",
          eventProperties: {
            "Type": "App",
            "Routine ID": routine.id,
            "Template ID": routine.templateId?.toString() ?? null,
          },
          setProperties: {
            Routines: routineIds.filter((rid) => !routinesById[rid].deleted)
              .length,
          },
        };

        handleEvent(event);
      } else if (!routine.deleted) {
        const event: RoutineUpdated = {
          name: "Routine Updated",
          eventProperties: {
            "Type": "App",
            "Routine ID": routine.id,
            "Nb Tasks": routine.tasks.length,
            "Start Time": Tools.Time.Strings.secondsToTimeStamp(routine.start),
            "End Time": Tools.Time.Strings.secondsToTimeStamp(routine.end),
            "Duration": Tools.Time.Strings.secondsToTimeStamp(
              routine.end - routine.start
            ),
          },
          setProperties: {
            Routines: routineIds.filter((rid) => !routinesById[rid].deleted)
              .length,
          },
        };

        handleEvent(event);
      } else {
        const event: RoutineDeleted = {
          name: "Routine Deleted",
          eventProperties: {
            "Type": "App",
            "Routine ID": routine.id,
          },
          setProperties: {
            Routines: routineIds.filter((rid) => !routinesById[rid].deleted)
              .length,
          },
        };

        handleEvent(event);
      }

      setTasksById((current) => {
        const clone = { ...current };

        routine.tasks.forEach((t) => {
          clone[t.id] = t;
        });

        return clone;
      });

      setTaskRoutineMap((current) => {
        const clone = { ...current };

        routine.tasks.forEach((t) => {
          clone[t.id] = routine.id;
        });

        return clone;
      });
    } finally {
      setLoading(false);
    }
  };

  const getActiveRoutines = (gamerChildId?: string): Routine[] => {
    if (!gamerChildId) {
      return routinesById
        ? Object.keys(routinesById)
            .filter((r) => {
              return (
                !routinesById[r].deleted &&
                !routinesById[r].disabled &&
                !routinesById[r].locked
              );
            })
            .map((r) => routinesById[r])
        : [];
    } else {
      return routinesById
        ? Object.keys(routinesById)
            .filter((r) => {
              return (
                !routinesById[r].deleted &&
                !routinesById[r].disabled &&
                !routinesById[r].locked &&
                routinesById[r].users.includes(gamerChildId)
              );
            })
            .map((r) => routinesById[r])
        : [];
    }
  };

  return {
    selectors: {
      routineIds,
      routinesById,
      selectedRoutineId,
      loading,
    },
    actions: {
      updateRoutine,
      getActiveRoutines,
    },
  };
};

export default useRoutines;
