import { taskCategoryLoc } from "@neurosolutionsgroup/localization";
import { TaskCategory } from "@neurosolutionsgroup/models";
import { Select } from "common/Components";
import useLanguage from "common/hooks/Parameters/useLanguage";
import React, { Dispatch, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";

interface CategorySelectorProps {
  value: TaskCategory | null;
  onChange: Dispatch<SetStateAction<TaskCategory | null>>;
}

const CategorySelector = ({
  value,
  onChange,
}: CategorySelectorProps): JSX.Element => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  const options = useMemo(
    () =>
      Object.values(TaskCategory)
        .map((value) => ({
          value,
          label: taskCategoryLoc[language][value],
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [language]
  );

  return (
    <Select
      value={value?.toString() ?? null}
      options={options}
      onChange={(option) => onChange(option.value)}
      label={t("routine.task.inputs.category.label")}
      placeholder={t("routine.task.inputs.category.placeholder")}
      data-cy="category-select"
    />
  );
};

export default CategorySelector;
