import { Box, ButtonBase, SxProps, Theme } from "@mui/material";
import { PropsWithChildren } from "react";
import PremiumTag from "./PremiumTag";
import { useTranslation } from "react-i18next";
import { CypressProps } from "@neurosolutionsgroup/models";

interface PremiumLockProps extends PropsWithChildren, CypressProps {
  active: boolean;
  onClick: VoidFunction;
  variant: "short" | "long" | "button" | "button-long" | "icon-button" | "none";
  borderRadius?: string | number;
  display?: "block" | "inline-block";
  top?: number | string;
  right?: number | string;
  sx?: SxProps<Theme>;
  buttonSx?: SxProps<Theme>;
  blur?: boolean;
}

const PremiumLock = ({
  active,
  onClick,
  variant,
  display = "inline-block",
  sx,
  buttonSx,
  blur = false,
  ...props
}: PremiumLockProps): JSX.Element => {
  const { t } = useTranslation();

  const tagText = ["long", "button-long"].includes(variant)
    ? t("navigation.subscription.tiers.premium")
    : "P";

  let fontSize: number | undefined = undefined;
  let top: number | string | undefined = props.top ?? "-.8rem";
  let right: number | string | undefined = props.right ?? 0;
  let borderRadius: string | number | undefined = props.borderRadius;

  switch (variant) {
    case "button":
    case "button-long":
      borderRadius = "999px";
      break;
    case "icon-button":
      fontSize = 14;
      right = props.right ?? "-.5rem";
      top = props.top ?? "-.4rem";
      borderRadius = props.borderRadius ?? "999px";
      break;
    case "long":
      right = props.right ?? (borderRadius ? 0 : "6px");
      break;
  }

  return active ? (
    <Box
      sx={[
        {
          position: "relative",
          display,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      data-cy={props["data-cy"] ?? "premium-lock"}
    >
      {props.children}
      <ButtonBase
        onClick={onClick}
        sx={[
          {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            borderRadius: borderRadius,
            backdropFilter: blur ? "blur(3px)" : undefined,
          },
          ...(Array.isArray(buttonSx) ? buttonSx : [buttonSx]),
        ]}
      />
      {variant !== "none" ? (
        <Box
          sx={{
            position: "absolute",
            top,
            right,
          }}
        >
          <PremiumTag text={tagText} fontSize={fontSize} />
        </Box>
      ) : null}
    </Box>
  ) : (
    <>{props.children}</>
  );
};

export default PremiumLock;
