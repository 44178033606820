import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { SetStateAction } from "react";

interface AMPMSelectorProps {
  isAM: boolean;
  setIsAM: React.Dispatch<SetStateAction<boolean>>;
}

const AMPMSelector: React.FC<AMPMSelectorProps> = ({ isAM, setIsAM }) => {
  const ROOT_CLASS = "ampm-selector";

  return (
    <Grid container justifyContent="center">
      <Grid item xs={6} className={ROOT_CLASS}>
        <Button
          variant={isAM ? "contained" : undefined}
          color="secondary"
          onClick={() => setIsAM(true)}
          className={ROOT_CLASS + "__selector"}
        >
          AM
        </Button>
      </Grid>
      <Grid item xs={6} className={ROOT_CLASS}>
        <Button
          variant={!isAM ? "contained" : undefined}
          color="secondary"
          onClick={() => setIsAM(false)}
          className={ROOT_CLASS + "__selector"}
        >
          PM
        </Button>
      </Grid>
    </Grid>
  );
};

export default AMPMSelector;
