import { DialogContent, DialogContentText } from "@mui/material";
import Tools from "@neurosolutionsgroup/tools";
import useLanguage from "common/hooks/Parameters/useLanguage";
import React from "react";
import { useTranslation } from "react-i18next";

export interface ChallengeSummaryDetailsProps {
  childName: string;
  title: string;
  days: number | null;
  startTime: number | null;
  endTime: number | null;
  endDateMS: number | null;
  occurrences: number;
}

const ChallengeSummaryDetails = ({
  childName,
  title,
  days,
  startTime,
  endTime,
  endDateMS,
  occurrences,
}: ChallengeSummaryDetailsProps): JSX.Element => {
  const { t } = useTranslation();
  const { language, dateLocale } = useLanguage();
  return (
    <DialogContent>
      <DialogContentText
        component="div"
        color="secondary"
        sx={{ wordWrap: "break-word" }}
      >
        <>
          {days || (startTime && endTime) || endDateMS
            ? t("routine.challenge.summary.must", {
                childName,
                challengeTitle: title,
              })
            : t("routine.challenge.summary.can", {
                childName,
                challengeTitle: title,
              })}
          <ul>
            {days ? (
              <li>{Tools.Time.Days.dayToString(days, dateLocale)}</li>
            ) : null}
            {startTime && endTime ? (
              <li>
                {Tools.Time.Strings.localizedTimePeriodFromSeconds(
                  startTime,
                  endTime,
                  language
                )}
              </li>
            ) : null}
            {endDateMS ? (
              <li>
                {t("routine.challenge.summary.before", {
                  periodString: new Date(endDateMS).toLocaleDateString(
                    language,
                    {
                      dateStyle: "long",
                    }
                  ),
                })}
              </li>
            ) : null}
            {occurrences ? (
              <li>
                {t("routine.challenge.summary.occurrence", { occurrences })}
              </li>
            ) : null}
          </ul>
        </>
      </DialogContentText>
    </DialogContent>
  );
};
export default ChallengeSummaryDetails;
