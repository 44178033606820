import { Box, Divider, Grid, Typography } from "@mui/material";
import { TaskHistory } from "@neurosolutionsgroup/models";
import { taskCategoryLoc } from "@neurosolutionsgroup/localization";
import Tools from "@neurosolutionsgroup/tools";
import TaskAssets from "assets/task-icons";
import { Drawer } from "common/Components";
import { DrawerProps } from "common/Components/Drawer/Drawer";
import useRoutines from "common/hooks/routines/useRoutines";
import useTasks from "common/hooks/routines/useTasks";
import useLanguage from "common/hooks/Parameters/useLanguage";
import { groupBy } from "lodash";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RoutinePeriod } from "../PeriodPicker";
import { Icons } from "@neurosolutionsgroup/components";

interface CategoryDetailDrawerProps extends DrawerProps {
  category: string;
  period: RoutinePeriod;
  history: TaskHistory[];
}

const CategoryDetailDrawer: React.FC<CategoryDetailDrawerProps> = ({
  category,
  period,
  history,
  ...props
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  const { tasksById, taskRoutineMap } = useTasks().selectors;
  const { routinesById } = useRoutines().selectors;

  interface TaskHistoryData {
    [routineId: string]: {
      [taskId: string]: {
        done: number;
        notDone: number;
      };
    };
  }

  const calculateTaskHistory: TaskHistoryData = useMemo(() => {
    const historyByTask = groupBy(history, (h) => h.task);

    const tasksByRoutineId: TaskHistoryData = {};

    Object.keys(historyByTask).forEach((taskId) => {
      const routineId = taskRoutineMap[taskId];

      if (routineId) {
        if (Object.keys(tasksByRoutineId).includes(routineId)) {
          tasksByRoutineId[routineId][taskId] = {
            done: historyByTask[taskId]
              ? historyByTask[taskId].filter((h) => h.status === true).length
              : 0,
            notDone: historyByTask[taskId]
              ? historyByTask[taskId].filter((h) => h.status === false).length
              : 0,
          };
        } else {
          tasksByRoutineId[routineId] = {
            [taskId]: {
              done: historyByTask[taskId]
                ? historyByTask[taskId].filter((h) => h.status === true).length
                : 0,
              notDone: historyByTask[taskId]
                ? historyByTask[taskId].filter((h) => h.status === false).length
                : 0,
            },
          };
        }
      }
    });

    return tasksByRoutineId;
  }, [history]);

  return (
    <Drawer {...props}>
      <Box pb={3}>
        <Box
          p={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h4" data-cy="task-details-drawer-title">
            {taskCategoryLoc[language][category]}
          </Typography>
          <Typography variant="h4">
            {t("dashboard.period.options." + period.toString())}
          </Typography>
        </Box>
        {Object.keys(calculateTaskHistory)
          .sort((a, b) => routinesById[a].start - routinesById[b].start)
          .map((routineId) => (
            <Box key={routineId}>
              <Divider />
              <Box p={1} pt={2}>
                <Grid container spacing={1} key={routineId}>
                  <Grid item xs={12} container>
                    <Grid item xs={7}>
                      <Typography variant="h5">
                        {routinesById[routineId].name}{" "}
                        {Tools.Time.Strings.localizedTimePeriodFromSeconds(
                          routinesById[routineId].start,
                          routinesById[routineId].end,
                          language
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={2.5}>
                      <Typography variant="h5" textAlign="center">
                        {t("dashboard.task.labels.done")}
                      </Typography>
                    </Grid>
                    <Grid item xs={2.5}>
                      <Typography variant="h5" textAlign="center">
                        {t("dashboard.task.labels.notDone")}
                      </Typography>
                    </Grid>
                  </Grid>

                  {Object.keys(calculateTaskHistory[routineId]).map(
                    (taskId) => (
                      <Grid
                        item
                        xs={12}
                        container
                        alignItems="center"
                        key={taskId}
                      >
                        <Grid item xs={7}>
                          <Box
                            display="flex"
                            alignItems="center"
                            sx={{
                              img: {
                                height: "3em",
                                marginRight: "1em",
                              },
                            }}
                          >
                            <img
                              src={
                                TaskAssets.TaskIcons[tasksById[taskId]?.icon]
                              }
                              alt={`Task Icon ${tasksById[taskId]?.icon}`}
                            />
                            <Typography fontSize="0.85rem">
                              {tasksById[taskId].name}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={2.5}>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                              svg: {
                                height: "1.5em",
                                width: "1.5em",
                                marginLeft: "0.5em",
                              },
                            }}
                          >
                            <Typography fontSize="0.85rem">
                              {calculateTaskHistory[routineId][taskId].done}
                            </Typography>
                            <Icons.CheckMarkIcon
                              color="#A6DA2D"
                              checkStyle="kairos"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={2.5}>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                              svg: {
                                height: "1.5em",
                                width: "1.5em",
                                marginLeft: "0.5em",
                              },
                            }}
                          >
                            <Typography fontSize="0.85rem">
                              {calculateTaskHistory[routineId][taskId].notDone}
                            </Typography>
                            <Icons.CloseIcon color="#EF4044" />
                          </Box>
                        </Grid>
                      </Grid>
                    )
                  )}
                </Grid>
              </Box>
            </Box>
          ))}
      </Box>
    </Drawer>
  );
};

export default CategoryDetailDrawer;
