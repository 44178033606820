import useChildren from "common/hooks/children/useChildren";
import useLanguage from "common/hooks/Parameters/useLanguage";
import React, { useState } from "react";
import clsx from "clsx";
import { Box, Grid, Typography } from "@mui/material";
import Tools from "@neurosolutionsgroup/tools";
import { Routine } from "@neurosolutionsgroup/models";
import { useTranslation } from "react-i18next";
import { FTUEFlow, useFTUE } from "@neurosolutionsgroup/webviews-ftue";
import Card, { CardMenuElement } from "common/Components/Card/Card";
import RoutineTemplateIcons from "assets/routines/templates";
import ChildDisplay from "common/Components/ChildDisplay/ChildDisplay";
import { Icons } from "@neurosolutionsgroup/components";

interface RoutineProps {
  id: string;
  routine: Routine;
  onClick: () => void;
  onEditClick: () => void;
  onCopyClick: () => void;
  onDeleteClick: () => void;
  onActivateClick: (isActive: boolean) => void;
}

enum ActionType {
  EDIT,
  COPY,
  DELETE,
  ACTIVATE,
}

const RoutineComponent = ({
  id,
  routine,
  onClick,
  onEditClick,
  onCopyClick,
  onDeleteClick,
  onActivateClick,
}: RoutineProps): JSX.Element => {
  const ROOT_CLASS = "routine-card";

  const { t } = useTranslation();
  const { language } = useLanguage();

  const {
    actions: { canDisplayFlow },
  } = useFTUE();

  const [isSelected, setIsSelected] = useState<boolean>(false);

  const {
    selectors: { childrenById },
  } = useChildren();

  const onclickAction = (action: ActionType) => {
    switch (action) {
      case ActionType.EDIT:
        onEditClick();
        break;
      case ActionType.COPY:
        onCopyClick();
        break;
      case ActionType.DELETE:
        onDeleteClick();
        break;
      case ActionType.ACTIVATE:
        onActivateClick(!routine.disabled);
        break;
      default:
        break;
    }
  };

  const timeToString = () => {
    return Tools.Time.Strings.localizedTimePeriodFromSeconds(
      routine.start,
      routine.end,
      language
    );
  };

  const menuComponents = (setMenuOpen: (b: boolean) => void): JSX.Element[] => {
    return [
      <CardMenuElement
        data-cy={"edit-button-" + id}
        text={t("general.actions.edit")}
        icon={<Icons.EditIcon />}
        onClick={() => {
          onclickAction(ActionType.EDIT);
          setMenuOpen(false);
        }}
        disabled={canDisplayFlow(FTUEFlow.FirstRoutine)}
      />,
      <CardMenuElement
        data-cy={"copy-button-" + id}
        text={t("general.actions.copy")}
        icon={<Icons.CopyIcon />}
        onClick={() => {
          onclickAction(ActionType.COPY);
          setMenuOpen(false);
        }}
        disabled={canDisplayFlow(FTUEFlow.FirstRoutine)}
      />,
      <CardMenuElement
        data-cy={"delete-button-" + id}
        text={t("general.actions.delete")}
        icon={<Icons.DeleteIcon />}
        onClick={() => {
          onclickAction(ActionType.DELETE);
          setMenuOpen(false);
        }}
        disabled={canDisplayFlow(FTUEFlow.FirstRoutine)}
      />,
      <CardMenuElement
        //id="card-disable-button"
        data-cy={"card-delete-button-" + id}
        text={
          routine.disabled
            ? t("general.actions.activate")
            : t("general.actions.deactivate")
        }
        icon={
          routine.disabled ? (
            <Icons.CheckMarkIcon checkStyle="kairos" />
          ) : (
            <Icons.CloseIcon />
          )
        }
        onClick={() => {
          onclickAction(ActionType.ACTIVATE);
          setMenuOpen(false);
        }}
        disabled={canDisplayFlow(FTUEFlow.FirstRoutine)}
      />,
    ];
  };

  return (
    <Card
      id={id}
      menuOpen={isSelected}
      setMenuOpen={setIsSelected}
      menuElements={menuComponents(setIsSelected)}
      className={clsx(ROOT_CLASS, {
        [ROOT_CLASS + "--disabled"]: routine.disabled,
      })}
      menuTitle={
        canDisplayFlow(FTUEFlow.FirstRoutine) ? (
          <>
            <Box>
              <Typography variant="h4" textAlign="center" mb={0.5}>
                {t("general.options")}
              </Typography>
              <Typography fontSize="0.8rem" textAlign="center" color="primary">
                {t("ftue.optionsCard.disabled")}
              </Typography>
            </Box>
          </>
        ) : undefined
      }
      data-cy="routine-card"
    >
      <div className={clsx(ROOT_CLASS + "__container")} onClick={onClick}>
        <Grid container>
          <Grid item xs={12}>
            <div className={ROOT_CLASS + "__icon"}>
              <img
                src={
                  RoutineTemplateIcons[
                    (routine?.icon as 0 | 1 | 2 | 3 | 4 | null) ?? 0
                  ]
                }
                alt={`Routine Icon ${routine?.icon ?? 0}`}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={ROOT_CLASS + "__name-container"}>
              <h2 className={ROOT_CLASS + "__name"}>{routine.name}</h2>
              <div className={ROOT_CLASS + "__time"}>{timeToString()}</div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={ROOT_CLASS + "__days-container"}>
              {Tools.Time.Days.dayIndexes.map((day) => (
                <div
                  key={day}
                  className={clsx(ROOT_CLASS + "__day", {
                    [ROOT_CLASS + "__day--disabled"]:
                      !Tools.Time.Days.dayInDays(day, routine.days),
                  })}
                >
                  {t(`general.time.days.short.${day}`)}
                </div>
              ))}
            </div>
          </Grid>
          <Grid item xs={12} mt={1}>
            <Grid container spacing={1}>
              {routine.users.map((user) => {
                const child = childrenById[user];
                return child ? (
                  <Grid item key={user}>
                    <ChildDisplay icon={child.icon} name={child.name} />
                  </Grid>
                ) : null;
              })}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

export default RoutineComponent;
