import useLanguage from "common/hooks/Parameters/useLanguage";
import React, { useCallback, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import clsx from "clsx";
import Tools from "@neurosolutionsgroup/tools";
import { NotificationCategory, Task } from "@neurosolutionsgroup/models";
import Card, { CardMenuElement } from "common/Components/Card/Card";
import { useTranslation } from "react-i18next";
import useNotifications from "common/hooks/notifications/useNotifications";
import TaskAssets from "assets/task-icons";
import { Icons } from "@neurosolutionsgroup/components";

interface TaskCardProps {
  task: Task;
  index: number;
  id: string;
  onCopy: VoidFunction;
  onEdit: VoidFunction;
  onDelete: VoidFunction;
}

const TaskCard = ({
  task,
  index,
  id,
  onEdit,
  onDelete,
}: TaskCardProps): JSX.Element => {
  const ROOT_CLASS = "task-card";
  const { globalOptIn, notificationsSettings } = useNotifications();

  const [menuOpen, setMenuOpen] = useState(false);

  const { language } = useLanguage();
  const { t } = useTranslation();

  const getTime = useCallback(
    (reminder: number): string => {
      return Tools.Time.Strings.localizedTimeFromSeconds(reminder, language);
    },
    [language]
  );

  return (
    <>
      <Draggable draggableId={id} index={index} key={id}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.draggableProps}>
            <Card
              data-cy={"task-card-" + index}
              className={clsx(ROOT_CLASS, {
                [ROOT_CLASS + "--is-dragging"]: snapshot.isDragging,
              })}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              menuElements={[
                <CardMenuElement
                  data-cy={"task-edit-button-" + index}
                  text={t("general.actions.edit")}
                  icon={<Icons.EditIcon />}
                  onClick={() => {
                    onEdit();
                    setMenuOpen(false);
                  }}
                />,
                <CardMenuElement
                  data-cy={"task-delete-button-" + index}
                  text={t("general.actions.delete")}
                  icon={<Icons.DeleteIcon />}
                  onClick={() => {
                    onDelete();
                    setMenuOpen(false);
                  }}
                />,
              ]}
            >
              <div className={ROOT_CLASS + "__row"}>
                <img
                  className={ROOT_CLASS + "__icon"}
                  src={TaskAssets.TaskIcons[task.icon]}
                  alt={`Task Icon ${task.icon}`}
                />
                <span className={ROOT_CLASS + "__name"}>{task.name}</span>

                <div
                  className={ROOT_CLASS + "__handle"}
                  onContextMenu={(e) => e.preventDefault()}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  {...provided.dragHandleProps}
                >
                  <Icons.DragIcon color="#31737c" />
                </div>
              </div>
              {task.reminder &&
              globalOptIn &&
              notificationsSettings[NotificationCategory.Routine] ? (
                <div className={ROOT_CLASS + "__row"}>
                  <div className={ROOT_CLASS + "__reminder"}>
                    <Icons.NotificationIcon />
                    <span>{getTime(task.reminder)}</span>
                  </div>
                </div>
              ) : null}
            </Card>
          </div>
        )}
      </Draggable>
    </>
  );
};

export default TaskCard;
