import React, {
  SetStateAction,
  Dispatch,
  useState,
  useEffect,
  PropsWithChildren,
  useMemo,
} from "react";
import {
  FirestoreCollection,
  FirestoreFollowedSideEffectDocument,
  FirestorePrescriptionDocument,
  FirestoreRecordSummaryDocument,
  FollowedSideEffect,
  Prescription,
  RecordSummary,
} from "@neurosolutionsgroup/models";
import { useChildrenContext } from "../children/ChildrenContext";
import useAuth from "../auth/useAuth";
import {
  collection,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { groupBy } from "lodash";
import FirestoreTools from "common/Tools/Firestore";
import { Unsubscribe } from "firebase/auth";
import FollowUpLogic from "./FollowUpLogic";
import {
  ObservationAvailable,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import useParameters from "../Parameters/useParameters";
import Tools from "@neurosolutionsgroup/tools";

export type PrescriptionsByGamerChildId = {
  [gamerChildId: string]: Prescription[];
};

export type FollowedSideEffectsByGamerChildId = {
  [gamerChildId: string]: FollowedSideEffect[];
};

export type RecordsSummariesByFollowedSideEffect = {
  [followedSideEffectId: string]: RecordSummary[];
};

interface FollowUpData {
  prescriptionsByGamerChildId: PrescriptionsByGamerChildId;
  setPrescriptionsByGamerChildId: Dispatch<
    SetStateAction<PrescriptionsByGamerChildId>
  >;
  followedSideEffectsByGamerChildId: FollowedSideEffectsByGamerChildId;
  setFollowedSideEffectsByGamerChildId: React.Dispatch<
    SetStateAction<FollowedSideEffectsByGamerChildId>
  >;
  followUpInitializationComplete: boolean;
  recordsInitializationComplete: boolean;
  recordsSummaries: RecordsSummariesByFollowedSideEffect;
  setRecordsSummaries: Dispatch<RecordsSummariesByFollowedSideEffect>;
  resetFollowUp: () => void;
  anyChildHasObservation: boolean;
}

const [useFollowUpContext, FollowUpContextProvider] =
  Tools.Context.createGenericContext<FollowUpData>(__filename);

const FollowUpProvider: React.FC<PropsWithChildren> = (props) => {
  const {
    childrenById,
    childInitilizationComplete,
    medicalChildrenByGamerId,
    selectedChild,
    anyChildHasTaskTovalidate,
    setSelectedChild,
  } = useChildrenContext();
  const { user, tenantId } = useAuth();

  const {
    handleEvent,
    functions: { setProfileProperties },
  } = useAnalytics();
  const { addLoadingProgress } = useParameters();

  const [prescriptionsByGamerChildId, setPrescriptionsByGamerChildId] =
    useState<PrescriptionsByGamerChildId>({});
  const [
    followedSideEffectsByGamerChildId,
    setFollowedSideEffectsByGamerChildId,
  ] = useState<FollowedSideEffectsByGamerChildId>({});
  const [followUpInitializationComplete, setFollowUpInitializationComplete] =
    useState(false);
  const [recordsInitializationComplete, setRecordsInitializationComplete] =
    useState(false);
  const [recordsSummaries, setRecordsSummaries] =
    useState<RecordsSummariesByFollowedSideEffect>({});

  useEffect(() => {
    if (childInitilizationComplete && user && tenantId) {
      const initializeData = async (): Promise<void> => {
        if (!medicalChildrenByGamerId) {
          setPrescriptionsByGamerChildId({});
          setFollowedSideEffectsByGamerChildId({});
          setFollowUpInitializationComplete(true);
        }

        const gamerChildIds = Object.keys(medicalChildrenByGamerId);
        const medicalChildIds = gamerChildIds.map(
          (gcid) => medicalChildrenByGamerId[gcid].medicalChildId
        );

        if (medicalChildIds.length === 0) {
          const prescriptionsByGamerChildId: PrescriptionsByGamerChildId = {};
          const sideEffectsByGamerChildId: FollowedSideEffectsByGamerChildId =
            {};

          gamerChildIds.forEach((gcid) => {
            prescriptionsByGamerChildId[gcid] = [];
            sideEffectsByGamerChildId[gcid] = [];
          });

          setPrescriptionsByGamerChildId(prescriptionsByGamerChildId);
          setFollowedSideEffectsByGamerChildId(sideEffectsByGamerChildId);
          setFollowUpInitializationComplete(true);
          addLoadingProgress(20);
        } else {
          // Setup Firestore requests.
          const db = getFirestore();

          const prescriptionsRef = collection(
            db,
            FirestoreCollection.Prescriptions
          );

          const prescriptionsQuery = query(
            prescriptionsRef,
            where("userId", "==", user.uid),
            where("tenantId", "==", tenantId),
            where("medicalChildId", "in", medicalChildIds)
          );

          const sideEffectRefs = collection(
            db,
            FirestoreCollection.FollowedSideEffects
          );

          const sideEffectsQuery = query(
            sideEffectRefs,
            where("userId", "==", user.uid),
            where("tenantId", "==", tenantId),
            where("medicalChildId", "in", medicalChildIds)
          );

          await Promise.all([
            getDocs(prescriptionsQuery),
            getDocs(sideEffectsQuery),
          ]).then((responses) => {
            let activePrescriptions = 0;
            let activeSideEffects = 0;

            // Parse Firestore data.
            const prescriptions: Prescription[] = responses[0].docs.map(
              (doc) => {
                const data = doc.data() as FirestorePrescriptionDocument;

                if (data.endDate === null) {
                  activePrescriptions++;
                }

                return {
                  prescriptionId: doc.id,
                  ...data,
                  endDate: data.endDate ?? undefined,
                  takesOtherMedication: data.takesOtherMedication ?? undefined,
                };
              }
            );

            const sideEffects: FollowedSideEffect[] = responses[1].docs.map(
              (doc) => {
                const data = doc.data() as FirestoreFollowedSideEffectDocument;

                if (data.isActive) {
                  activeSideEffects++;
                }

                return {
                  followedSideEffectId: doc.id,
                  ...data,
                  startDate: FirestoreTools.mapFirestoreDateToString(
                    data.startDate as Timestamp
                  ),
                  activeStatusChanges: data.activeStatusChanges.map((asc) =>
                    FirestoreTools.mapFirestoreDateToString(asc as Timestamp)
                  ),
                };
              }
            );

            const prescriptionByMedicalChildId = groupBy(
              prescriptions,
              (p) => p.medicalChildId
            );

            const sideEffectsByMedicalChildId = groupBy(
              sideEffects,
              (se) => se.medicalChildId
            );

            const prescriptionsByGamerChildId: PrescriptionsByGamerChildId = {};
            const sideEffectsByGamerChildId: FollowedSideEffectsByGamerChildId =
              {};

            gamerChildIds.forEach((gcid) => {
              const medicalChildId =
                medicalChildrenByGamerId[gcid].medicalChildId;
              prescriptionsByGamerChildId[gcid] =
                prescriptionByMedicalChildId[medicalChildId] ?? [];
              sideEffectsByGamerChildId[gcid] =
                sideEffectsByMedicalChildId[medicalChildId] ?? [];
            });

            setPrescriptionsByGamerChildId(prescriptionsByGamerChildId);
            setFollowedSideEffectsByGamerChildId(sideEffectsByGamerChildId);
            setFollowUpInitializationComplete(true);

            setProfileProperties({
              "Prescriptions Count": activePrescriptions,
              "Side Effects Count": activeSideEffects,
            });
          });
        }
      };

      initializeData();
    }
  }, [childInitilizationComplete, user, tenantId]);

  useEffect(() => {
    let unsub: Unsubscribe | null = null;

    if (followUpInitializationComplete && user && tenantId) {
      const db = getFirestore();
      const q = query(
        collection(db, FirestoreCollection.RecordSummaries),
        where("userId", "==", user.uid),
        where("tenantId", "==", tenantId)
      );

      unsub = onSnapshot(q, (snapShot) => {
        const summaries: RecordsSummariesByFollowedSideEffect = {};
        snapShot.forEach((doc) => {
          const data = doc.data() as FirestoreRecordSummaryDocument;
          if (summaries[data.sideEffectId]) {
            summaries[data.sideEffectId].push({
              recordSummaryId: doc.id,
              ...data,
            });
          } else {
            summaries[data.sideEffectId] = [
              {
                recordSummaryId: doc.id,
                ...data,
              },
            ];
          }
        });

        const concatedSummaries: RecordsSummariesByFollowedSideEffect = {};

        // Merge summaries to simplify the use of summaries.
        Object.entries(summaries).forEach(([key, value]) => {
          value.forEach((s) => {
            if (concatedSummaries[key]) {
              const i = concatedSummaries[key].findIndex(
                (rs) =>
                  rs.questionId === s.questionId &&
                  rs.medicalChildId === s.medicalChildId
              );
              if (i >= 0) {
                concatedSummaries[key][i].records.push(...s.records);
              } else {
                concatedSummaries[key].push(s);
              }
            } else {
              concatedSummaries[key] = [s];
            }
          });
        });

        setRecordsSummaries(concatedSummaries);
        setRecordsInitializationComplete(true);
      });
    }

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [followUpInitializationComplete, user, tenantId]);

  useEffect(() => {
    if (selectedChild && followUpInitializationComplete) {
      const observations =
        childrenById[selectedChild]?.isDisabled === true
          ? []
          : FollowUpLogic.getObservationsToDo(
              followedSideEffectsByGamerChildId[selectedChild] ?? [],
              recordsSummaries
            );

      if (observations.length > 0) {
        const event: ObservationAvailable = {
          name: "Observation Available",
          eventProperties: {
            "Kid ID": selectedChild,
            "Number of observations": observations.length,
          },
        };

        handleEvent(event);
      }
    }
  }, [selectedChild, followUpInitializationComplete]);

  useEffect(() => {
    if (anyChildHasTaskTovalidate) {
      return;
    }
    const allSideEffect = Object.entries(followedSideEffectsByGamerChildId);
    let childId = undefined;
    for (let i = 0; i < allSideEffect.length; i++) {
      const element = allSideEffect[i][1];
      if (
        FollowUpLogic.getObservationsToDo(element, recordsSummaries).length > 0
      ) {
        childId = allSideEffect[i][0];
        break;
      }
    }

    if (childId && childrenById[childId].isDisabled) {
      return;
    }

    if (childId) {
      setSelectedChild(childId);
    }
  }, [followUpInitializationComplete, recordsSummaries]);

  const anyChildHasObservation = useMemo((): boolean => {
    if (!followedSideEffectsByGamerChildId) {
      return false;
    }
    const allSideEffect = Object.values(followedSideEffectsByGamerChildId);

    let anyObservation = false;

    for (let i = 0; i < allSideEffect.length; i++) {
      if (childrenById[i]?.isDisabled !== true) {
        const element = allSideEffect[i];
        if (
          FollowUpLogic.getObservationsToDo(element, recordsSummaries).length >
          0
        ) {
          anyObservation = true;
          break;
        }
      }
    }
    return anyObservation;
  }, [followedSideEffectsByGamerChildId, recordsSummaries]);

  const resetFollowUp = () => {
    setPrescriptionsByGamerChildId({});
    setFollowedSideEffectsByGamerChildId({});
    setFollowUpInitializationComplete(false);
    setRecordsSummaries({});
  };

  return (
    <FollowUpContextProvider
      value={{
        prescriptionsByGamerChildId,
        setPrescriptionsByGamerChildId,
        followedSideEffectsByGamerChildId,
        setFollowedSideEffectsByGamerChildId,
        followUpInitializationComplete,
        recordsInitializationComplete,
        recordsSummaries,
        setRecordsSummaries,
        resetFollowUp,
        anyChildHasObservation,
      }}
    >
      {props.children}
    </FollowUpContextProvider>
  );
};

export { useFollowUpContext, FollowUpProvider };
