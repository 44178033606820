import { Card, Grid, SxProps, Theme, Typography } from "@mui/material";
import EmptyStateGauge from "../Gauge/EmptyStateGauge";
import { useTranslation } from "react-i18next";

interface SkillsEmptyStateCardProps {
  sx?: SxProps<Theme>;
}

const SkillsEmptyStateCard = ({
  sx,
}: SkillsEmptyStateCardProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Card sx={sx}>
      <Grid
        container
        alignItems="center"
        sx={{
          marginY: 1,
        }}
      >
        <Grid
          item
          xs={6}
          sm={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <EmptyStateGauge
            sx={{
              paddingX: "1rem",
              maxWidth: "150px",
            }}
          />
          <Typography fontWeight={700} textAlign="center" mx={1} mt={1}>
            {t("dashboard.skills.empty.skill")}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={9}>
          <Typography fontWeight={700} fontSize="0.9rem" color="#629d9b">
            {t("dashboard.skills.empty.title")}
          </Typography>

          <Typography fontSize="0.75rem">
            {t("dashboard.skills.empty.text")}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default SkillsEmptyStateCard;
