import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { GenericPageProps } from "../../genericPageProps";
import {
  calculateStickyButtonPagePadding,
  PageWithHeader,
  PageContentContainer,
  StickyButtons,
} from "@neurosolutionsgroup/components";

interface ChildNeedsPageProps extends GenericPageProps {
  orderedNeedKeys: string[];
  onSubmit: (needs: string[]) => void;
  childName: string;
  preselectedNeeds?: string[];
}

const ChildNeedsPage = ({
  orderedNeedKeys,
  onSubmit,
  safeAreas,
  sx,
  preselectedNeeds,
  childName,
}: ChildNeedsPageProps): JSX.Element => {
  const { t } = useTranslation();

  const [needs, setNeeds] = useState<string[]>(preselectedNeeds ?? []);

  const onNeedClick = (need: string, checked: boolean) => {
    if (checked) {
      setNeeds((current) => {
        if (current.includes(need)) {
          return current;
        } else {
          return [...current, need];
        }
      });
    } else {
      setNeeds((current) => {
        return current.filter((n) => n !== need);
      });
    }
  };

  return (
    <PageWithHeader
      sx={sx}
      headerContent={
        <Typography fontWeight="bold" my={2} color="#fff">
          {t("settings.child.needs.title", { childName: childName })}
        </Typography>
      }
    >
      <PageContentContainer safeAreas={safeAreas}>
        <Box py={2} px={3} pb={calculateStickyButtonPagePadding()}>
          <Typography fontWeight="bold" my={2}>
            {t("settings.child.needs.editionPage.question", {
              childName: childName,
            })}
          </Typography>

          <FormGroup>
            {orderedNeedKeys.map((need) => (
              <FormControlLabel
                key={need}
                control={
                  <Checkbox
                    checked={needs.includes(need)}
                    onChange={(e) => onNeedClick(need, e.target.checked)}
                    color="secondary"
                    sx={{
                      paddingY: 0.75,
                    }}
                    data-cy={`child-needs-checkbox-${need}`}
                  />
                }
                label={t(`settings.child.needs.needs.${need}`)}
              />
            ))}
            <FormControlLabel
              control={
                <Checkbox
                  checked={needs.includes("other")}
                  onChange={(e) => onNeedClick("other", e.target.checked)}
                  color="secondary"
                  sx={{
                    paddingY: 0.75,
                  }}
                  data-cy={"child-needs-checkbox-other"}
                />
              }
              label={t("settings.child.needs.needs.other")}
            />
          </FormGroup>
        </Box>

        <StickyButtons
          onConfirm={() => onSubmit(needs)}
          data-cy="child-needs-confirm"
          confirmVariant="contained"
          confirmColor="secondary"
          safeAreas={safeAreas}
        />
      </PageContentContainer>
    </PageWithHeader>
  );
};

export default ChildNeedsPage;
