import { SetStateAction, useEffect, useMemo, useState } from "react";
import {
  PeriodOption,
  RoutinePeriod,
  routinePeriodAbbreviations,
} from "../PeriodPicker";
import { format, isBefore, isSameDay, isSameMonth, isSameYear } from "date-fns";
import useChildren from "common/hooks/children/useChildren";
import { TaskHistory, catmap } from "@neurosolutionsgroup/models";
import { Dictionary, groupBy } from "lodash";
import useTasks from "common/hooks/routines/useTasks";
import useLanguage from "common/hooks/Parameters/useLanguage";
import { useTranslation } from "react-i18next";
import { taskCategoryLoc } from "@neurosolutionsgroup/localization";
import { GraphModels, GraphTools } from "@neurosolutionsgroup/graphs";
import FirebaseAPI from "@neurosolutionsgroup/api-client";
import useSubscription from "common/hooks/subscription/useSubscription";
import { freeRoutinePeriods } from "common/hooks/subscription/SubscriptionContext";

export interface PeriodDates {
  periodStart: number;
  periodEnd: number;
}

export interface TaskCount {
  done: number;
  notDone: number;
  graphData: GraphModels.RoutineDatum[];
  historyByCategory: Dictionary<TaskHistory[]>;
  progressData: GraphModels.ProgressDatum[];
  categories: string[];
}

const blankData: TaskCount = {
  done: 0,
  notDone: 0,
  graphData: [],
  historyByCategory: {},
  progressData: [],
  categories: [],
};

interface UseRoutineDashboardData {
  loading: boolean;
  period: RoutinePeriod;
  periodText: string;
  setPeriod: React.Dispatch<SetStateAction<RoutinePeriod>>;
  selectedCategory: string;
  setSelectedCategory: React.Dispatch<SetStateAction<string>>;
  isPeriodDisabled: (period: number) => boolean;
  periodOptions: PeriodOption[];
  data: TaskCount;
}

const useRoutineDashboard = (): UseRoutineDashboardData => {
  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState<RoutinePeriod>(RoutinePeriod.ThisWeek);
  const [selectedCategory, setSelectedCategory] = useState("0");
  const [data, setData] = useState<TaskCount>(blankData);

  const { language, dateLocale } = useLanguage();
  const { t } = useTranslation();

  const {
    selectors: { selectedChild, childrenById },
  } = useChildren();

  const {
    selectors: { tasksById },
  } = useTasks();

  const { permissions } = useSubscription();

  const isPeriodDisabled = (period: number): boolean => {
    if (
      period === 0 ||
      period === 1 ||
      !selectedChild ||
      !childrenById[selectedChild]
    ) {
      return false;
    }

    const childCreation = childrenById[selectedChild].creation * 1000;

    // Check the smaller period because if creation before smaller period, larger period must be available.
    const startOfSmallerPeriod =
      GraphTools.calculateRoutinePeriod((period - 1).toString()).periodStart *
      1000;

    return !isBefore(childCreation, startOfSmallerPeriod);
  };

  const periodOptions = useMemo((): PeriodOption[] => {
    return Object.values(RoutinePeriod)
      .filter((k) => typeof k === "number")
      .map((k) => ({
        value: k as number,
        label: t("dashboard.period.options." + k.toString()),
        disabled: isPeriodDisabled(k as number),
        display: routinePeriodAbbreviations[k as number][language],
        premiumLock:
          !permissions.allRoutinePeriods &&
          !freeRoutinePeriods.some((period) => period === k),
      }));
  }, [t, selectedChild, permissions]);

  const periodText: string = useMemo(() => {
    const periodDates = GraphTools.calculateRoutinePeriod(period);

    const start = new Date(periodDates.periodStart * 1000);
    const end = new Date(periodDates.periodEnd * 1000);

    const endDate = format(end, "d LLL u", {
      locale: dateLocale,
    });

    let startDate = format(start, "d", {
      locale: dateLocale,
    });

    if (isSameDay(end, start)) {
      return endDate;
    }

    if (!isSameMonth(end, start)) {
      startDate =
        startDate + " " + format(start, "LLL", { locale: dateLocale });
    }

    if (!isSameYear(end, start)) {
      startDate = startDate + " " + format(start, "u", { locale: dateLocale });
    }

    return startDate + " - " + endDate;
  }, [period, dateLocale]);

  const formatProgressData = (
    history: TaskHistory[],
    periodStartMS: number,
    periodEndMS: number,
    weeks: boolean
  ): GraphModels.ProgressDatum[] => {
    const initialProgressDataMap: GraphModels.ProgressDataMap =
      GraphTools.RoutineDataLogic.createEntriesForPeriod(
        periodStartMS,
        periodEndMS,
        weeks
      );

    const progressDataMap: GraphModels.ProgressDataMap =
      GraphTools.RoutineDataLogic.placeHistoryCountsIntoDates(
        history,
        initialProgressDataMap,
        weeks,
        periodStartMS,
        tasksById
      );

    const progressData: GraphModels.ProgressDatum[] =
      GraphTools.RoutineDataLogic.calculatePercentageForEachDate(
        progressDataMap
      );

    return progressData;
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);

      try {
        if (selectedChild && childrenById[selectedChild]) {
          const { periodStart, periodEnd } =
            GraphTools.calculateRoutinePeriod(period);

          const categories = groupBy(
            childrenById[selectedChild].history,
            (h) => {
              const task = tasksById[h.task];

              if (task) {
                return task.category ?? catmap[task.icon]["0"];
              }
            }
          );

          const history = await populateHistory(
            period,
            childrenById[selectedChild].history,
            selectedChild,
            periodStart,
            periodEnd
          );

          const progressData = formatProgressData(
            history,
            periodStart * 1000,
            periodEnd * 1000,
            GraphTools.progressGraphInWeeks(period)
          );

          const dataDict = groupBy(history, (h) => {
            const task = tasksById[h.task];

            if (task) {
              return task.category ?? catmap[task.icon][0];
            }
          });

          const data: GraphModels.RoutineDatum[] =
            GraphTools.RoutineDataLogic.populateData(
              dataDict,
              categories,
              taskCategoryLoc,
              language
            );

          data.sort((a, b) => a.category.localeCompare(b.category));

          setData({
            done: history.filter((h) => h.status === true).length,
            notDone: history.filter((h) => h.status === false).length,
            graphData: data,
            historyByCategory: dataDict,
            progressData,
            categories: Object.keys(dataDict),
          });
        } else {
          setData(blankData);
        }
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [period, selectedChild, childrenById]);

  return {
    loading,
    period,
    periodText,
    setPeriod,
    selectedCategory,
    setSelectedCategory,
    isPeriodDisabled,
    periodOptions,
    data,
  };
};

export default useRoutineDashboard;

const populateHistory = async (
  period: RoutinePeriod,
  selectedChildHistory: TaskHistory[],
  selectedChild: string,
  periodStart: number,
  periodEnd: number
): Promise<TaskHistory[]> => {
  let output = [];
  if (period > RoutinePeriod.LastTwoWeeks) {
    output = await FirebaseAPI.Child.getValidatedTaskHistory({
      gamerChildId: selectedChild,
      start: periodStart,
      end: periodEnd,
    });
  } else {
    output = selectedChildHistory.filter(
      (h) =>
        h.dueTime > periodStart &&
        h.dueTime < periodEnd &&
        h.confirmTime !== null
    );
  }
  return output;
};
