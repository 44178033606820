import { useMemo, useState } from "react";
import { InfoOutlined as InfoOutlinedIcon } from "@mui/icons-material";
import { IconButton, SxProps, Theme } from "@mui/material";
import InfoDrawer from "./InfoDrawer";

interface InformationButtonProps {
  infoText: string | string[] | JSX.Element;
  coachImage: string | null;
  infoTitle?: string;
  infoIconImage?: string;
  color?: "primary" | "secondary" | "white";
  inline?: boolean;
  sx?: SxProps<Theme>;
}

const InformationButton = ({
  infoText,
  coachImage,
  infoTitle,
  infoIconImage,
  color = "secondary",
  inline = false,
  sx,
}: InformationButtonProps): JSX.Element => {
  const [open, setOpen] = useState(false);

  const textColor = useMemo(() => {
    switch (color) {
      case "white":
        return "#fff";
      case "primary":
        return "primary.main";
      case "secondary":
        return "secondary.main";
      default:
        return "text.primary";
    }
  }, [color]);

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setOpen(true);
        }}
        sx={[
          {
            color: textColor,
            padding: inline ? 0 : 1,
            fontSize: inline ? "1.25em" : undefined,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <InfoOutlinedIcon color="inherit" fontSize="inherit" />
      </IconButton>
      <InfoDrawer
        open={open}
        onClose={() => setOpen(false)}
        title={infoTitle}
        text={infoText}
        coachImage={coachImage}
        infoIconImage={infoIconImage}
      />
    </>
  );
};

export default InformationButton;
