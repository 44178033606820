import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { Challenge, ChallengeHistory } from "@neurosolutionsgroup/models";
import TaskAssets from "assets/task-icons";
import React, { useEffect, useState } from "react";
import ExtendableCard from "../ExtendableCard";
import useChallenges from "common/hooks/challenges/useChallenges";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import ChallengeAccordionDetails from "./ChallengeAccordionDetails";

export interface ChallengeCardProps {
  challenge: Challenge;
  onChallengeValidationComplete: (challenge: Challenge) => void;
}

const ChallengeValidationCard: React.FC<ChallengeCardProps> = ({
  challenge,
  onChallengeValidationComplete,
}) => {
  const {
    actions: { validateChallenges },
  } = useChallenges();
  const { handleUnknownError } = useErrorsContext();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onChallengeValidated = async (
    challengeId: string,
    history: ChallengeHistory[]
  ) => {
    try {
      setLoading(true);
      const challenge = await validateChallenges(challengeId, history);

      onChallengeValidationComplete(challenge);
    } catch (err) {
      handleUnknownError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading) {
      setOpen(false);
    }
  }, [loading]);

  return (
    <>
      <ExtendableCard
        summaryComponent={
          loading ? (
            <Box
              height="4em"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexGrow={1}
            >
              <CircularProgress color="secondary" />
            </Box>
          ) : (
            <ChallengeAccordionSummary challenge={challenge} />
          )
        }
        detailsComponent={
          <ChallengeAccordionDetails
            challenge={challenge}
            onChallengeValidated={onChallengeValidated}
          />
        }
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};

export default ChallengeValidationCard;

interface ChallengeAccordionSummaryProps {
  challenge: Challenge;
}

const ChallengeAccordionSummary: React.FC<ChallengeAccordionSummaryProps> = ({
  challenge,
}) => {
  return (
    <>
      <Grid container alignItems="center" spacing={1} flexWrap="nowrap">
        <Grid
          item
          sx={{
            "& img": {
              width: "4em",
              objectFit: "contain",
            },
          }}
          flexGrow={0}
        >
          <img
            src={TaskAssets.TaskIcons[challenge.icon]}
            alt={`Challenge Icon ${challenge.icon}`}
          />
        </Grid>
        <Grid item flexGrow={1}>
          <Typography fontWeight="bold">{challenge.title}</Typography>
        </Grid>
      </Grid>
    </>
  );
};
