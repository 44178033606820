import React, { useCallback, useState } from "react";
import {
  Button,
  ButtonBase,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import DrawerSection from "../Drawer/DrawerSection";
import Drawer from "../Drawer/Drawer";
import { Icons } from "@neurosolutionsgroup/components";
import { CypressProps } from "@neurosolutionsgroup/models";

export interface SelectOption {
  value: string | number | null;
  label?: string | JSX.Element;
  disabled?: boolean;
  display?: string | JSX.Element;
}

interface SelectClasses {
  root?: string;
  drawer?: string;
  option?: string;
  button?: string;
}

interface SelectProps<T extends SelectOption> extends CypressProps {
  value: string | number | null;
  options: T[];
  onChange: (option: T) => void;
  label: string;
  placeholder?: string | JSX.Element;
  hideIndicator?: boolean;
  indicator?: JSX.Element;
  leftIndicator?: JSX.Element;
  id?: string;
  classes?: SelectClasses;
  truncateOnOverflow?: boolean;
  variant?: "standard" | "outlined";
  fullWidth?: boolean;
  buttonStyle?: SxProps<Theme>;
}

const Select = <T extends SelectOption>({
  value,
  options,
  onChange,
  label,
  placeholder,
  hideIndicator = false,
  indicator,
  leftIndicator,
  id,
  classes,
  truncateOnOverflow = false,
  variant = "standard",
  fullWidth = true,
  buttonStyle,
  ...props
}: SelectProps<T>): JSX.Element => {
  const ROOT_CLASS = "kairos-select";

  const { palette } = useTheme();

  const [drawerOpen, setDrawerOpen] = useState(false);

  /**
   * Update label on options or value change.
   */
  const getValueLabel = useCallback((): string | JSX.Element | null => {
    if (value === null) {
      return placeholder ?? null;
    }

    const option = options.find((o) => value === o.value);

    if (!option) {
      return placeholder ?? null;
    }

    return option.display ?? option.label ?? `${option.value}`;
  }, [options, value]);

  /**
   * Close drawer on selection.
   */
  const onOptionClick = (option: T) => {
    onChange(option);
    setDrawerOpen(false);
  };

  return (
    <div
      id={id}
      className={clsx(ROOT_CLASS, ROOT_CLASS + "--" + variant, classes?.root)}
    >
      <Button
        data-cy={props["data-cy"]}
        color="secondary"
        variant="text"
        className={clsx(ROOT_CLASS + "__button", classes?.button)}
        onClick={() => setDrawerOpen(true)}
        disabled={options.length === 0}
        sx={
          buttonStyle ??
          (variant === "outlined"
            ? (theme) => ({
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: theme.palette.secondary.main,
              })
            : undefined)
        }
        fullWidth={fullWidth}
      >
        {leftIndicator && (
          <div
            className={clsx(
              ROOT_CLASS + "__indicator",
              ROOT_CLASS + "__indicator--left"
            )}
          >
            {leftIndicator}
          </div>
        )}
        <div
          className={clsx(ROOT_CLASS + "__value", {
            [ROOT_CLASS + "__value--truncate"]: truncateOnOverflow,
          })}
        >
          {getValueLabel()}
        </div>
        <div
          className={clsx(
            ROOT_CLASS + "__indicator",
            ROOT_CLASS + "__indicator--right"
          )}
        >
          {!hideIndicator &&
            (indicator ?? (
              <Icons.ArrowIcon
                className={ROOT_CLASS + "__indicator-icon"}
                arrowDirection="down"
                color={palette.secondary.main}
              />
            ))}
        </div>
      </Button>
      <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
        classes={{
          paper: clsx(ROOT_CLASS + "__drawer", classes?.drawer),
        }}
      >
        <DrawerSection variant="header">
          <Typography variant="h4">{label}</Typography>
        </DrawerSection>
        {options.map((o, i) => (
          <DrawerSection
            className={clsx(ROOT_CLASS + "__option", classes?.option, {
              [ROOT_CLASS + "__option--active"]: o.value === value,
            })}
            key={i}
          >
            <ButtonBase
              data-cy={`${props["data-cy"]}-${i}`}
              //data-cy={"drawer-choice-" + i}
              onClick={(e) => {
                e.stopPropagation();
                onOptionClick(o);
              }}
              sx={{
                opacity: o.disabled ? 0.5 : 1,
              }}
              disabled={o.disabled}
            >
              {o.label ?? o.value}
            </ButtonBase>
          </DrawerSection>
        ))}
      </Drawer>
    </div>
  );
};

export default Select;
