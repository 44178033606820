import Tools from "@neurosolutionsgroup/tools";
import {
  addDays,
  differenceInCalendarDays,
  getDay,
  nextDay,
  previousDay,
} from "date-fns";

export const calculateIfAChallengeIsAchievable = (
  numberOfOccurrences: number,
  days: number,
  endDate: number | null
): boolean => {
  if (!endDate) {
    return true;
  }
  if (days === 0) {
    const daysTodoTheChallenge =
      differenceInCalendarDays(endDate, new Date()) + 1;

    return daysTodoTheChallenge >= numberOfOccurrences;
  } else {
    const fnsDay = Tools.Time.Days.kairosDayToDateFnsDay(days);
    if (fnsDay !== undefined) {
      const result =
        calculateNumberOfValidDays(fnsDay, endDate) >= numberOfOccurrences;
      return result;
    }
  }
  return false;
};

export const calculateNumberOfValidDays = (
  day: Day,
  endDateMS: number,
  today: number | Date = new Date()
): number => {
  const firstValidDay = nextDay(today, day);
  let totalCorrection = 0;
  if (getDay(today) === day) {
    totalCorrection++;
  } else if (firstValidDay.getTime() > endDateMS) {
    return 0;
  }

  let correction = 0;
  if (getDay(endDateMS) === day) {
    correction++;
  }

  const lastValidDay = previousDay(addDays(endDateMS, correction), day);
  const nbrOfValidDays =
    differenceInCalendarDays(lastValidDay, firstValidDay) / 7 +
    1 +
    totalCorrection;
  return nbrOfValidDays;
};
