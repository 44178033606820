import {
  DiagnosisEntered,
  MedicationEntered,
  TasksValidated,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import { GamerChild, TaskHistory } from "@neurosolutionsgroup/models";
import FirebaseAPI from "@neurosolutionsgroup/api-client";
import { endOfDay, fromUnixTime, getUnixTime, startOfDay } from "date-fns";
import { Dispatch, useCallback, useMemo } from "react";
import { v4 } from "uuid";
import { useAppDataContext } from "../AppDataContext";
import { HookResult } from "../HookResult";
import {
  TasksToValidate,
  ChildrenById,
  useChildrenContext,
  MedicalChildrenByGamerId,
} from "./ChildrenContext";
import useParameters from "common/hooks/Parameters/useParameters";
import minBy from "lodash/minBy";
import Tools from "@neurosolutionsgroup/tools";
import { UniWebViewActions } from "../Parameters/UniWebViewActions";

export interface UseChildrenSelectors {
  childIds: string[];
  childrenById: ChildrenById;
  medicalChildrenByGamerId: MedicalChildrenByGamerId;
  selectedChild: string | null;
  tasksToValidate: TasksToValidate;
  hasChildStartPlaying: (childId: string) => boolean | undefined;
  firstCreatedChild: GamerChild | undefined;
  isFirstCreatedChild: (childId: string) => boolean | undefined;
  childrenSortedByCreation: GamerChild[];
  lastValidationSent: number | undefined;
  anyChildHasTaskTovalidate: boolean;
}

export interface UseChildrenActions {
  childHasMedicalInfo: (gamerChildId: string) => boolean;
  clearAllValidations: (gamerChildId: string) => Promise<void>;
  countMedicalChildrenWithDiagnosis: () => number;
  deleteChild: (gamerChildId: string) => Promise<void>;
  refreshChildren: () => Promise<void>;
  setSelectedChild: Dispatch<string | null>;
  selectedChildHasMedicalInfo: () => boolean;
  selectedChildTakesMedication: () => boolean;
  updateChild: (
    gamerChild: GamerChild,
    needs?: string[],
    diagnosis?: number[],
    takeMedication?: number
  ) => Promise<void>;
  updateSelectedChild: VoidFunction;
  validateTasks: (
    taskHistory: TaskHistory[],
    gamerChildId: string
  ) => Promise<void>;
}

const useChildren = (): HookResult<
  UseChildrenSelectors,
  UseChildrenActions
> => {
  const {
    childIds,
    setChildIds,
    childrenById,
    setChildrenById,
    medicalChildrenByGamerId,
    setMedicalChildrenByGamerId,
    selectedChild,
    setSelectedChild,
    tasksToValidate,
    setTasksToValidate,
    lastValidationSent,
    setLastValidationSent,
    anyChildHasTaskTovalidate,
    updateSelectedChild,
    refreshChildren,
  } = useChildrenContext();

  // FIXME: Circular dependence, app data context should use children context instead.
  const { setLoading } = useAppDataContext();

  const { handleEvent } = useAnalytics();
  const { sendMessageToUnity, version } = useParameters();

  const childHasMedicalInfo = (gamerChildId: string): boolean => {
    return Object.keys(medicalChildrenByGamerId).includes(gamerChildId);
  };

  const selectedChildHasMedicalInfo = (): boolean => {
    return !!selectedChild && childHasMedicalInfo(selectedChild);
  };

  const selectedChildTakesMedication = (): boolean => {
    if (!selectedChild) {
      console.warn(
        "selectedChildTakesMedication called with no selectedChild."
      );
      return false;
    }

    if (!childHasMedicalInfo(selectedChild)) {
      return false;
    }

    return medicalChildrenByGamerId[selectedChild].takeMedication === 1;
  };

  const updateChild = async (
    gamerChild: GamerChild,
    needs?: string[],
    diagnosis?: number[],
    takeMedication?: number
  ): Promise<void> => {
    setLoading(true);

    try {
      await Promise.all([
        updateGamerChild(gamerChild),
        updateMedicalChild(
          gamerChild.id,
          gamerChild.name,
          diagnosis,
          takeMedication,
          needs
        ),
      ]);

      return Promise.resolve();
    } finally {
      sendMessageToUnity(UniWebViewActions.ChildrenUpdated);
      setLoading(false);
    }
  };

  const updateGamerChild = async (gamerChild: GamerChild): Promise<void> => {
    const result = await FirebaseAPI.Child.updateGamerChild(gamerChild);

    setChildrenById((current) => {
      const clone = { ...current };

      if (Object.keys(clone).includes(result.id)) {
        clone[result.id] = { ...result, history: clone[result.id].history };
      } else {
        clone[result.id] = result;
      }

      return clone;
    });

    setChildIds((current) =>
      current.includes(gamerChild.id) ? current : [...current, gamerChild.id]
    );
  };

  const updateMedicalChild = async (
    gamerChildId: string,
    name: string,
    diagnosis?: number[],
    takeMedication?: number,
    needs?: string[]
  ): Promise<void> => {
    if (childHasMedicalInfo(gamerChildId)) {
      await FirebaseAPI.Medical.Child.updateMedicalChild({
        medicalChildId: medicalChildrenByGamerId[gamerChildId].medicalChildId,
        diagnosis: diagnosis ?? null,
        takeMedication: takeMedication ?? null,
        name,
        needs,
      });

      setMedicalChildrenByGamerId((current) => ({
        ...current,
        [gamerChildId]: {
          ...current[gamerChildId],
          name,
          diagnosis,
          takeMedication,
          needs,
        },
      }));
    } else if (diagnosis || needs) {
      const result = await FirebaseAPI.Medical.Child.createMedicalChild({
        medicalChildId: gamerChildId,
        name,
        diagnosis: diagnosis ?? null,
        takeMedication: takeMedication ?? null,
        gamerChildId: gamerChildId,
        needs,
      });

      setMedicalChildrenByGamerId((current) => ({
        ...current,
        [gamerChildId]: result,
      }));
    }

    if (takeMedication) {
      const event: MedicationEntered = {
        name: "Medication Entered",
        eventProperties: {
          Response: takeMedication,
        },
      };

      handleEvent(event);
    }

    if (diagnosis) {
      const event: DiagnosisEntered = {
        name: "Diagnosis Entered",
        eventProperties: {
          Response: diagnosis.length === 0 ? 0 : 1,
          Diagnosis: diagnosis.map((d) => d.toFixed()),
        },
      };

      handleEvent(event);
    }
  };

  const deleteChild = async (gamerChildId: string): Promise<void> => {
    setLoading(true);
    try {
      const shouldChangeSelectedChild =
        gamerChildId === selectedChild && childIds.length > 1;
      if (shouldChangeSelectedChild) {
        setSelectedChild(childIds.find((id) => id !== gamerChildId) ?? null);
      }

      if (childHasMedicalInfo(gamerChildId)) {
        await Promise.all([
          FirebaseAPI.Child.deleteGamerChild(gamerChildId),
          childHasMedicalInfo(gamerChildId)
            ? FirebaseAPI.Medical.Child.deleteMedicalChild(
                medicalChildrenByGamerId[gamerChildId].medicalChildId
              )
            : Promise.resolve(),
        ]);

        setChildrenById((current) => {
          const clone = { ...current };

          delete clone[gamerChildId];

          return clone;
        });
        setChildIds((current) => current.filter((id) => id !== gamerChildId));
        setMedicalChildrenByGamerId((current) => {
          const clone = { ...current };

          delete clone[gamerChildId];

          return clone;
        });
      } else {
        await FirebaseAPI.Child.deleteGamerChild(gamerChildId);

        setChildrenById((current) => {
          const clone = { ...current };

          delete clone[gamerChildId];

          return clone;
        });
        setChildIds((current) => current.filter((id) => id !== gamerChildId));
      }
    } finally {
      sendMessageToUnity(UniWebViewActions.ChildrenUpdated);
      setLoading(false);
    }
  };

  const validateTasks = async (
    taskHistory: TaskHistory[],
    gamerChildId: string
  ): Promise<void> => {
    await FirebaseAPI.Child.validateTasks(
      {
        history: taskHistory.map((th) => ({
          ...th,
          id: th.id ?? v4(),
          childStatus: th.childStatus ?? undefined,
          status: th.status ?? undefined,
          confirmTime: th.confirmTime ?? undefined,
          durationSeconds: th.durationSeconds ?? undefined,
        })),
        gamerChildId,
        version: 0,
      },
      true
    );

    const doneTasksCount = taskHistory.filter((h) => h.status).length;
    if (doneTasksCount > 0) {
      const dueTimeDate = taskHistory[0].dueTime;

      const historyTasksOfTheDay = childrenById[gamerChildId].history
        ? childrenById[gamerChildId].history.filter(
            (h) =>
              h.dueTime >
                getUnixTime(startOfDay(new Date(dueTimeDate * 1000))) &&
              h.dueTime < getUnixTime(endOfDay(new Date(dueTimeDate * 1000)))
          )
        : [];

      setLastValidationSent(Tools.Time.Dates.getTimeStamp());

      sendMessageToUnity(
        UniWebViewActions.TaskValidation,
        "gamerChildId=" +
          gamerChildId +
          "&doneTasksCount=" +
          doneTasksCount +
          "&historyTasksCount=" +
          historyTasksOfTheDay.length +
          "&dueTimeDateEpoch=" +
          dueTimeDate
      );

      // #region Fix for 1.24.0 validation.
      if (version === "1.24.0") {
        const awaitTimeout = async (timeoutMS: number) => {
          return new Promise((resolve) => setTimeout(resolve, timeoutMS));
        };

        await awaitTimeout(3000);
      }
      // #endregion
    }

    const event: TasksValidated = {
      name: "Tasks Validated",
      eventProperties: {
        "Kid ID": gamerChildId,
        "Due Date": fromUnixTime(taskHistory[0].dueTime).toISOString(),
        "Completed Tasks": taskHistory.filter((th) => th.status).length,
        "NA Tasks": taskHistory.filter((th) => th.status === null).length,
        "Uncompleted Tasks": taskHistory.filter((th) => th.status === false)
          .length,
        "Validated Tasks": taskHistory.length,
      },
    };

    handleEvent(event);

    const newHistories = childrenById[gamerChildId].history.map((h) => {
      const newTaskIndex = taskHistory.findIndex((th) => th.id === h.id);

      if (newTaskIndex !== -1) {
        return taskHistory[newTaskIndex];
      } else {
        return h;
      }
    });

    setChildrenById((current) => {
      const clone = { ...current };

      clone[gamerChildId].history = newHistories;

      return clone;
    });

    setTasksToValidate((current) => {
      const clone = { ...current };

      clone[gamerChildId] = newHistories.filter((h) => h.confirmTime === null);

      return clone;
    });

    return Promise.resolve();
  };

  const clearAllValidations = async (gamerChildId: string): Promise<void> => {
    const tasksToClear = tasksToValidate[gamerChildId];
    const initialState = tasksToValidate[gamerChildId];

    if (!tasksToClear) {
      return;
    }

    // Optimistic update.
    setTasksToValidate((current) => {
      const clone = { ...current };

      clone[gamerChildId] = [];

      return clone;
    });

    const confirmTime = getUnixTime(new Date());

    try {
      await FirebaseAPI.Child.validateTasks(
        {
          history: tasksToClear.map((th) => ({
            ...th,
            id: th.id ?? v4(),
            childStatus: th.childStatus ?? th.status ?? undefined,
            status: undefined,
            confirmTime,
            durationSeconds: th.durationSeconds ?? undefined,
          })),
          gamerChildId,
          version: 0,
        },
        true
      );
    } catch (err) {
      // Revert optimism.
      setTasksToValidate((current) => {
        const clone = { ...current };

        clone[gamerChildId] = initialState;

        return clone;
      });
    }

    const newHistories = childrenById[gamerChildId].history.map((h) => {
      const newTaskIndex = tasksToClear.findIndex((th) => th.id === h.id);

      if (newTaskIndex !== -1) {
        return tasksToClear[newTaskIndex];
      } else {
        return h;
      }
    });

    setChildrenById((current) => {
      const clone = { ...current };

      clone[gamerChildId].history = newHistories;

      return clone;
    });
  };

  const countMedicalChildrenWithDiagnosis = useCallback((): number => {
    return Object.values(medicalChildrenByGamerId).filter(
      (mc) => mc.diagnosis && mc.diagnosis.length > 0
    ).length;
  }, [medicalChildrenByGamerId]);

  interface GameProgress {
    askParentTrial: boolean;
  }

  const isGameProgress = (object: unknown): object is GameProgress => {
    return typeof object === "object" && object !== null && "version" in object;
  };

  const hasChildStartPlaying = (childId: string): boolean => {
    if (!childrenById[childId].gameProgression) {
      return false;
    }

    const progress = childrenById[childId].gameProgression;

    if (isGameProgress(progress)) {
      return progress.askParentTrial !== true;
    }

    return false;
  };

  const firstCreatedChild: GamerChild | undefined = useMemo(
    () => minBy(Object.values(childrenById), (child) => child.creation),
    [childrenById]
  );

  const isFirstCreatedChild = (childId: string): boolean | undefined => {
    if (firstCreatedChild) {
      return firstCreatedChild.id === childId;
    } else {
      return undefined;
    }
  };

  const childrenSortedByCreation: GamerChild[] = useMemo(() => {
    if (!childrenById) {
      return [];
    }
    return Object.values(childrenById).sort((a, b) => a.creation - b.creation);
  }, [childrenById]);

  return {
    selectors: {
      childIds,
      childrenById,
      medicalChildrenByGamerId,
      selectedChild,
      tasksToValidate,
      hasChildStartPlaying,
      firstCreatedChild,
      isFirstCreatedChild,
      childrenSortedByCreation,
      lastValidationSent,
      anyChildHasTaskTovalidate,
    },
    actions: {
      childHasMedicalInfo,
      clearAllValidations,
      countMedicalChildrenWithDiagnosis,
      deleteChild,
      refreshChildren,
      setSelectedChild,
      selectedChildHasMedicalInfo,
      selectedChildTakesMedication,
      updateChild,
      updateSelectedChild,
      validateTasks,
    },
  };
};

export default useChildren;
